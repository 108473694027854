import { ReactNode, useMemo } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { IconButton, Snackbar } from "@mui/material";
import { TranslatedStr } from "types/src/TranslatedStr";
import { useTranslation } from "i18n";
import { CancelOutlined } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { Typography } from "@components/Typography";

export namespace ToastProvider {
  export interface Props {
    children?: ReactNode;
  }
}

export function ToastProvider(p: ToastProvider.Props) {
  return <SnackbarProvider>{p.children}</SnackbarProvider>;
}

export const useToast = useSnackbar;

export const Notification = Snackbar;

export interface NotificationItemProps {
  type: "success" | "info" | "warning" | "error";
  message: TranslatedStr;
  content:
    | { type: "none" }
    | { type: "text"; text: TranslatedStr }
    | { type: "code"; code: string };
  onLock: () => void;
  onUnlock: () => void;
  onRemove: () => void;
}

export function NotificationItem(p: NotificationItemProps) {
  const { t } = useTranslation();

  const title = useMemo(() => {
    switch (p.type) {
      case "success":
        return t("Success");
      case "info":
        return t("Info");
      case "warning":
        return t("Warning");
      case "error":
        return t("An error occurred");
    }
  }, [p.type, t]);

  return (
    <Notification
      style={{ maxWidth: 450 }}
      onMouseEnter={p.onLock}
      onMouseLeave={p.onUnlock}
      message={
        <Stack direction={"row"}>
          <Typography variant={"h4"}>{title}</Typography>
          {p.message}
        </Stack>
      }
      action={
        <IconButton onClick={p.onRemove}>
          <CancelOutlined />
        </IconButton>
      }
    />
  );
}
