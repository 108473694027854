import { ReactElement, ReactNode } from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface ListingWrapperProps {
  children?: ReactNode;
  header?: ReactNode;
  className?: string;
}

export function ListingWrapper({
  children,
  header,
  className,
}: ListingWrapperProps): ReactElement {
  return (
    <Wrapper className={className}>
      {header && <Header>{header}</Header>}
      {header && <Separator />}
      <Content>{children}</Content>
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  padding: 0;
  flex: 1;
  min-height: 0;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Separator = styled("hr")`
  margin: 0;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey["300"]};
`;

const Content = styled("div")`
  min-height: 0;
  max-height: 100%;
  overflow-y: auto;
`;

const Header = styled("div")`
  padding: 10px 12px;
`;
