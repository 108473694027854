import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import List from "@mui/material/List";

export function SecondarySidebar(p: SecondarySidebar.Props): ReactElement {
  return (
    <Box
      sx={(t) => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        p: t.spacing(8, 4, 0, 4),
        backgroundColor: t.palette.surface.primary,
        gap: 4,
      })}
    >
      <Typography sx={{ opacity: 0.5 }} variant={"body1"}>
        {p.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 1,
        }}
      >
        {p.children}
      </Box>
    </Box>
  );
}

export namespace SecondarySidebar {
  export interface Props {
    children: ReactNode;
    title: string;
  }

  export interface ItemProps {
    title: string;
    isCurrent?: "ancestor" | boolean;
    onClick: () => void;
    children?: ReactNode;
  }

  export function Item(p: ItemProps): ReactElement {
    const [open, setOpen] = useState(false);

    useEffect(
      () => {
        if (p.isCurrent && !open) {
          setOpen(true);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [p.isCurrent],
    );

    return (
      <Box
        sx={(t) => ({
          borderRadius: 2,
          backgroundColor: p.isCurrent
            ? t.palette.surface.secondary
            : "transparent",
        })}
      >
        <ListItem sx={{ p: 0 }}>
          <ListItemButton
            onClick={p.onClick}
            selected={p.isCurrent === true}
            sx={{ backgroundColor: "transparent" }}
          >
            <ListItemText>{p.title}</ListItemText>
            {p.children && (
              <KeyboardArrowDownRoundedIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen((v) => !v);
                }}
                sx={(t) => ({
                  transform: `rotate(${open ? "180deg" : "0deg"})`,
                  fontSize: "24px",
                  color: t.palette.text.primary,
                  opacity: open || p.isCurrent ? 0.35 : 0,
                  ".MuiListItemButton-root:hover &": {
                    opacity: 0.35,
                  },
                })}
              />
            )}
          </ListItemButton>
        </ListItem>
        {p.children && (
          <SubNavItemSubmenu expanded={open}>{p.children}</SubNavItemSubmenu>
        )}
      </Box>
    );
  }

  export interface SubItemProps {
    title: string;
    isCurrent: boolean;
    onClick: () => void;
  }
  export function SubItem(p: SubItemProps): ReactElement {
    return (
      <ListItem sx={{ p: 0 }}>
        <ListItemButton onClick={p.onClick} selected={p.isCurrent}>
          <ListItemText
            sx={(t) => ({
              "& .MuiTypography-root": {
                fontWeight: "normal",
                color: t.palette.text.secondary,
              },
              ".MuiListItemButton-root.Mui-selected & .MuiTypography-root": {
                fontWeight: 700,
              },
            })}
          >
            {p.title}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }
}

function SubNavItemSubmenu(p: { children: ReactNode; expanded?: boolean }) {
  return (
    <List
      sx={{
        flex: 1,
        py: 0,
        overflow: "hidden",
        maxHeight: p.expanded ? "300px" : 0,
        transition: "maxHeight 300ms ease",
        overflowY: "auto",
      }}
    >
      {p.children}
    </List>
  );
}
