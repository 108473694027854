import React from "react";
import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { Box } from "@mui/material";
import { Sxs } from "../../../../../../types/styles";
import { Icon } from "./Icon";

export namespace MovedFrom {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.movedFrom>["renderProps"]
  >;
}

export const MovedFrom = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  text,
}: MovedFrom.Props<DataEntry, ColumnConfig>) => (
  <Box sx={sxs.wrapper}>
    <Icon />
    <Box sx={sxs.text}>{text ?? "-"}</Box>
  </Box>
);

export const sxs: Sxs<"wrapper" | "text"> = {
  wrapper: (theme) => ({
    display: "inline-flex",
    alignItems: "center",
    gap: 2,
  }),
  text: (theme) => ({
    ...theme.typography.subtitle2,
  }),
};
