import React, { ReactElement } from "react";
import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const Tooltip: React.FC<{ title: string; children: ReactElement }> = ({
  title,
  children,
}) => (
  <MuiTooltip title={title} placement="top" arrow slotProps={slotProps}>
    {children}
  </MuiTooltip>
);

const sxs = {
  popper: (theme) => ({
    ".MuiTooltip-tooltip": {
      padding: theme.spacing(2, 3),
      ...theme.typography.caption,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
    },
    ".MuiTooltip-arrow:before": {
      backgroundColor: theme.palette.common.black,
    },
    '&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
      marginBottom: `${theme.spacing(2.5)} !important`,
    },
  }),
} satisfies Record<string, SxProps<Theme>>;

const slotProps: TooltipProps["slotProps"] = { popper: { sx: sxs.popper } };
