import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { NoEmptyString } from "types/src/NoEmptyString";
import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";
import { RepositoryMovementFragmentFragment } from "../generated/graphql";
import { repositoryFragmentToRepository } from "./Repositories";

export function repositoryMovementFragmentToRepositoryMovement(
  fragment: RepositoryMovementFragmentFragment,
): RepositoryMovement {
  return {
    id: fragment.id as RepositoryMovementId,
    fields: (fragment.data ?? {}) as ItemMovement["fields"],
    createdAt: fragment.createdAt,
    updatedAt: fragment.updatedAt ?? undefined,
    collectionId: fragment.collectionID as ItemMovement["collectionId"],
    orderId: fragment.orderID as ItemMovement["orderId"],
    dataTypeId: fragment.dataTypeID as ItemMovement["dataTypeId"],
    executed: fragment.executed ?? false,
    executedAt: fragment.executedAt ?? undefined,
    handler: fragment.handler as NoEmptyString,
    repository: repositoryFragmentToRepository(fragment.repository),
    from: Fp.pipe(
      fragment.from,
      O.fromNullable,
      O.map(repositoryFragmentToRepository),
      O.toUndefined,
    ),
    to: repositoryFragmentToRepository(fragment.to),
  };
}
