import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { schemaFieldsState } from "./utils";
import * as Exits from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          id: s.payload.id,
          dataTypeId: s.payload.dataTypeId,
          schema: schemaFieldsState.reducer(s.payload.schema, a),
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Suppliers:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(State.loadError(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            id: a.payload.item.id,
            dataTypeId: a.payload.item.dataTypeId,
            schema: schemaFieldsState.init({
              schema: a.payload.schema,
              values: a.payload.item.fields,
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:Submit": {
      if (State.isReady(s)) {
        return E.right(State.saving(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.saved.create(a.payload));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}
