/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typed } from "utils/Typed";
import { silentUnreachableError } from "utils/exceptions";
import { DsError } from "ds";
import * as Rx from "rxjs";
import * as E from "fp-ts/Either";
import { Epic } from "../../types/RootEpic";

function createStates<P extends string, T, Q>(p: P) {
  return Typed.builder
    .add("loading", (query: Q) => ({ query }))
    .add("loadError", (p: { error: DsError; query: Q }) => p)
    .add("ready", (p: { data: T; query: Q }) => p)
    .finish()(p);
}
function createActions<P extends string, T, Q>(p: P) {
  return Typed.builder
    .add("loadSuccess", (p: T) => p)
    .add("loadError", (e: DsError) => e)
    .add("retry")
    .add("setQuery", (q: Q) => q)
    .finish()(p);
}

export namespace Loading {
  export type State<P extends string, T, Q> = Typed.GetTypes<
    ReturnType<typeof createStates<P, T, Q>>
  >;
  export type Actions<P extends string, T, Q> = Typed.GetTypes<
    ReturnType<typeof createActions<P, T, Q>>
  >;

  export function createState<P extends string, T, Q>(p: P) {
    const states = createStates<P, T, Q>(p);
    const actions = createActions<P, T, Q>(p);

    type State = Typed.GetTypes<typeof states>;
    type Actions = Typed.GetTypes<typeof actions>;

    const reducer = (s: State, a: Actions): E.Either<never, State> => {
      if (actions.loadError.is(a)) {
        return states.loading.is(s)
          ? E.right(
              states.loadError.create({
                error: a.payload,
                query: s.payload.query,
              }),
            )
          : E.right(s);
      }

      if (actions.loadSuccess.is(a)) {
        return states.loading.is(s)
          ? E.right(
              states.ready.create({ data: a.payload, query: s.payload.query }),
            )
          : E.right(s);
      }

      if (actions.retry.is(a)) {
        return states.loadError.is(s)
          ? E.right(states.loading.create(s.payload.query))
          : E.right(s);
      }

      if (actions.setQuery.is(a)) {
        return E.right(states.loading.create(a.payload));
      }

      silentUnreachableError(a);
      return E.right(s);
    };

    const epic: Epic<
      Actions,
      State,
      { get: (q: Q) => Rx.Observable<E.Either<DsError, T>> }
    > = (state$, deps) => {
      return state$.pipe(
        Rx.filter(states.loading.is),
        Rx.debounceTime(500),
        Rx.switchMap((s) =>
          deps
            .get(s.payload.query)
            .pipe(
              Rx.map(E.map(actions.loadSuccess.create)),
              Rx.map(E.mapLeft(actions.loadError.create)),
              Rx.map(E.getOrElseW((v) => v)),
            ),
        ),
      );
    };

    return {
      prefix: p,
      isState: Typed.getGuard(states),
      isAction: Typed.getGuard(actions),
      states,
      actions,
      reducer,
      epic,
      init: (q: Q) => states.loading.create(q),
    };
  }

  export function mapData<P extends string, T, Q, R>(
    i: ReturnType<typeof createState<P, T, Q>>,
    fn: (v: T) => R,
    s: State<P, T, Q>,
  ): State<P, R, Q> {
    if (i.states.loading.is(s)) return s;
    if (i.states.loadError.is(s)) return s;

    return {
      ...s,
      payload: {
        ...s.payload,
        data: fn(s.payload.data),
      },
    };
  }

  export type GetState<T extends { isState: (v: any) => v is any }> =
    T extends { isState: (v: any) => v is infer T } ? T : never;
  export type GetActions<T extends { isAction: (v: any) => v is any }> =
    T extends { isAction: (v: any) => v is infer T } ? T : never;
}
