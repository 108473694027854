import { isInvalid, Value } from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { Input } from "@Containers/Form/Input";
import { Selector, useSelector } from "state-manager";

export interface NameInputProps {
  value$: Selector<Value<unknown, NoEmptyString, string>>;
  onChange: (value: string) => void;
}
export function NameInput(p: NameInputProps) {
  const { t } = useTranslation();
  const value$ = flow(p.value$, (v) => v.value);
  const isError = useSelector(flow(p.value$, isInvalid));

  return (
    <Input
      label={t("Name")}
      value$={value$}
      onChange={p.onChange}
      validation={isError ? "error" : undefined}
    />
  );
}
