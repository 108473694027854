import { ReactElement, ReactNode } from "react";
import { useTranslation } from "i18n";
import { TranslatedStr } from "types/src/TranslatedStr";
import { styled } from "@mui/material/styles";
import { GlobalSheet } from "@components/Sheet";
import { Button } from "@components/Button";
import { FormWrapper } from "../FormWrapper";

export interface AdvancedFiltersProps {
  isOpen: boolean;
  headerText?: TranslatedStr;
  onClose: () => void;
  onApply: () => void;
  onClear: () => void;
  children?: ReactNode;
}

export function AdvancedFilters({
  isOpen,
  onClose,
  children,
  headerText,
  onApply,
  onClear,
}: AdvancedFiltersProps): ReactElement {
  const { t } = useTranslation();
  const hText = headerText ?? t("Filters");

  return (
    <GlobalSheet
      isOpen={isOpen}
      onClose={onClose}
      header={<div>{headerText}</div>}
      footer={
        <ButtonsGroup>
          <Button variant={"outlined"} onClick={onClear}>
            {t("Clear all")}
          </Button>
          <Button onClick={onApply}>{t("Apply")}</Button>
        </ButtonsGroup>
      }
    >
      <FormWrapper>{children}</FormWrapper>
    </GlobalSheet>
  );
}

const ButtonsGroup = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${(p) => p.theme.spacing(1)};
`;
