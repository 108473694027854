import { RootState, useSelector } from "state-manager";
import { Picker as InputComponent } from "ui/components/Picker";
import { ReactElement } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface PickerProps<T> {
  label?: TranslatedStr;
  value$: (s: RootState) => T | undefined;
  onChange: (value: T | undefined) => void;
  options: Array<{
    value: T;
    label: TranslatedStr;
  }>;
  eq?: (a: T | undefined, b: T | undefined) => boolean;
}

export function Picker<T>({
  label,
  value$,
  onChange,
  options,
  eq = (a, b) => a === b,
}: PickerProps<T>): ReactElement {
  const value = useSelector(value$, eq);

  return (
    <InputComponent
      label={label}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
}
