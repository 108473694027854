import { Combobox } from "@Containers/Form/Combobox";
import { Selector, useSelector } from "state-manager";
import { isInvalid, Value } from "types/src/FormValue";
import {
  RepositoryType,
  repositoryTypes,
} from "types/src/Repositories/RepositoryType";
import { useTranslation } from "i18n";
import { useMemo } from "react";
import { useRepositoryTypeTitle } from "@Hooks/useRepositoryTypeTitle";
import { flow } from "fp-ts/function";

export interface RepositoryTypeSelectProps {
  value$: Selector<Value<unknown, RepositoryType, RepositoryType | undefined>>;
  onChange: (value: RepositoryType) => void;
}

export function RepositoryTypeSelect(p: RepositoryTypeSelectProps) {
  const { t } = useTranslation();
  const getTitle = useRepositoryTypeTitle();
  const options = useMemo(
    () => repositoryTypes.map((t) => ({ value: t, label: getTitle(t) })),
    [getTitle],
  );
  const value$ = flow(p.value$, (v) => v.value);
  const isError = useSelector(flow(p.value$, isInvalid));

  return (
    <Combobox
      label={t("Type")}
      value$={value$}
      onChange={p.onChange}
      options={options}
      validation={isError ? "error" : undefined}
    />
  );
}
