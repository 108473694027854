import { ReactElement, useState } from "react";
import { Selector, useSelector } from "state-manager";
import { StocksListing as Listing } from "state-manager/states/Ready/states/Stocks/states/Listing";
import * as Fp from "fp-ts/function";
import { eqByKey } from "utils/eq";
import { unreachableError } from "utils/exceptions";
import { Loading } from "ui/layouts/Loading";
import { AdvancedFilters } from "ui/layouts/Filters/AdvancedFilters";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { useTranslation } from "i18n";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import { Toggle } from "@Containers/Form/Toggle";
import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Orphans } from "@Containers/Filters/Orphans";
import { CreatedTo } from "@Containers/Filters/CreatedTo";
import { MultiSelect } from "@Containers/Filters/MultiSelect";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { StockId } from "types/src/Stocks/Stock";
import { RepositoryId } from "types/src/Repositories/Repository";
import { CreatedFrom } from "@Containers/Filters/CreatedFrom";
import { NumberFrom, NumberTo } from "@Containers/Filters/NumberRange";
import { Loading as LoadingState } from "../../../../../../../packages/state-manager/src/generic-states/Loading";
import { ItemsTable } from "./ItemsTable";

const filters = Listing.instance.subStates.filters;
const items = Listing.instance.subStates.items;
const stocks = Listing.instance.subStates.stocks;
const repositories = Listing.instance.subStates.repositories;

export interface ContentProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Content(p: ContentProps): ReactElement {
  const [open, setOpen] = useState(false);
  const data = useSelector(
    Fp.flow(p.selector, (s) => {
      if (Listing.instance.states.loading.is(s))
        return { type: "loading" } as const;
      if (
        Listing.instance.states.ready.is(s) ||
        Listing.instance.states.fetching.is(s)
      ) {
        return {
          type: "items",
          selector: Fp.flow(p.selector, (st) => st as typeof s),
        } as const;
      }
      if (Listing.instance.states.loadError.is(s))
        return { type: "error" } as const;

      unreachableError(s);
      return { type: "loading" } as const;
    }),
    eqByKey("type"),
  );

  switch (data.type) {
    case "loading":
      return <Loading />;
    case "items":
      return (
        <>
          <ListingWrapper
            header={
              <FiltersWrapper>
                <SearchInput
                  value$={Fp.flow(
                    p.selector,
                    (v) => v.payload.filters.payload.fields.search,
                    O.getOrElse(() => ""),
                  )}
                  onChange={Fp.flow(
                    NoEmptyString.fromString,
                    (v) => filters.actions.setValue.create({ search: v }),
                    p.dispatch,
                  )}
                />
                <FiltersTrigger
                  onClick={() => setOpen(true)}
                  selector={Fp.flow(p.selector, (v) => v.payload.filters)}
                  dispatch={p.dispatch}
                />
              </FiltersWrapper>
            }
          >
            <ItemsTable
              selector={Fp.flow(data.selector, (v) => v.payload.items)}
              dispatch={p.dispatch}
            />
          </ListingWrapper>
          <AdvancedFilters
            isOpen={open}
            onClose={() => setOpen(false)}
            onApply={Fp.flow(filters.actions.submit.create, p.dispatch)}
            onClear={Fp.flow(filters.actions.reset.create, p.dispatch)}
          >
            <FiltersForm selector={data.selector} dispatch={p.dispatch} />
          </AdvancedFilters>
        </>
      );
    case "error":
      return <div>Error</div>;
  }
}

interface FiltersTriggerProps {
  onClick: () => void;
  selector: Selector<Listing.State["payload"]["filters"]>;
  dispatch: (a: Listing.Actions) => void;
}
function FiltersTrigger(p: FiltersTriggerProps) {
  const isSelected = useSelector(
    Fp.flow(p.selector, (s) => !filters.states.idle.is(s)),
  );

  return (
    <FiltersButton
      onClick={p.onClick}
      isSelected={isSelected}
      onClear={Fp.flow(filters.actions.reset.create, p.dispatch)}
    />
  );
}

interface FiltersFormProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}
function FiltersForm(p: FiltersFormProps): ReactElement {
  const { t } = useTranslation();
  const filters$ = Fp.flow(p.selector, (v) => v.payload.filters);

  return (
    <FormWrapper>
      <MultiSelect
        label={t("By id")}
        selector$={Fp.flow(p.selector, (s) => ({
          value: s.payload.filters.payload.fields.id,
          data: LoadingState.mapData(
            stocks,
            (vs) =>
              vs.map((v) => ({ value: v.id, label: v.name as TranslatedStr })),
            s.payload.stocks,
          ),
        }))}
        onChange={(v: O.Option<StockId>) =>
          p.dispatch(filters.actions.setValue.create({ id: v }))
        }
        onSearch={Fp.flow(
          NoEmptyString.fromString,
          stocks.actions.setQuery.create,
          p.dispatch,
        )}
        prefix={stocks.prefix}
      />
      <Toggle
        value$={Fp.flow(filters$, (v) => v.payload.fields.latest)}
        onChange={(v) =>
          p.dispatch(
            filters.actions.setValue.create({
              latest: v,
            }),
          )
        }
      >
        {t("Latest only")}
      </Toggle>

      <Orphans
        selector$={Fp.flow(p.selector, (v) => v.payload.filters)}
        dispatch={p.dispatch}
        actions={filters.actions}
      />
      <MultiSelect
        label={t("By inventory item")}
        selector$={Fp.flow(p.selector, (s) => ({
          value: s.payload.filters.payload.fields.item,
          data: LoadingState.mapData(
            items,
            (vs) =>
              vs.map((v) => ({ value: v.id, label: v.name as TranslatedStr })),
            s.payload.inventoryItems,
          ),
        }))}
        onChange={(v: O.Option<InventoryItemId>) =>
          p.dispatch(
            filters.actions.setValue.create({
              item: v,
            }),
          )
        }
        onSearch={Fp.flow(
          NoEmptyString.fromString,
          items.actions.setQuery.create,
          p.dispatch,
        )}
        prefix={items.prefix}
      />
      <MultiSelect
        label={t("By repository")}
        selector$={Fp.flow(p.selector, (s) => ({
          value: s.payload.filters.payload.fields.repository,
          data: LoadingState.mapData(
            repositories,
            (vs) =>
              vs.map((v) => ({ value: v.id, label: v.name as TranslatedStr })),
            s.payload.repositories,
          ),
        }))}
        onChange={(v: O.Option<RepositoryId>) =>
          p.dispatch(
            filters.actions.setValue.create({
              repository: v,
            }),
          )
        }
        onSearch={Fp.flow(
          NoEmptyString.fromString,
          repositories.actions.setQuery.create,
          p.dispatch,
        )}
        prefix={repositories.prefix}
      />
      <NumberFrom
        label={t("Quantity from")}
        selector$={Fp.flow(filters$, (v) => v.payload.fields.quantity)}
        onChange={(v) => {
          p.dispatch(filters.actions.setValue.create({ quantity: v }));
        }}
      />
      <NumberTo
        label={t("Quantity to")}
        selector$={Fp.flow(filters$, (v) => v.payload.fields.quantity)}
        onChange={(v) => {
          p.dispatch(filters.actions.setValue.create({ quantity: v }));
        }}
      />
      <CreatedFrom
        selector$={filters$}
        dispatch={p.dispatch}
        actions={filters.actions}
      />
      <CreatedTo
        selector$={filters$}
        dispatch={p.dispatch}
        actions={filters.actions}
      />
    </FormWrapper>
  );
}
