import React, { useRef } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Search as Icon } from "icons";
import { Suggestions } from "@layouts/ListingTable/Suggestions";
import { useSuggestionsController } from "@layouts/ListingTable/Suggestions/hooks/useSuggestionsController";
import { styleButtonBorderColor, stylesIcon } from "../../../utils/styles";
import { FilterTypeText, FilterPropsPredefined } from "../../../types/filters";

export const Text = ({
  config: { options },
  value: { text = "" } = {},
  onChange,
}: FilterPropsPredefined<FilterTypeText>) => {
  const wrapperRef = useRef<Element>();
  const suggestions = useSuggestionsController({
    data$: options.suggestions$,
    onPick: onChange,
    text,
  });

  return (
    <>
      <OutlinedInput
        ref={wrapperRef}
        size="small"
        sx={sx}
        startAdornment={icon}
        placeholder={options.placeholder || options.label}
        value={text}
        onChange={({ target: { value: text } }) => onChange({ text })}
        onFocus={() =>
          wrapperRef.current &&
          suggestions.open({ anchorEl: wrapperRef.current })
        }
      />
      {suggestions.isOpen && suggestions.props && !!text.trim().length && (
        <Suggestions {...suggestions.props} />
      )}
    </>
  );
};

const sx: SxProps<Theme> = (theme) => ({
  width: theme.spacing(100),
  maxWidth: theme.spacing(100),
  padding: theme.spacing(2.5, 3.5),
  gap: theme.spacing(2),

  boxShadow: 0,
  border: 0,
  borderRadius: 2,
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 2,
    border: `1px solid ${styleButtonBorderColor(theme)}`,
  },
  ".MuiInputBase-input": {
    height: theme.spacing(6),
    padding: 0,
  },
});

const icon = <Icon sx={stylesIcon} />;
