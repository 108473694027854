import { JSONSchema7 } from "json-schema";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { jsonParse } from "utils/object";
import * as O from "fp-ts/lib/Option";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DataTypeFragmentFragment } from "../generated/graphql";

export function dataTypeToJsonSchema(schema: DataTypeSchema): JSONSchema7 {
  return schema;
}

export function apiEntityToDataTypeEntity(entity: string): DataTypeEntity {
  switch (entity) {
    case "attachment":
      return DataTypeEntity.Attachment;
    case "collection_movement":
      return DataTypeEntity.CollectionMovement;
    case "customer":
      return DataTypeEntity.Customer;
    case "inbound":
      return DataTypeEntity.Inbound;
    case "inbound_item":
      return DataTypeEntity.InboundItem;
    case "item":
      return DataTypeEntity.Item;
    case "item_set":
      return DataTypeEntity.ItemSet;
    case "movement":
      return DataTypeEntity.Movement;
    case "order":
      return DataTypeEntity.OrderItem;
    case "order_item":
      return DataTypeEntity.Other;
    case "repository":
      return DataTypeEntity.Repository;
    case "supplier":
      return DataTypeEntity.Supplier;
    default:
      return DataTypeEntity.Other;
  }
}

export function dataTypeEntityToApiEntity(entity: DataTypeEntity): string {
  switch (entity) {
    case DataTypeEntity.Attachment:
    case DataTypeEntity.CollectionMovement:
    case DataTypeEntity.Customer:
    case DataTypeEntity.Inbound:
    case DataTypeEntity.InboundItem:
    case DataTypeEntity.Item:
    case DataTypeEntity.ItemSet:
    case DataTypeEntity.Movement:
    case DataTypeEntity.Order:
    case DataTypeEntity.OrderItem:
    case DataTypeEntity.Other:
    case DataTypeEntity.Repository:
    case DataTypeEntity.Supplier:
      return entity;
  }
}

export function jsonSchemaToDataTypeSchema(
  schema: JSONSchema7,
): DataTypeSchema {
  return schema as DataTypeSchema;
}

export function dataTypeFragmentToDataType(
  fragment: DataTypeFragmentFragment,
): DataType {
  return {
    id: fragment.id as DataTypeId,
    name: fragment.name as TranslatedStr,
    description: fragment.description as TranslatedStr,
    entity: apiEntityToDataTypeEntity(fragment.entity),
    default: fragment.default,
    schema: jsonSchemaToDataTypeSchema(
      jsonParse(fragment.jsonSchema) as JSONSchema7,
    ),
    frontendSchema: jsonParse(fragment.jsonSchema) as object | undefined,
    createdAt: fragment.createdAt,
    updatedAt: O.fromNullable(fragment.updatedAt),
    deletedAt: O.fromNullable(fragment.deletedAt),
  };
}
