import { styled } from "@mui/material/styles";

export function AltairSandbox() {
  return (
    <html>
      <head>
        <title>Altair</title>
        <base href="/altair/dist/" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/x-icon" href="favicon.ico" />
        <link rel="stylesheet" href="styles.css" />
      </head>
      <body>
        <Wrapper>
          {/* @ts-expect-error altair library require this type of markup */}
          <app-root>
            <div className="loading-screen styled">
              <div className="loading-screen-inner">
                <div className="loading-screen-logo-container"></div>
                <div className="loading-screen-loading-indicator">
                  <span className="loading-indicator-dot"></span>
                  <span className="loading-indicator-dot"></span>
                  <span className="loading-indicator-dot"></span>
                </div>
              </div>
            </div>
            {/* @ts-expect-error altair library require this type of markup */}
          </app-root>
        </Wrapper>
        <script type="text/javascript" src="runtime.js" />
        <script type="text/javascript" src="polyfills.js" />
        <script type="text/javascript" src="main.js" />
      </body>
    </html>
  );
}

const Wrapper = styled("div")`
  .loading-screen {
    display: none;
  }

  padding: 0;
  flex: 1;
  min-height: 0;
  max-height: 100%;
`;
