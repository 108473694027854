import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export function Loading() {
  return (
    <Wrapper>
      <CircularProgress size={"55px"} />
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
