import { Box } from "@mui/material";
import React from "react";
import { Sxs } from "../../../types/styles";
import { HighlightText } from "./HighlightText";

export namespace List {
  export type Item<ID extends PropertyKey> = {
    id: ID;
    text: string;
  };

  export type Props<ID extends PropertyKey> = {
    text: string;
    items: Array<Item<ID>>;
    onPick: (item: Item<ID>) => void;
  };
}

export const List = <ID extends PropertyKey>({
  text,
  items,
  onPick,
}: List.Props<ID>) => (
  // todo: scroll bar
  <Box sx={sxs.wrapper}>
    {items.map((item) => (
      <Box key={item.id.toString()} sx={sxs.item}>
        <Box onClick={() => onPick(item)} sx={sxs.itemInner}>
          <HighlightText highlight={text} text={item.text} />
        </Box>
      </Box>
    ))}
  </Box>
);

const sxs = {
  wrapper: () => ({
    py: 1,
  }),
  item: () => ({
    py: 0.25,
    px: 1.5,
  }),
  itemInner: (theme) => ({
    ...theme.typography.body2,

    padding: 2,
    paddingRight: 2.5,
    borderRadius: 1.6,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.surface.primary,
    },
  }),
} satisfies Sxs;
