import { gql } from "@apollo/client";
import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { _gte, _lte } from "../../utils";
import { Client, notFoundError, QueryResponse } from "../../index";
import { repositoryMovementFragment } from "../../fragments/RepositoryMovement";
import {
  GetRepositoryMovementsQuery,
  GetRepositoryMovementsQueryVariables,
  RepositoryMovementOrderField,
} from "../../generated/graphql";
import { repositoryMovementFragmentToRepositoryMovement } from "../../transformers/RepositoryMovements";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export interface GetRepositoryMovementsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: {
    createdAt?: [ISODate | undefined, ISODate | undefined];
    updatedAt?: [ISODate | undefined, ISODate | undefined];
    dataTypes?: NonEmptyArray.NonEmptyArray<DataTypeId>;
    id?: string;
    search?: string;
    orphans?: boolean;
    executed?: boolean;
  };
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}
export function getRepositoryMovements(
  client: Client,
  vars: GetRepositoryMovementsVars,
): Promise<PaginatedQueryResponse<RepositoryMovement>> {
  const query = gql`
    ${pageInfoFragment}
    ${repositoryMovementFragment}

    query GetRepositoryMovements(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: RepositoryMovementWhereInput
      $orderBy: RepositoryMovementOrder
    ) {
      repositoryMovements(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...RepositoryMovementFragment
          }
        }
      }
    }
  `;

  const createdAt = pipe(vars.where?.createdAt, O.fromNullable);
  const updatedAt = pipe(vars.where?.updatedAt, O.fromNullable);

  return client
    .query<GetRepositoryMovementsQuery, GetRepositoryMovementsQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: {
          and: [
            {
              createdAtGTE: pipe(createdAt, _gte),
              updatedAtGTE: pipe(updatedAt, _gte),
            },
            {
              createdAtLTE: pipe(createdAt, _lte),
              updatedAtLTE: pipe(updatedAt, _lte),
            },
          ],
          id: vars.where?.id,
          DataContains: vars.where?.search ? [vars.where.search] : undefined,
          dataTypeIDIn: vars.where?.dataTypes,
          dataTypeIDIsNil: vars.where?.orphans === true ? true : undefined,
          dataTypeIDNotNil: vars.where?.orphans === false ? true : undefined,
          executed: vars.where?.executed,
        },
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: RepositoryMovementOrderField.CreatedAt,
              updatedAt: RepositoryMovementOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      flow(
        E.map((r) => r.repositoryMovements),
        E.map(
          getPaginatedQueryResult(
            repositoryMovementFragmentToRepositoryMovement,
          ),
        ),
      ),
    );
}

export type GetItemMovementQueryResult = RepositoryMovement;
export function getRepositoryMovement(
  client: Client,
  id: RepositoryMovementId,
): Promise<QueryResponse<GetItemMovementQueryResult>> {
  return getRepositoryMovements(client, { where: { id } }).then(
    flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}
