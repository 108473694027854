import { useTranslation } from "i18n";
import { Button } from "@components/Button";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Footer as FooterLayout } from "../Single/Footer";

export interface FooterProps {
  disabled: boolean;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  submitTitle: TranslatedStr;
}

export function Footer(p: FooterProps) {
  const { t } = useTranslation();

  return (
    <FooterLayout>
      <Button color={"secondary"} onClick={p.onCancel} disabled={p.disabled}>
        {t("Cancel")}
      </Button>
      <Button color={"primary"} onClick={p.onSubmit} disabled={p.disabled}>
        {p.submitTitle}
      </Button>
    </FooterLayout>
  );
}
