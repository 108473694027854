import { forwardRef, ReactNode } from "react";
import Stack from "@mui/material/Stack";

export interface ColumnsProps {
  children?: ReactNode;
}

export const FormColumns = forwardRef<HTMLDivElement, ColumnsProps>(
  (p: ColumnsProps, ref) => {
    return (
      <Stack
        direction={"row"}
        spacing={4}
        useFlexGap
        ref={ref}
        sx={{ flexWrap: "wrap" }}
      >
        {p.children}
      </Stack>
    );
  },
);
