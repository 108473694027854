import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import Typography from "@mui/material/Typography";

export namespace ID {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.id>["renderProps"]
  >;
}

export const ID = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  id,
  onClick,
}: ID.Props<DataEntry, ColumnConfig>) => {
  const str = id.toString();
  const text = str.substring(str.length - 4);

  return (
    <Typography
      variant="subtitle2"
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : "default" }}
    >
      {text}
    </Typography>
  );
};
