import { Footer as FooterLayout } from "ui/layouts/Edit/Footer";
import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Edit";
import { flow } from "fp-ts/function";

export interface FooterProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
  onCancel: () => void;
}

export function Footer({ selector, dispatch, onCancel }: FooterProps) {
  const isSaving = useSelector(flow(selector, Edit.isSaving));

  return (
    <FooterLayout
      disabled={isSaving}
      onSubmit={() => dispatch(Edit.submit())}
      onCancel={onCancel}
    />
  );
}
