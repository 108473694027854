import { styled } from "@mui/material/styles";
import { ComponentProps, JSXElementConstructor } from "react";

export const Login: JSXElementConstructor<ComponentProps<"div">> = styled(
  "div",
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
`;
