import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

import { DataTypes } from "./states/DataTypes";
import { Customers } from "./states/Customers";
import { Suppliers } from "./states/Suppliers";
import { Repositories } from "./states/Repositories";
import { InventoryItems } from "./states/InventoryItems";
import { ItemMovements } from "./states/ItemMovements";
import { RepositoryMovements } from "./states/RepositoryMovements";
import { pickingOrderState } from "./utils";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (Customers.instance.isState(s.payload.subState)) {
    if (Customers.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Customers.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Suppliers.instance.isState(s.payload.subState)) {
    if (Suppliers.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Suppliers.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Repositories.instance.isState(s.payload.subState)) {
    if (Repositories.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Repositories.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (InventoryItems.instance.isState(s.payload.subState)) {
    if (InventoryItems.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: InventoryItems.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (ItemMovements.instance.isState(s.payload.subState)) {
    if (ItemMovements.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: ItemMovements.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (RepositoryMovements.instance.isState(s.payload.subState)) {
    if (RepositoryMovements.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: RepositoryMovements.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (pickingOrderState.isState(s.payload.subState)) {
    if (pickingOrderState.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: pickingOrderState.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  switch (a.type) {
    case "Ready:DataManager:LoadFail": {
      return pipe(
        s,
        O.fromPredicate(State.isLoading),
        O.map((s) => State.loadError(s.payload)),
        O.getOrElse(() => s),
      );
    }

    case "Ready:DataManager:LoadSuccess": {
      return pipe(
        s,
        O.fromPredicate(State.isLoading),
        O.map((s) =>
          State.ready({
            ...s.payload,
            dataTypes: a.payload.dataTypes,
          }),
        ),
        O.getOrElse(() => s),
      );
    }
  }

  /*
  This is a hack have a type safe reducer
   */
  if (DataTypes.instance.isAction(a)) return s;
  if (Customers.instance.isAction(a)) return s;
  if (Suppliers.instance.isAction(a)) return s;
  if (Repositories.instance.isAction(a)) return s;
  if (InventoryItems.instance.isAction(a)) return s;
  if (ItemMovements.instance.isAction(a)) return s;
  if (RepositoryMovements.instance.isAction(a)) return s;
  if (pickingOrderState.isAction(a)) return s;

  silentUnreachableError(a);
  return s;
}
