import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

interface BaseInputProps {
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  error?: boolean;
  helpText?: string;
}

export namespace Input {
  export interface Props extends BaseInputProps {
    value?: string;
    onChange: (value: string) => void;
  }
}

export function Input(p: Input.Props) {
  return (
    <TextField
      placeholder={p.placeholder}
      disabled={p.disabled}
      label={p.label}
      helperText={p.helpText}
      error={p.error}
      value={p.value}
      slotProps={{
        input: {
          readOnly: p.readOnly,
        },
      }}
      onChange={(e) => p.onChange?.(e.target.value)}
      size={"small"}
    />
  );
}

export namespace NumberInput {
  export interface Props extends BaseInputProps {
    min?: number;
    max?: number;
    value?: number;
    onChange: (value: number | undefined) => void;
  }
}

export function NumberInput(p: NumberInput.Props) {
  return (
    <TextField
      placeholder={p.placeholder}
      disabled={p.disabled}
      label={p.label}
      helperText={p.helpText}
      error={p.error}
      value={p.value}
      slotProps={{
        input: {
          readOnly: p.readOnly,
          // @ts-expect-error, everything is good
          min: p.min,
          max: p.max,
        },
      }}
      onChange={(e) => {
        const value = e.target.value ? Number(e.target.value) : undefined;
        p.onChange?.(value);
      }}
      type="number"
      size="small"
    />
  );
}

export namespace Search {
  export interface Props extends Omit<Input.Props, "helpText" | "error"> {}
}

export function Search(p: Search.Props) {
  return (
    <OutlinedInput
      placeholder={p.placeholder}
      disabled={p.disabled}
      label={p.label}
      value={p.value}
      slotProps={{
        input: {
          readOnly: p.readOnly,
        },
      }}
      onChange={(e) => p.onChange?.(e.target.value)}
      endAdornment={<SearchRoundedIcon />}
      sx={{ flex: 1 }}
      size={"small"}
    />
  );
}
