import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as Rx from "rxjs";
import { Client, DsError, unknownError } from "ds";
import { getDataTypes, GetDataTypesVars, removeDataType } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { from } from "rxjs";
import { SymmetricTuple } from "types/src/Tuple";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingState } from "../../../../../../../../generic-states/Listing";

const prefix = "Ready:DataManager:DataTypes:Listing" as const;
type Prefix = typeof prefix;

function createState() {
  const state = ListingState.createState<
    Prefix,
    DataTypesListing.Filter,
    DataTypesListing.Item,
    "createdAt" | "updatedAt",
    {}
  >(prefix, {
    defaultFilters: {
      name: O.none,
      description: O.none,
      entity: O.none,
      default: O.none,
      createdAt: [undefined, undefined],
      updatedAt: [undefined, undefined],
    },
  });

  const epic: Epic<
    ListingState.GetActions<typeof state>,
    ListingState.GetState<typeof state>,
    { pyckAdminClient$: Rx.Observable<Client> }
  > = (state$, { pyckAdminClient$ }) => {
    return state.epic(state$, {
      fetchItems: (s) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return from(getDataTypes(client, getFetchVars(s))).pipe(
              Rx.map(
                E.map((r) => ({
                  total: r.totalCount,
                  pageInfo: r.pageInfo,
                  items: r.items,
                })),
              ),
            );
          }),
        );
      },
      removeItems: (ids) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            const ids$ = ids.map((id) => {
              return Rx.from(removeDataType(client, id)).pipe(
                Rx.catchError(() => Rx.of(E.left<DsError>(unknownError()))),
                Rx.map(E.mapLeft(() => id)),
              );
            });

            return Rx.forkJoin(ids$).pipe(
              Rx.map((v) => {
                return v.reduce(
                  (acc: SymmetricTuple<DataTypeId[]>, v) => {
                    if (E.isRight(v)) acc[1].push(v.right);
                    if (E.isLeft(v)) acc[0].push(v.left);

                    return acc;
                  },
                  [[], []],
                );
              }),
              Rx.mergeMap(([left, right]) =>
                Rx.from([E.left(left), E.right(right)]),
              ),
            );
          }),
        );
      },
    });
  };

  return { ...state, epic, init: () => state.init({}) };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetDataTypesVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetDataTypesVars["where"] = {
      name: O.toUndefined(fields.name),
      description: O.toUndefined(fields.description),
      entity: O.toUndefined(fields.entity),
      default: O.toUndefined(fields.default),
      createdAt: fields.createdAt,
      updatedAt: fields.updatedAt,
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.startCursor,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.endCursor,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          where,
        };
    }
  }
}

export namespace DataTypesListing {
  export type Filter = {
    name: O.Option<NoEmptyString>;
    description: O.Option<NoEmptyString>;
    entity: O.Option<NonEmptyArray<DataTypeEntity>>;
    default: O.Option<boolean>;
    createdAt: DateRange;
    updatedAt: DateRange;
  };

  export interface Item {
    id: DataTypeId;
    name: string;
    description: string;
    entity: DataTypeEntity;
    default: boolean;
  }

  export type State = ListingState.State<
    Prefix,
    Filter,
    Item,
    "createdAt" | "updatedAt",
    {}
  >;
  export type Actions = ListingState.Actions<
    Prefix,
    Filter,
    Item,
    "createdAt" | "updatedAt"
  >;

  export const instance = createState();
}
