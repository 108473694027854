import MuiButton, { ButtonProps } from "@mui/material/Button";
import { forwardRef } from "react";

export namespace Button {
  export interface Props
    extends Pick<
      ButtonProps,
      "children" | "startIcon" | "onClick" | "disabled" | "variant" | "color"
    > {}
}

export const Button = forwardRef<HTMLButtonElement, Button.Props>((p, ref) => (
  <MuiButton {...p} ref={ref} variant={p.variant ?? "contained"} />
));
