import { Selector, useSelector } from "state-manager";
import { Autocomplete as ComboboxComponent } from "ui/components/Autocomplete";
import { TranslatedStr } from "types/src/TranslatedStr";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as N from "fp-ts/number";
import { isDeepEqual } from "utils/object";

export interface SelectorProps<T> {
  validation?: "success" | "warning" | "error";
  value: O.Option<T>;
  options: { value: T; label: TranslatedStr }[];
  isLoading: boolean;
}

export interface SearchComboboxProps<T> {
  onChange: (value: O.Option<T>) => void;
  onSearch: (s: string) => void;
  selector: Selector<SelectorProps<T>>;
  clearable?: boolean;
  getId: (v: T) => string;
  label?: TranslatedStr;
}

export function SearchCombobox<T>({
  clearable,
  selector,
  onChange,
  onSearch,
  getId,
  label,
}: SearchComboboxProps<T>) {
  const options = useSelector(
    Fp.flow(selector, (v) => v.options),
    isDeepEqual,
  );
  const activeIndex = useSelector(
    Fp.flow(
      selector,
      (v) => v.value,
      O.map((v) => options.findIndex((i) => getId(i.value) === getId(v))),
    ),
    O.getEq(N.Eq).equals,
  );
  const validation = useSelector(Fp.flow(selector, (v) => v.validation));
  const value = Fp.pipe(
    activeIndex,
    O.map((v) => options[v]?.label),
    O.toUndefined,
  );
  const isLoading = useSelector(Fp.flow(selector, (v) => v.isLoading));

  return (
    <ComboboxComponent<T>
      label={label}
      activeIndex={O.toUndefined(activeIndex)}
      search={value}
      onSearch={onSearch}
      onChange={Fp.flow(O.fromNullable, onChange)}
      error={validation === "error"}
      options={options.map((v) => v.value)}
      getLabel={(v) => options.find((i) => i.value === v)?.label ?? ""}
      isLoading={isLoading}
      clearable={clearable}
    />
  );
}
