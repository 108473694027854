import { ReactElement } from "react";
import { Selector } from "state-manager";
import { flow } from "fp-ts/function";
import { Value } from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Input } from "@Containers/Form/FormValue/Input";
import { Number } from "@Containers/Form/FormValue/Number";
import { useTranslation } from "i18n";
import { IconButton } from "ui/components/IconButton";
import { TrashIcon } from "icons/Trash";
import { GearIcon } from "icons/Gear";
import { Inline } from "ui/components/Inline";

export interface ItemProps {
  selector: Selector<{
    sku: Value<unknown, NoEmptyString, string | undefined>;
    quantity: Value<unknown, number, number | undefined>;
  }>;
  onSetSku: (value: string | undefined) => void;
  onSetQuantity: (value: number | undefined) => void;
  onRemove: () => void;
  onAdvanced: () => void;
}

export function Item(p: ItemProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Inline gap={"m"}>
      <Input
        value={flow(p.selector, (v) => v.sku)}
        onChange={p.onSetSku}
        placeholder={t("Sku")}
      />
      <Number
        value={flow(p.selector, (v) => v.quantity)}
        onChange={p.onSetQuantity}
        min={0}
        placeholder={t("Quantity")}
      />
      <Inline>
        <IconButton onClick={p.onRemove}>
          <TrashIcon />
        </IconButton>
        <IconButton onClick={p.onAdvanced}>
          <GearIcon />
        </IconButton>
      </Inline>
    </Inline>
  );
}
