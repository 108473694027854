import { ReactNode } from "react";
import { Suggestions } from "../Suggestions";

export enum FilterGroup {
  predefined = "predefined",
  custom = "custom",
}

export enum FilterType {
  text = "text",
  select = "select",
  dateRange = "dateRange",
  numberRange = "numberRange",
  multiChoice = "multiChoice",
}

type ConfigType<
  Type extends FilterType,
  Options extends Record<string, unknown>,
  Value extends Record<string, unknown>,
> = {
  type: Type;
  options: Options & {
    label: string; // removed for "More filters" dropdown
  };
  value?: /*default*/ Value;
};

//region FilterType*
export type FilterTypeText = ConfigType<
  FilterType.text,
  {
    placeholder?: string;
    suggestions$?: Suggestions.Props["data$"];
    onChange?: (text: string) => void;
  },
  { text?: string }
>;
export type FilterTypeSelect = ConfigType<
  FilterType.select,
  {
    choices: Array<{ id: string; label: ReactNode }>;
    onChange?: (value: { id: string }) => void;
  },
  {
    id?: string;
  }
>;
export type FilterTypeMultiChoice = ConfigType<
  FilterType.multiChoice,
  { choices: Array<{ id: string; label: ReactNode }> },
  { choices?: Partial<Record<string, boolean>> }
>;
export type FilterTypeNumberRange = ConfigType<
  FilterType.numberRange,
  {},
  { start?: number; end?: number }
>;
export type FilterTypeDateRange = ConfigType<
  FilterType.dateRange,
  {
    min?: Date;
    max?: Date;
  },
  { start?: Date; end?: Date } // todo: use DateRange from 'types' package
>;
//endregion

export type /*all*/ FilterTypeMap = {
  [FilterType.text]: FilterTypeText;
  [FilterType.select]: FilterTypeSelect;
  [FilterType.multiChoice]: FilterTypeMultiChoice;
  [FilterType.numberRange]: FilterTypeNumberRange;
  [FilterType.dateRange]: FilterTypeDateRange;
};
export type FilterConfig = FilterTypeMap[FilterType];

export type PredefinedFiltersMap = {
  search: FilterTypeText;
  dateRange: FilterTypeDateRange;
};

export type CustomFilterTypeMap = {
  [FilterType.text]: FilterTypeText;
  [FilterType.select]: FilterTypeSelect;
  [FilterType.multiChoice]: FilterTypeMultiChoice;
  [FilterType.numberRange]: FilterTypeNumberRange;
};
export type CustomFilterConfig = CustomFilterTypeMap[keyof CustomFilterTypeMap];

export type FiltersConfigBase<CustomKeys extends string = string> = {
  [FilterGroup.predefined]: Partial<PredefinedFiltersMap> &
    Pick<PredefinedFiltersMap, "search">;
  [FilterGroup.custom]: Record<CustomKeys, CustomFilterConfig>;
};

export type FilterPropsPredefined<Config extends FilterConfig> = {
  config: Config;
  value: Config["value"];
  onChange: (value: Config["value"]) => void;
};
export type FilterPropsCustom<Config extends FilterConfig> = {
  config: Config;
  value: Config["value"];
  onChange: (value: Config["value"]) => void;
  onRemove: () => void;
};
