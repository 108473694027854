import deepEqual from "deep-equal";
import { shallowEqual } from "shallow-equal";

export function jsonParse<T = unknown>(json: string): T | undefined {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
}

export function keys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function entries<T extends object>(
  obj: T,
): Array<[keyof T, T[keyof T]]> {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>;
}

export function values<T extends object>(obj: T): Array<T[keyof T]> {
  return Object.values(obj);
}

export function map<T extends object, R>(
  fn: (v: T[keyof T], k: keyof T) => R,
  obj: T,
): Record<keyof T, R> {
  return (Object.entries(obj) as Array<[keyof T, T[keyof T]]>).reduce(
    (acc, [k, v]) => {
      acc[k] = fn(v, k);
      return acc;
    },
    {} as Record<keyof T, R>,
  );
}

export function filter<T extends object, B extends T[keyof T]>(
  fn: (v: T[keyof T], k: keyof T) => v is B,
  obj: T,
): { [k in keyof T]: B };
export function filter<T extends object>(
  fn: (v: T[keyof T], k: keyof T) => boolean,
  obj: T,
): T;
export function filter<T extends object>(
  fn: (v: T[keyof T], k: keyof T) => boolean,
  obj: T,
): T {
  return fromEntries(entries(obj).filter(([k, v]) => fn(v, k))) as T;
}

export function every<T extends object, B extends T[keyof T]>(
  fn: (v: T[keyof T], k: keyof T) => v is B,
  obj: T,
  // @ts-expect-error, don't know other way
): obj is { [k in keyof T]: B } {
  return entries(obj).every(([k, v]) => fn(v, k));
}

export function some<T extends object, B extends T[keyof T]>(
  fn: (v: T[keyof T], k: keyof T) => v is B,
  obj: T,
  // @ts-expect-error, don't know other way
): obj is { [k in keyof T]: B };
export function some<T extends object>(
  fn: (v: T[keyof T], k: keyof T) => boolean,
  obj: T,
): boolean;
export function some<T extends object>(
  fn: (v: T[keyof T], k: keyof T) => boolean,
  obj: T,
): boolean {
  return entries(obj).some(([k, v]) => fn(v, k));
}

export const isObject = (obj: unknown): obj is object => {
  return obj !== null && typeof obj === "object" && !Array.isArray(obj);
};

export const isDeepEqual = <
  T extends Object | Array<unknown>,
  T2 extends T = T,
>(
  a: T,
  b: T2,
): a is T2 => deepEqual(a, b);

export const isShallowEqual = <
  T extends Object | Array<unknown>,
  T2 extends T = T,
>(
  a: T,
  b: T2,
): a is T2 => shallowEqual(a, b);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromEntries<T extends readonly [any, any]>(
  ts: T[],
): Record<T[0], T[1]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromEntries<T extends [any, any]>(ts: T[]): Record<T[0], T[1]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromEntries<T extends [any, any]>(ts: T[]): Record<T[0], T[1]> {
  return Object.fromEntries(ts) as Record<T[0], T[1]>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pick = <K extends string, T extends { [k in string]: any }>(
  k: K[],
  t: T,
): { [k in K]: T[k] } => {
  return k.reduce(
    (acc, k) => {
      if (Object.hasOwn(t, k)) {
        acc[k as K] = t[k];
      }

      return acc;
    },
    {} as { [k in K]: T[k] },
  );
};
