import { Selector, useSelector } from "state-manager";
import { StocksListing as Listing } from "state-manager/states/Ready/states/Stocks/states/Listing";
import * as Single from "state-manager/states/Ready/states/Stocks/states/Single";
import { ReactElement } from "react";

import { flow } from "fp-ts/function";
import { unreachableError } from "utils/exceptions";
import * as Stocks from "../../../../../../packages/state-manager/src/states/Ready/states/Stocks";
import * as ListingContainer from "./Listing";
import * as SingleContainer from "./Single";

export interface HeaderProps {
  selector: Selector<Stocks.State>;
  dispatch: (a: Stocks.Actions) => void;
}

export function Header(p: HeaderProps): ReactElement | null {
  const data = useSelector(
    flow(p.selector, (s) => {
      if (Listing.instance.isState(s))
        return {
          type: "listing",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      if (Single.isState(s))
        return {
          type: "single",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;

      unreachableError(s);
      return { type: "none" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (data.type) {
    case "listing":
      return (
        <ListingContainer.Header
          selector={data.selector}
          dispatch={p.dispatch}
        />
      );
    case "single":
      return <SingleContainer.Header selector={data.selector} />;
    case "none":
      return null;
  }
}
