import { ReactNode, useEffect, useRef } from "react";
import { createTheme, ThemeProvider as MaterialProvider } from "@mui/material";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import { alpha } from "@mui/material/styles";
import { Check, Minus } from "icons";
import { defaultConfig } from "@mui/material/InitColorSchemeScript/InitColorSchemeScript";

export interface ThemeProviderProps {
  children?: ReactNode;
  mode: "light" | "dark";
}

export function ThemeProvider({ mode, children }: ThemeProviderProps) {
  const skipReload = useRef(true);
  useEffect(() => {
    localStorage.setItem(themeModeStorageKey, mode);

    if (skipReload.current) {
      skipReload.current = false;
    } else {
      // todo: find a solution without reload.
      //       I tried `useState(bool) -> toggle` but nothing happens
      window.location.reload();
    }
  }, [mode]);

  return (
    <MaterialProvider theme={theme} defaultMode={mode}>
      {children}
    </MaterialProvider>
  );
}

const themeModeStorageKey = defaultConfig.modeStorageKey;

const theme = createTheme({
  spacing: 4,

  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        common: {
          white: "#ffffff",
          black: "#000000",
        },
        background: {
          default: "#FCFCFD",
          paper: "#FFFFFF",
        },
        primary: {
          main: "#00E5FF",
          dark: "#0097A7",
          light: "#42A5F5",
          contrastText: "#FFFFFF",
        },
        secondary: {
          main: "#9C27B0",
          contrastText: "#FFFFFF",
        },
        error: {
          main: "#d32f2f",
          dark: "#c62828",
          light: "#ef5350",
          contrastText: "#fff",
        },
        warning: {
          main: "#ed6c02",
          dark: "#e65100",
          light: "#ff9800",
          contrastText: "#fff",
        },
        info: {
          main: "#0288d1",
          dark: "#01579b",
          light: "#03a9f4",
          contrastText: "#fff",
        },
        success: {
          main: "#2e7d32",
          dark: "#1b5e20",
          light: "#4caf50",
          contrastText: "#fff",
        },
        text: {
          primary: "rgba(0,0,0,.87)",
          secondary: "rgba(0,0,0,.50)",
          disabled: "rgba(0,0,0,.38)",
        },
        action: {
          active: "#94A3B8",
          hover: "rgba(0, 0, 0, 0.04)",
          selected: "rgba(0, 0, 0, 0.08)",
          disabled: "rgba(0, 0, 0, 0.38)",
          disabledBackground: "rgba(0, 0, 0, 0.12)",
          focus: "rgba(0, 0, 0, 0.12)",
        },
        surface: {
          primary: "#EDF1F6",
          secondary: "#FFFFFF",
        },
      },
    },
    dark: {
      palette: {
        mode: "dark",
        common: {
          white: "#ffffff",
          black: "#000000",
        },
        background: {
          default: "#1F1E26",
          paper: "#302E3B",
        },
        primary: {
          main: "#00E5FF",
          dark: "#00E5FF",
          light: "#E3F2FD",
          contrastText: "rgba(0,0,0,.87)",
        },
        surface: {
          primary: "#383744",
          secondary: "#45434F",
        },
        secondary: {
          main: "#CE93D8",
          dark: "#AB47BC",
          light: "#F3E5F5",
          contrastText: "rgba(0,0,0,.87)",
        },
        error: {
          main: "#d32f2f",
          dark: "#D32F2F",
          light: "#E57373",
          contrastText: "#fff",
        },
        warning: {
          main: "#FFA726",
          dark: "#F57C00",
          light: "#FFB74D",
          contrastText: "rgba(0,0,0,.87)",
        },
        info: {
          main: "#29B6F6",
          dark: "#0288D1",
          light: "#4FC3F7",
          contrastText: "rgba(0,0,0,.87)",
        },
        success: {
          main: "#66BB6A",
          dark: "#388E3C",
          light: "#81C784",
          contrastText: "rgba(0,0,0,.87)",
        },
        text: {
          primary: "#FFFFFF",
          secondary: "rgba(255,255,255,60)",
          disabled: "rgba(255,255,255,.38)",
        },
        action: {
          active: "#667085",
          hover: "rgba(255, 255, 255, 0.08)",
          selected: "rgba(255, 255, 255, 0.16)",
          disabled: "rgba(255, 255, 255, 0.38)",
          disabledBackground: "rgba(255, 255, 255, 0.12)",
          focus: "rgba(255, 255, 255, 0.12)",
        },
      },

      /*spacing: 8,
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
      },*/
    },
  },

  typography: (p) => ({
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    allVariants: {
      color: p.text.primary,
      fontStyle: "normal",
    },
    h1: {
      fontSize: "96px",
      fontWeight: 300,
      lineHeight: "116.7%",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: "60px",
      fontWeight: 300,
      lineHeight: "120%",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "116%",
      letterSpacing: "0",
    },
    h4: {
      fontSize: "34px",
      fontWeight: 700,
      lineHeight: "123.5%",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "133.4%",
      letterSpacing: "0",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "160%",
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "150%",
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "143%",
      letterSpacing: "0.17px",
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "175%",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "157%",
      letterSpacing: "0.1px",
    },
    overline: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "266%",
      letterSpacing: "1px",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "166%",
    },
  }),

  components: {
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:hover, &.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: (p) => {
          return {
            margin: 0,
            "& .MuiTypography-root": {
              whiteSpace: "nowrap",
              fontWeight: 600,
              color: p.theme.palette.text.primary,
            },
            ".MuiListItemButton-root.Mui-selected & .MuiTypography-root": {
              color: p.theme.palette.primary.dark,
            },
            ".MuiListItemButton-root:hover & .MuiTypography-root": {
              color: p.theme.palette.primary.dark,
            },
          };
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: (p) => ({
          ...p.theme.typography.body2,
          boxShadow: "none",
          textTransform: "none",
          borderRadius: "8px",
          padding: "10px 16px",
          whiteSpace: "nowrap",
          variants: [
            {
              props: { color: "secondary" as const },
              style: (p) => {
                return {
                  backgroundColor: p.theme.palette.background.paper,
                };
              },
            },
            {
              props: { variant: "contained" as const },
              style: (p) => {
                return {
                  color: p.theme.palette.common.black,
                };
              },
            },
            {
              props: { variant: "text" as const },
              style: (p) => {
                return {
                  color: p.theme.palette.action.active,
                };
              },
            },
          ],
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: (p) => ({
          boxShadow: "none",
          background: p.theme.palette.surface.primary,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: (p) => ({
          ...p.theme.typography.body2,
          color: alpha(p.theme.palette.text.secondary, 0.5),
          "&.Mui-focused": {
            color: alpha(p.theme.palette.text.secondary, 0.5),
          },
        }),
        focused: (p) => ({
          transform: "rotateX(45deg)",
          color: alpha(p.theme.palette.text.secondary, 0.5),
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: "6px",
          ".MuiFormLabel-root": {
            transform: "none",
            position: "relative",
          },
          ".MuiInputBase-root": {
            margin: 0,
            "&:before": { content: "unset" },
            "&:after": { content: "unset" },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0, // remove negative margin hack for default checkbox
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: (p) => ({
          background: p.theme.palette.background.paper,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: alpha(p.theme.palette.common.black, 0.1),
          borderRadius: p.theme.spacing(2),
          padding: `${p.theme.spacing(2.5)} ${p.theme.spacing(3.5)}`,
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        }),
        input: {
          padding: 0,
        },
        error: (p) => ({
          borderColor: alpha(p.theme.palette.error.main, 0.5),
          boxShadow: `0px 1px 2px 0px ${alpha(
            p.theme.palette.error.main,
            0.1,
          )}`,
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: (p) => ({
          padding: `${p.theme.spacing(2.5)} ${p.theme.spacing(3.5)}`,
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <Check />,
        indeterminateIcon: <Minus />,
      },
      styleOverrides: {
        root: (p) => ({
          width: p.theme.spacing(4),
          height: p.theme.spacing(4),
          padding: p.theme.spacing(1),
          opacity: 0.5,
          "&:before": {
            content: "' '",
            borderRadius: p.theme.spacing(1),
            borderStyle: "solid",
            borderWith: 1,
            position: "absolute",
            inset: 0,
          },
          svg: {
            display: "none",
          },
          "&.Mui-checked": {
            borderColor: p.theme.palette.primary.light,
            color: p.theme.palette.primary.light,
            opacity: 1,
            svg: {
              display: "block",
            },
          },
          "&.Mui-disabled": {
            opacity: 0.8,
            filter: "grayscale(1)",
            background: p.theme.palette.surface.primary,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: (p) => ({
          boxShadow:
            "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
        }),
        list: (p) => ({
          padding: 0,
        }),
        paper: (p) => ({
          borderRadius: p.theme.spacing(2),
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: alpha(p.theme.palette.common.black, 0.1),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: (p) => ({
          ...p.theme.typography.body2,
          padding: `${p.theme.spacing(2.5)} ${p.theme.spacing(4)}`,
        }),
      },
    },
  },
});
