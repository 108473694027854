import { Customers as Main } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";

import { GlobalSheet } from "ui/components/Sheet";
import * as DataTypesCreateContainer from "../DataTypes/Create";
import * as DataTypesEditContainer from "../DataTypes/Edit";
import * as ListingAllContainer from "./ListingAll";
import * as CreateContainer from "./Create";
import * as EditContainer from "./Edit";
import * as ListingContainer from "./Listing";

const { dataTypesCreate, dataTypesEdit, listingAll, listing, create, edit } =
  Main.instance.subStates;

export interface ContentProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
}

export function Content(p: ContentProps) {
  const content = useSelector(
    flow(
      p.selector,
      (s) => s.payload.listing,
      (s) => {
        if (listingAll.isState(s))
          return {
            type: "listing-all",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        if (listing.isState(s))
          return {
            type: "listing",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );
  const sheet = useSelector(
    flow(
      p.selector,
      (s) => s.payload.single,
      (s) => {
        if (s === undefined) {
          return {
            type: "unknown",
          } as const;
        }

        if (create.isState(s))
          return {
            type: "create",
            selector: flow(
              p.selector,
              (s) => s.payload.single,
              (st) => st as typeof s,
            ),
          } as const;

        if (edit.isState(s))
          return {
            type: "edit",
            selector: flow(
              p.selector,
              (s) => s.payload.single,
              (st) => st as typeof s,
            ),
          } as const;

        if (dataTypesCreate.isState(s))
          return {
            type: "type:create",
            selector: flow(
              p.selector,
              (s) => s.payload.single,
              (st) => st as typeof s,
            ),
          } as const;

        if (dataTypesEdit.isState(s))
          return {
            type: "type:edit",
            selector: flow(
              p.selector,
              (s) => s.payload.single,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  return (
    <>
      {getContent()}
      {getSheet()}
    </>
  );

  function getContent() {
    switch (content.type) {
      case "listing-all":
        return (
          <ListingAllContainer.Content
            selector={content.selector}
            dispatch={p.dispatch}
            onCreateNewType={Fp.flow(
              Main.instance.actions.goToCreateType.create,
              p.dispatch,
            )}
            onItemClick={Fp.flow(
              Main.instance.actions.goToEdit.create,
              p.dispatch,
            )}
            onTypeClick={Fp.flow(
              Main.instance.actions.goToEditType.create,
              p.dispatch,
            )}
          />
        );
      case "listing":
        return (
          <ListingContainer.Content
            selector={content.selector}
            dispatch={p.dispatch}
            onCreateNewType={Fp.flow(
              Main.instance.actions.goToCreateType.create,
              p.dispatch,
            )}
            onItemClick={Fp.flow(
              Main.instance.actions.goToEdit.create,
              p.dispatch,
            )}
            onTypeClick={Fp.flow(
              Main.instance.actions.goToEditType.create,
              p.dispatch,
            )}
          />
        );
      case "unknown":
        return null;
    }
  }

  function getSheet() {
    switch (sheet.type) {
      case "create":
        return (
          <GlobalSheet
            isOpen
            onClose={Fp.flow(
              Main.instance.actions.exitSingle.create,
              p.dispatch,
            )}
            header={
              <CreateContainer.Header
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
            footer={
              <CreateContainer.Footer
                selector={sheet.selector}
                dispatch={p.dispatch}
                onCancel={Fp.flow(
                  Main.instance.actions.exitSingle.create,
                  p.dispatch,
                )}
              />
            }
          >
            <CreateContainer.Content
              selector={sheet.selector}
              dispatch={p.dispatch}
            />
          </GlobalSheet>
        );
      case "edit":
        return (
          <GlobalSheet
            isOpen
            onClose={Fp.flow(
              Main.instance.actions.exitSingle.create,
              p.dispatch,
            )}
            header={
              <EditContainer.Header
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
            footer={
              <EditContainer.Footer
                selector={sheet.selector}
                dispatch={p.dispatch}
                onCancel={Fp.flow(
                  Main.instance.actions.exitSingle.create,
                  p.dispatch,
                )}
              />
            }
          >
            <EditContainer.Content
              selector={sheet.selector}
              dispatch={p.dispatch}
            />
          </GlobalSheet>
        );
      case "type:create":
        return (
          <GlobalSheet
            isOpen
            onClose={Fp.flow(
              Main.instance.actions.exitSingle.create,
              p.dispatch,
            )}
            header={
              <DataTypesCreateContainer.Header
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
            footer={
              <DataTypesCreateContainer.Footer
                selector={sheet.selector}
                dispatch={p.dispatch}
                onCancel={Fp.flow(
                  Main.instance.actions.exitSingle.create,
                  p.dispatch,
                )}
              />
            }
          >
            <DataTypesCreateContainer.Content
              selector={sheet.selector}
              dispatch={p.dispatch}
            />
          </GlobalSheet>
        );
      case "type:edit":
        return (
          <GlobalSheet
            isOpen
            onClose={Fp.flow(
              Main.instance.actions.exitSingle.create,
              p.dispatch,
            )}
            header={
              <DataTypesEditContainer.Header
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
            footer={
              <DataTypesEditContainer.Footer
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
          >
            <DataTypesEditContainer.Content
              selector={sheet.selector}
              dispatch={p.dispatch}
            />
          </GlobalSheet>
        );
      case "unknown":
        return <GlobalSheet isOpen={false} />;
    }
  }
}
