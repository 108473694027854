import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { FilterConfig, FiltersConfigBase } from "../types/filters";
import { Criteria, CriteriaChange } from "../types/criteria";

const mergeCriteriaFiltersChanges = (
  criteria: Partial<Record<string, FilterConfig["value"]>>,
  changes: Partial<Record<string, O.Option<FilterConfig["value"]>>>,
): Partial<Record<string, FilterConfig["value"]>> =>
  Fp.pipe(
    Object.entries(criteria ?? {}),
    // remove keys that are None in changes
    (pairsValues) =>
      pairsValues.filter(([id]) => {
        const newValue = changes?.[id];
        return !(newValue && O.isNone(newValue));
      }, {}),
    // update keys that are Some in changes
    (pairsValues) =>
      pairsValues.map(([id, currentValue]): (typeof pairsValues)[number] => {
        if (Object.hasOwn(changes || {}, id)) {
          const newValue = changes?.[id];
          if (newValue) {
            if (O.isSome(newValue)) {
              return [id, O.getOrElse(() => currentValue)(newValue)];
            }
          } else {
            return [id, newValue];
          }
        }
        return [id, currentValue];
      }),
    // append keys that are new in changes
    (pairsValues): typeof pairsValues => {
      type PairValue = (typeof pairsValues)[number];

      const newKeyValues = { ...(changes || {}) };
      pairsValues.forEach(([id]) => {
        delete newKeyValues[id];
      });

      return [
        ...pairsValues,
        ...Fp.pipe(
          Object.entries(newKeyValues),
          // remove None/deleted
          (pairsChanges) =>
            pairsChanges.filter(
              ([_, newValue]) => !(newValue && O.isNone(newValue)),
            ),
          (pairsChanges) =>
            pairsChanges.map(
              ([id, newValue]): PairValue => [
                id as PairValue[0],
                newValue
                  ? O.getOrElse((): PairValue[1] => undefined)(newValue)
                  : undefined,
              ],
            ),
        ),
      ];
    },
    (pairs) =>
      pairs.reduce(
        (carry, [id, value]) => ({
          ...carry,
          [String(id)]: value,
        }),
        {},
      ),
  );

export const mergeCriteriaChanges = <
  Columns,
  FiltersConfig extends FiltersConfigBase,
>(
  criteria: Criteria<Columns, FiltersConfig>,
  changes: CriteriaChange<Columns, FiltersConfig>,
): Criteria<Columns, FiltersConfig> => ({
  ...criteria,
  filters: {
    ...criteria.filters,

    predefined: mergeCriteriaFiltersChanges(
      criteria.filters?.predefined || {},
      changes.filters?.predefined || {},
    ) as Exclude<
      Criteria<Columns, FiltersConfig>["filters"],
      undefined
    >["predefined"],

    custom: mergeCriteriaFiltersChanges(
      criteria.filters?.custom || {},
      changes.filters?.custom || {},
    ) as Exclude<
      Criteria<Columns, FiltersConfig>["filters"],
      undefined
    >["custom"],
  },
  orderBy: changes.orderBy ?? criteria.orderBy,
});
