import { FormControlLabel, Switch } from "@mui/material";
import { ReactNode } from "react";

export namespace Toggle {
  export interface Props {
    value: boolean;
    onChange?: (value: boolean) => void;
    readonly?: boolean;
    disabled?: boolean;
    children?: ReactNode;
  }
}

export function Toggle(p: Toggle.Props) {
  return (
    <FormControlLabel
      control={
        <Switch
          readOnly={p.readonly}
          disabled={p.disabled}
          value={p.value}
          onChange={() => p.onChange?.(!p.value)}
        />
      }
      label={p.children}
    />
  );
}
