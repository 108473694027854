import { useTranslation } from "i18n";
import { Selector } from "state-manager";
import * as Create from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Create";

import { Header as Layout } from "ui/layouts/Single/Header";

export interface HeaderProps {
  selector: Selector<Create.State>;
  dispatch: (a: Create.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Layout>{t("Add new movement")}</Layout>;
}
