import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { ListingTable } from "ui/layouts/ListingTable";
import { RepositoryId } from "types/src/Repositories/Repository";

export namespace RepositoriesListing {
  export interface Item {
    id: RepositoryId;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
    name: string;
  }

  export interface Props {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    loading$: BehaviorValue<boolean>;
    filters$: BehaviorValue<{
      search: O.Option<NoEmptyString>;
      created: DateRange;
    }>;
    orderBy$: BehaviorValue<O.Option<OrderBy<"createdAt" | "updatedAt">>>;
    bulkSelect: Omit<
      EntityListing.Props<Item, "remove">["bulkSelect"],
      "actions"
    >;
    remove: Omit<
      EntityListing.Props<Item, "remove">["remove"],
      "getDescription" | "getTitle"
    >;

    onCreateNew: (() => void) | undefined;
    onCreateNewType: () => void;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: RepositoryId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onFilterChange: (
      v: Partial<{
        search: O.Option<NoEmptyString>;
        created: DateRange;
      }>,
    ) => void;
    onOrderChange: (p: "createdAt" | "updatedAt") => void;
  }
}

export function RepositoriesListing(p: RepositoriesListing.Props) {
  const { t } = useTranslation();

  return (
    <EntityListing
      title={t("Recent repositories")}
      newItemTitle={t("New repository")}
      newTypeTitle={t("New repository type")}
      columns={{
        name: {
          label: t("Name"),
          type: ListingTable.CellType.text,
          renderProps: (v) => ({
            text: v.name,
            onClick: () => p.onItemClick(v.id),
          }),
        },
      }}
      data$={p.data$}
      onItemClick={p.onItemClick}
      onOrderChange={p.onOrderChange}
      onFilterChange={p.onFilterChange}
      orderBy$={p.orderBy$}
      filters$={p.filters$}
      onPageChange={p.onPageChange}
      loading$={p.loading$}
      onTypeClick={p.onTypeClick}
      onCreateNew={p.onCreateNew}
      onCreateNewType={p.onCreateNewType}
      bulkSelect={{
        ...p.bulkSelect,
        actions: [{ id: "remove", label: t("Remove") }],
      }}
      remove={{
        ...p.remove,
        getTitle: (c) =>
          c === 1 ? t("Remove repository") : t("Remove repositories"),
        getDescription: (c) =>
          c === 1
            ? t("Do you really want to remove this repository")
            : t("Do you really want to remove this repositories"),
      }}
    />
  );
}
