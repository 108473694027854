import React from "react";
import { createSvgIcon } from "@mui/material";

export const ArrowLeft = createSvgIcon(
  <path
    d="M19 12H5M5 12L12 19M5 12L12 5"
    stroke="currentColor"
    strokeOpacity="0.1"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  "ArrowLeft",
);
