import { Selector, useSelector } from "state-manager";
import { NumberInput } from "ui/components/Input";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface InputProps {
  placeholder?: string;
  value$: Selector<number | undefined>;
  onChange: (value: number | undefined) => void;
  min?: number;
  max?: number;
  validation?: "success" | "error";
  label?: TranslatedStr;
}

export function Number({
  value$,
  onChange,
  placeholder,
  min,
  max,
  validation,
  label,
}: InputProps) {
  const value = useSelector(value$);

  return (
    <NumberInput
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      min={min}
      max={max}
      error={validation === "error"}
    />
  );
}
