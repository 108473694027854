import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { DataTypes } from "../states/DataTypes";
import { Customers } from "../states/Customers";
import { Suppliers } from "../states/Suppliers";
import { Repositories } from "../states/Repositories";
import { InventoryItems } from "../states/InventoryItems";
import { ItemMovements } from "../states/ItemMovements";
import { RepositoryMovements } from "../states/RepositoryMovements";
import { PickingOrders } from "../states/PickingOrder";
import { pickingOrderState } from "../utils";

// region Actions
export type Actions =
  | LoadFail
  | LoadSuccess
  | DataTypes.Actions
  | Suppliers.Actions
  | Customers.Actions
  | Repositories.Actions
  | InventoryItems.Actions
  | ItemMovements.Actions
  | RepositoryMovements.Actions
  | PickingOrders.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (isLoadFail(a)) return true;
  if (isLoadSuccess(a)) return true;
  if (DataTypes.instance.isAction(a)) return true;
  if (Customers.instance.isAction(a)) return true;
  if (Suppliers.instance.isAction(a)) return true;
  if (Repositories.instance.isAction(a)) return true;
  if (InventoryItems.instance.isAction(a)) return true;
  if (ItemMovements.instance.isAction(a)) return true;
  if (RepositoryMovements.instance.isAction(a)) return true;
  if (pickingOrderState.isAction(a)) return true;

  silentUnreachableError(a);
  return false;
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:LoadFail";
  payload: {
    type: "unknown";
  };
}

export const loadFail = (payload: LoadFail["payload"]): LoadFail => ({
  type: "Ready:DataManager:LoadFail",
  payload,
});

export const isLoadFail = (action: Actions): action is LoadFail =>
  action.type === "Ready:DataManager:LoadFail";
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:LoadSuccess";
  payload: {
    dataTypes: DataType[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:LoadSuccess",
  payload,
});

export const isLoadSuccess = (action: Actions): action is LoadSuccess =>
  action.type === "Ready:DataManager:LoadSuccess";
// endregion
