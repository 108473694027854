import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

export namespace BulkSelect {
  export interface Props<Id> {
    id: Id;
    selected$: BehaviorValue<Id[]>;
    disabled$: BehaviorValue<Id[]>;
    onSelect: () => void;
  }
}

export function BulkSelect<Id>(p: BulkSelect.Props<Id>) {
  const selected = useBehaviorValue(p.selected$.map((vs) => vs.includes(p.id)));
  const disabled = useBehaviorValue(p.disabled$.map((vs) => vs.includes(p.id)));

  return (
    <TableCell padding={"checkbox"}>
      <Checkbox checked={selected} disabled={disabled} onClick={p.onSelect} />
    </TableCell>
  );
}
