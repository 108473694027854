import MuiTable from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import {
  forwardRef,
  JSXElementConstructor,
  MutableRefObject,
  ReactNode,
} from "react";
import { TableSortLabel } from "@mui/material";

export namespace Table {
  export interface Props {
    stickyHeader?: boolean;
    children?: ReactNode;
  }
}
export const Table = forwardRef<HTMLTableElement, Table.Props>((p, ref) => (
  <MuiTable {...p} ref={ref} size={"small"}>
    {p.children}
  </MuiTable>
));

export namespace Row {
  export interface Props {
    children?: ReactNode;
  }
}
export const Row: JSXElementConstructor<Row.Props> = TableRow;

export namespace SortableCell {
  export interface Props {
    children?: ReactNode;
    sort?: "asc" | "desc";
    onClick?: () => void;
  }
}
export const SortableCell: JSXElementConstructor<SortableCell.Props> = (p) => {
  return (
    <TableCell>
      <TableSortLabel active={!!p.sort} direction={p.sort} onClick={p.onClick}>
        {p.children}
      </TableSortLabel>
    </TableCell>
  );
};

export namespace Body {
  export interface Props {
    children?: ReactNode;
  }
}
export const Body: JSXElementConstructor<Body.Props> = TableBody;

export namespace Head {
  export interface Props {
    children?: ReactNode;
  }
}
export const Head: JSXElementConstructor<Head.Props> = TableHead;

export namespace HeaderCell {
  export interface Props {
    className?: string;
    children?: ReactNode;
    ref?: MutableRefObject<HTMLTableCellElement>;
    size?: "small" | "medium";
  }
}
export const HeaderCell: JSXElementConstructor<HeaderCell.Props> = TableCell;

export namespace HeaderRow {
  export interface Props {
    children?: ReactNode;
  }
}
export const HeaderRow: JSXElementConstructor<HeaderRow.Props> = TableRow;

export namespace Cell {
  export interface Props {
    tabIndex?: number;
    className?: string;
    children?: ReactNode;
    $ellipsis?: boolean;
    align?: "left" | "center" | "right" | "justify";
    size?: "small" | "medium";
  }
}
export const Cell = forwardRef<HTMLTableCellElement, Cell.Props>((p, ref) => {
  return (
    <TableCell
      size={p.size}
      align={p.align}
      tabIndex={p.tabIndex}
      className={p.className}
      ref={ref}
      sx={
        p.$ellipsis
          ? {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
          : undefined
      }
    >
      {p.children}
    </TableCell>
  );
});
