import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { ThemeProvider } from "@components/ThemeProvider";

import Drawer from "@mui/material/Drawer";
import { Box, Divider, IconButton, useTheme } from "@mui/material";
import Close from "@mui/icons-material/Close";

import Grid from "@mui/material/Grid2";
import { alpha } from "@mui/material/styles";

export interface GlobalSheetProps {
  isOpen: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
}

export function GlobalSheet({
  children,
  isOpen,
  header,
  footer,
  onClose,
}: GlobalSheetProps) {
  const theme = useTheme();
  return createPortal(
    <ThemeProvider mode={theme.palette.mode}>
      <Drawer
        slots={{ backdrop: "div" }}
        open={isOpen}
        anchor={"right"}
        sx={(t) => ({
          ".MuiPaper-root": {
            borderRadius: t.spacing(3),
            width: "450px",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: alpha(t.palette.common.black, 0.1),
          },
        })}
      >
        {header && (
          <Box sx={{ px: 8, pt: 8, pb: 5 }}>
            <Grid container>
              <Grid size={"grow"}>{header}</Grid>
              <Grid size={"auto"}>
                <IconButton onClick={onClose} size={"small"}>
                  <Close sx={{ fontSize: "24px" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        )}
        {header && <Divider />}
        <Box sx={{ p: 8, flex: 1, minHeight: 0, overflowY: "auto" }}>
          {children}
        </Box>
        {footer && <Box sx={{ p: 8 }}>{footer}</Box>}
      </Drawer>
    </ThemeProvider>,
    document.body,
  );
}
