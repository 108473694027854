import { ListingTable } from "ui/layouts/ListingTable";
import { useTranslation } from "i18n";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as Rx from "rxjs";
import { Button } from "ui/components/Button";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";

const filtersConfigPredefined = {
  search: ListingTable.predefinedFilters.search,
  // todo: add `dateRange`
} satisfies ListingTable.FiltersConfigBase["predefined"];

export namespace EntityListing {
  export interface Item<Id extends string> {
    id: Id;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
  }

  export interface Props<
    I extends Item<string>,
    Actions extends string,
    CustomFilters extends ListingTable.FiltersConfigBase["custom"] = {},
  > {
    title: TranslatedStr;
    newItemTitle: TranslatedStr;
    newTypeTitle: TranslatedStr;

    data$: BehaviorValue<ListingTable.ListData<I>>;
    loading$: BehaviorValue<boolean>;

    filtersConfig?: CustomFilters;
    filters$ /*criteria*/ : BehaviorValue<
      {
        search: O.Option<NoEmptyString>;
        created: DateRange;
      } & Required<
        Required<
          ListingTable.Criteria<
            ListingTable.ColumnsConfigBase<I>,
            { predefined: typeof filtersConfigPredefined } & {
              custom: CustomFilters;
            }
          >
        >["filters"]
      >["custom"]
    >;
    orderBy$: BehaviorValue<O.Option<OrderBy<"createdAt" | "updatedAt">>>;
    columns?: ListingTable.ColumnsConfigBase<I>;

    onCreateNew: (() => void) | undefined;
    onCreateNewType: () => void;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: I["id"]) => void;
    onTypeClick: (id: DataTypeId) => void;
    onFilterChange: (
      v: Partial<{
        search: O.Option<NoEmptyString>;
        created: DateRange;
      }>,
    ) => void;
    onOrderChange: (p: "createdAt" | "updatedAt") => void;

    bulkSelect: {
      actions: { id: Actions; label: TranslatedStr }[];
      selected$: BehaviorValue<I["id"][]>;
      unselectable$: BehaviorValue<I["id"][]>;
      onSelect: (id: I["id"][]) => void;
      onUnselect: (id: I["id"][]) => void;
      onAction: (a: Actions) => void;
    };
    remove: DeleteProps;
  }
}

export function EntityListing<
  I extends EntityListing.Item<string>,
  Actions extends string,
>(p: EntityListing.Props<I, Actions>) {
  const { t } = useTranslation();

  const filtersConfig = useMemo<ListingTable.FiltersConfigBase>(
    () => ({
      predefined: filtersConfigPredefined,
      custom: p.filtersConfig ?? {},
    }),
    [p.filtersConfig],
  );

  return (
    <>
      <ListingTable
        title={p.title}
        data$={p.data$}
        columns={{
          id: {
            type: ListingTable.CellType.id,
            label: t("Id"),
            renderProps: (v) => ({
              id: v.id,
              onClick: () => p.onItemClick(v.id),
            }),
          },
          dataType: {
            type: ListingTable.CellType.text,
            label: t("Data type"),
            renderProps: (v) => ({
              text: O.toUndefined(v.dataType)?.name,
              onClick: () =>
                Fp.pipe(
                  v.dataType,
                  O.map((v) => v.id),
                  O.map(p.onTypeClick),
                ),
            }),
          },
          ...(p.columns ?? {}),
          createdAt: {
            type: ListingTable.CellType.timeDate,
            label: t("Created at"),
            renderProps: (v) => ({ date: ISODate.toDate(v.createdAt) }),
            canReorder: true,
          },
          updatedAt: {
            type: ListingTable.CellType.timeDate,
            label: t("Last update"),
            canReorder: true,
            renderProps: Fp.flow(
              (v) => v.updatedAt,
              O.map(ISODate.toDate),
              O.toUndefined,
              (date) => ({ date }),
            ),
          },
        }}
        filters={filtersConfig}
        criteria$={BehaviorValue.combine([p.filters$, p.orderBy$]).map(
          ([filters, orderBy]) => {
            return {
              filters: {
                predefined: {
                  search: {
                    text: O.toUndefined(filters.search),
                  },
                  dateRange: {
                    start: Fp.pipe(
                      filters.created[0],
                      O.fromNullable,
                      O.map(ISODate.toDate),
                      O.toUndefined,
                    ),
                    end: Fp.pipe(
                      filters.created[1],
                      O.fromNullable,
                      O.map(ISODate.toDate),
                      O.toUndefined,
                    ),
                  },
                },
                custom: {},
              },
              orderBy: O.toUndefined(orderBy),
            };
          },
        )}
        onCriteriaChange={(c) => {
          Fp.pipe(
            c.filters?.predefined?.search,
            O.fromNullable,
            O.map(
              Fp.flow(
                O.chain((v) => O.fromNullable(v?.text)),
                O.chain(NoEmptyString.fromString),
              ),
            ),
            O.map((v) => ({ search: v })),
            O.map(p.onFilterChange),
          );

          Fp.pipe(
            c.orderBy,
            O.fromNullable,
            O.map((v) => v.by),
            O.filter((v): v is "createdAt" | "updatedAt" =>
              ["createdAt", "updatedAt"].includes(v),
            ),
            O.map(p.onOrderChange),
          );
        }}
        onPageChange={p.onPageChange}
        loading$={p.loading$}
        columnsVisibility$={new BehaviorValue({}, Rx.NEVER)}
        bulkSelect={p.bulkSelect}
        headerButtons={
          <>
            <Button color="primary" onClick={p.onCreateNewType}>
              {p.newTypeTitle}
            </Button>
            {p.onCreateNew ? (
              <Button variant={"outlined"} onClick={p.onCreateNew}>
                {p.newItemTitle}
              </Button>
            ) : null}
          </>
        }
      />
      <Delete {...p.remove} />
    </>
  );
}

interface DeleteProps {
  getTitle: (count: number) => TranslatedStr;
  getDescription: (count: number) => TranslatedStr;
  confirmation$: BehaviorValue<false | number>;
  onConfirm: () => void;
  onDecline: () => void;
}

function Delete(p: DeleteProps) {
  const confirm = useBehaviorValue(p.confirmation$);

  return confirm !== false ? (
    <DeleteModal
      title={p.getTitle(confirm)}
      onConfirm={p.onConfirm}
      onCancel={p.onDecline}
    >
      {p.getDescription(confirm)}
    </DeleteModal>
  ) : null;
}
