import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import {
  RepositoryFragmentFragment,
  RepositoryType,
} from "../generated/graphql";

export function repositoryFragmentToRepository(
  fragment: RepositoryFragmentFragment,
): Repository {
  return {
    id: fragment.id as Repository["id"],
    fields: (fragment.data ?? {}) as Repository["fields"],
    createdAt: fragment.createdAt ?? undefined,
    updatedAt: fragment.updatedAt ?? undefined,
    dataTypeId: fragment.dataTypeID as Repository["dataTypeId"],
    name: fragment.name as Repository["name"],
    type: fragment.type,
    isVirtual: !!fragment.virtualRepo,
    parentId: getParentId(fragment.parentID),
  };
}

export function repositoryTypeToApiResponseType(
  type: Repository["type"],
): RepositoryType {
  switch (type) {
    case "static":
      return RepositoryType.Static;
    case "dynamic":
      return RepositoryType.Dynamic;
  }
}

function getParentId(v: GQLUuid | null | undefined): RepositoryId | undefined {
  return v && v !== "00000000-0000-0000-0000-000000000000"
    ? (v as RepositoryId)
    : undefined;
}
