import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";
import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "../../../types/columns";
import { DataEntryBase } from "../../../types/data";
import { styleSecondaryTextColor } from "../../../utils/styles";

export namespace TextPrimarySecondary {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<
      DataEntry,
      ColumnConfig,
      Type.textPrimarySecondary
    >["renderProps"]
  >;
}

export const TextPrimarySecondary = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  primary,
  secondary,
}: TextPrimarySecondary.Props<DataEntry, ColumnConfig>) => (
  <>
    <Typography variant={"body2"}>{primary}</Typography>
    <Typography variant={"body2"} sx={sxSecondary}>
      {secondary}
    </Typography>
  </>
);

const sxSecondary: SxProps<Theme> = (theme) => ({
  color: styleSecondaryTextColor(theme),
});
