import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";
import { ReactElement, ReactNode } from "react";

export namespace Tooltip {
  export interface Props {
    content: ReactNode;
    children: ReactElement;
    placement?: TooltipProps["placement"];
  }
}

export function Tooltip(p: Tooltip.Props) {
  return (
    <MuiTooltip placement={p.placement} title={p.content}>
      {p.children}
    </MuiTooltip>
  );
}
