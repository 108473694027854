import React, { useMemo } from "react";
import { Box } from "@mui/material";
import {
  DateCalendar,
  DateCalendarProps,
  PickersDay,
  LocalizationProvider,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // todo: review
import { useTranslation } from "i18n";
import { isSameDay, addDays, startOfDay, endOfDay } from "date-fns";
import { langToDateFnsLocale } from "../constants/date";
import { Sxs } from "../../../types/styles";
import { useOnEndChange, useOnStartChange } from "./hooks/onChange";

export namespace DateRangePicker {
  export type Value = Partial<{ start: Date; end: Date }>;
  export type Props = {
    min?: Date;
    max?: Date;
    value?: Value;
    onChange: (value?: Value) => void;
  };
}

// fixme: maybe move to `src/components/`
export const DateRangePicker: React.FC<DateRangePicker.Props> = ({
  min,
  max,
  value,
  onChange,
}) => {
  const { language } = useTranslation();

  const normalized = useMemo(
    () => ({
      min: min ? startOfDay(min) : min,
      max: max ? endOfDay(max) : max,
    }),
    [min, max],
  );
  const onStartChange = useOnStartChange({ ...normalized, value, onChange });
  const onEndChange = useOnEndChange({ ...normalized, value, onChange });

  const slots = useMemo<DateCalendarProps<Date>["slots"]>(
    () => ({
      day: (props: PickersDayProps<Date>) => {
        const { day, selected } = props,
          inRange =
            value?.start &&
            value.start <= day &&
            (!value?.end || day <= value.end),
          isStart = inRange && value?.start && isSameDay(value.start, day),
          isEnd = inRange && value?.end && isSameDay(value.end, day),
          isBeforeEnd =
            inRange &&
            !isEnd &&
            value?.end &&
            isSameDay(value.end, addDays(day, 1));

        return (
          <PickersDay
            {...props}
            className={[
              inRange ? cn.inRange : 0,
              isStart ? cn.isStart : 0,
              isEnd ? cn.isEnd : 0,
              isBeforeEnd ? cn.isBeforeEnd : 0,
            ]
              .filter(Boolean)
              .join(" ")}
          />
        );
      },
    }),
    [value?.end, value?.start],
  );

  return (
    <Box sx={sxs.wrapper}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={langToDateFnsLocale[language]}
      >
        <DateCalendar
          value={value?.start}
          minDate={min}
          maxDate={max}
          onChange={onStartChange}
          slots={slots}
          sx={sxs.calendar}
        />
        <DateCalendar
          value={value?.end}
          onChange={onEndChange}
          minDate={value?.start || min}
          maxDate={max}
          slots={slots}
          sx={sxs.calendar}
        />
      </LocalizationProvider>
    </Box>
  );
};

const cn = {
  inRange: "in-range",
  isStart: "is-start",
  isEnd: "is-end",
  isBeforeEnd: "is-before-end",

  day: "MuiPickersDay-root",
} as const;

const sxFakeContinuation: Sxs[string] = (theme) => ({
  content: "' '",
  display: "block",
  position: "absolute",
  width: "50%",
  height: "100%",
  top: "0",
  backgroundColor: theme.palette.surface.primary,
});

const sxs = {
  wrapper: () => ({
    display: "flex",
    gap: 2,
    alignItems: "center",
  }),
  calendar: (theme) => ({
    width: "auto",
    height: "auto",
    minHeight: "unset",

    ".MuiPickersCalendarHeader-root": {
      marginTop: 0,
      paddingTop: 5,
      paddingLeft: 6,
    },
    ".MuiDayCalendar-root": {
      padding: theme.spacing(0, 6, 5),
    },
    ".MuiDayCalendar-weekContainer": {
      margin: theme.spacing(1, 0),
    },
    ".MuiDayCalendar-weekDayLabel": {
      ...theme.typography.caption,
    },
    [`.${cn.day}, .MuiDayCalendar-weekDayLabel, .MuiPickersDay-dayWithMargin`]:
      {
        margin: 0,
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
    [`.${cn.day}`]: {
      ...theme.typography.body2,
      fontWeight: 600,
    },
    [`.${cn.day}.Mui-selected`]: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      zIndex: 1,
    },
    [`.${cn.day}.Mui-selected:hover, .${cn.day}.Mui-selected:focus`]: {
      backgroundColor: theme.palette.primary.main,
    },

    [`.${cn.day}.${cn.inRange}:not(.Mui-selected), .${cn.day}:hover`]: {
      backgroundColor: theme.palette.surface.primary,
    },

    [`.${cn.day}.${cn.inRange}.${cn.isStart}:not(.Mui-selected)`]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      color: theme.palette.primary.dark,
    },
    [`.${cn.day}.${cn.inRange}.${cn.isEnd}:not(.Mui-selected)`]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      color: theme.palette.primary.dark,
    },
    [`.${cn.day}.${cn.inRange}:not(.Mui-selected,.${cn.isStart},.${cn.isEnd})`]:
      {
        borderRadius: 0,
      },
    [`.${cn.day}.${cn.inRange}:not(.Mui-selected):first-of-type`]: {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    },
    [`.${cn.day}.${cn.inRange}:not(.Mui-selected):last-of-type`]: {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },

    [`.${cn.day}.${cn.inRange}.${cn.isBeforeEnd}:not(:last-of-type,.Mui-selected):before`]:
      {
        ...sxFakeContinuation(theme),
        left: "100%",
      },
    [`.${cn.day}.Mui-selected + .${cn.day}.${cn.inRange}:before`]: {
      ...sxFakeContinuation(theme),
      left: "-50%",
    },

    [`.${cn.day}.MuiPickersDay-today`]: {
      border: 0,
    },
    [`.${cn.day}.MuiPickersDay-today:not(.Mui-selected)`]: {
      backgroundColor: theme.palette.surface.primary,
    },
    [`.${cn.day}.MuiPickersDay-today:after`]: {
      content: "' '",
      display: "block",
      position: "absolute",
      width: theme.spacing(1.25),
      height: theme.spacing(1.25),
      bottom: theme.spacing(1),
      left: `calc(50% - ${parseInt(theme.spacing(1.25), 10) / 2}px)`,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
  }),
} satisfies Sxs;
