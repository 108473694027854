import { useTranslation } from "i18n";
import { Selector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";
import { Header as Layout } from "ui/layouts/Single/Header";

const state = DataManager.pickingOrderState.subStates.edit;

type State = GetContructorType<typeof state.states>;
type Actions = GetContructorType<typeof state.actions>;

export interface HeaderProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Layout>{t("Edit order")}</Layout>;
}
