import React from "react";
import { escapeRegExp } from "utils/regexp";
import { Box } from "@mui/material";
import { Sxs } from "../../../types/styles";

export const HighlightText: React.FC<{ highlight: string; text: string }> = ({
  highlight: highlightRaw,
  text,
}) => {
  const highlight = sanitize(highlightRaw);
  if (!highlight.length) {
    return text;
  }

  return (
    <>
      {text.split(makeRegex(highlight)).map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <Box key={index} component="span" sx={sxs.highlight}>
            {part}
          </Box>
        ) : (
          part
        ),
      )}
    </>
  );
};

const sxs = {
  highlight: (theme) => ({
    color: theme.palette.primary.dark,
  }),
} satisfies Sxs;

const sanitize = (text: string): string => text.trim();

const makeRegex = (text: string) =>
  new RegExp(`(?<=^|\\s)(${escapeRegExp(text)})`, "ig");
