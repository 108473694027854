import { Supplier } from "types/src/Supplier/Supplier";
import { SupplierFragmentFragment } from "../generated/graphql";

export function supplierFragmentToSupplier(
  supplierFragment: SupplierFragmentFragment,
): Supplier {
  return {
    id: supplierFragment.id as Supplier["id"],
    fields: (supplierFragment.data ?? {}) as Supplier["fields"],
    createdAt: supplierFragment.createdAt,
    updatedAt: supplierFragment.updatedAt ?? undefined,
    deletedAt: supplierFragment.updatedAt ?? undefined,
    dataTypeId: supplierFragment.dataTypeID as Supplier["dataTypeId"],
  };
}
