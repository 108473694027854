import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import { isShallowEqual } from "utils/object";
import {
  OrganizationId,
  State,
  changeOrganizationId,
} from "state-manager/states/Ready";
import { SecondarySidebar } from "ui/components/SecondarySidebar";
import { useTranslation } from "i18n";

export interface SidebarProps {
  selector: Selector<State["payload"]>;
  dispatch: (action: OrganizationId) => void;
}

export function Sidebar(p: SidebarProps): ReactElement {
  const { t } = useTranslation();
  const organizations = useSelector(
    flow(p.selector, (s) => s.organizations),
    isShallowEqual,
  );
  const orgId = useSelector(
    flow(p.selector, (s) => s.orgId),
    isShallowEqual,
  );

  return (
    <SecondarySidebar title={t("Organizations")}>
      {organizations.ids.map((id) => {
        const entry = organizations.entities[id];
        if (!entry) return null;

        return (
          <SecondarySidebar.Item
            key={id}
            onClick={() => p.dispatch(changeOrganizationId(id))}
            isCurrent={orgId === id}
            title={`${entry.link}(${entry.type})`}
          />
        );
      })}
    </SecondarySidebar>
  );
}
