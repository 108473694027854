import React, { useEffect, useRef, useState } from "react";
import { from, Subscription, tap } from "rxjs";
import i18nInit, { I18nInitProps } from "../init";

export type LoadingProps = { error?: boolean };

export type InitFromFilesSuspenseProps = React.PropsWithChildren<
  {
    Loading: React.FC<LoadingProps>;
  } & Pick<I18nInitProps, "lang">
>;

type LoadingState = {
  inProgress: boolean;
  props: LoadingProps;
};

const InitSuspense: React.FC<InitFromFilesSuspenseProps> = ({
  Loading,
  children,
  lang,
}) => {
  const [loading, setLoading] = useState<LoadingState>({
    inProgress: true,
    props: {},
  });

  const initRef = useRef<Subscription>();
  if (!initRef.current) {
    initRef.current = from(i18nInit({ lang }))
      .pipe(
        tap({
          next: () => setLoading((state) => ({ ...state, inProgress: false })),
          error: () =>
            setLoading((state) => ({ ...state, props: { error: true } })),
        }),
      )
      .subscribe();
  }
  useEffect(() => () => initRef.current && initRef.current.unsubscribe(), []);

  return loading.inProgress
    ? React.createElement(Loading, loading.props)
    : children;
};

export default InitSuspense;
