import { Theme } from "@mui/material/styles";
import { Box, SxProps } from "@mui/material";

export const Icon: React.FC = () => (
  <Box sx={sx}>
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="currentColor" fillOpacity="0.87" />
    </svg>
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1H12"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeDasharray="2 2"
      />
    </svg>
  </Box>
);

const sx: SxProps<Theme> = {
  display: "inline-flex",
  alignItems: "center",
  opacity: 0.3,
};
