import React from "react";
import Icon from "@mui/icons-material/RefreshRounded";
import { useTranslation } from "i18n";
import { keyframes } from "@mui/material/styles";
import { Sxs } from "../../../../types/styles";
import { Layout } from "./common/Layout";

export const StateLoading = () => {
  const { t } = useTranslation();

  return (
    <Layout
      // todo: set icon as in Figma (with colors)
      icon={icon}
      title={t("Loading")}
      description={t("Results are loading, please wait.")}
    />
  );
};

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const sxs = {
  icon: (theme) => ({
    color: theme.palette.action.active,
    animation: `${rotate} 2s linear infinite`,
  }),
} satisfies Sxs;

const icon = <Icon sx={sxs.icon} />;
