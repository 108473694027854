import { ReactElement } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ButtonGroup, Button, FormLabel } from "@mui/material";
import Stack from "@mui/material/Stack";

export interface PickerProps<T> {
  label?: string;
  value: T | undefined;
  onChange: (value: T | undefined) => void;
  options: { value: T; label: TranslatedStr }[];
  eq?: (a: T | undefined, b: T | undefined) => boolean;
}

export function Picker<T>({
  label,
  value,
  onChange,
  options,
  eq = (a, b) => a === b,
}: PickerProps<T>): ReactElement {
  return (
    <Stack useFlexGap gap={3} direction={"row"} alignItems={"center"}>
      <FormLabel>{label}</FormLabel>
      <ButtonGroup variant={"outlined"}>
        {options.map(({ value: v, label }, i) => (
          <Button
            variant={eq(v, value) ? "contained" : undefined}
            key={i}
            onClick={() => (v === value ? onChange(undefined) : onChange(v))}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </Stack>
  );
}
