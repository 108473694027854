import { RootState, useSelector } from "state-manager";
import { Textarea as TextareaComponent } from "ui/components/Textarea";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface TextareaProps {
  value$: (s: RootState) => string;
  onChange: (value: string) => void;
  rows?: number;
  placeholder?: string;
  label?: TranslatedStr;
}

export function Textarea({
  value$,
  onChange,
  rows,
  placeholder,
  label,
}: TextareaProps) {
  const value = useSelector(value$);

  return (
    <TextareaComponent
      placeholder={placeholder}
      rows={rows}
      value={value || ""}
      onChange={onChange}
      label={label}
    />
  );
}
