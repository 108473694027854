import T, { TypographyProps } from "@mui/material/Typography";

import { forwardRef } from "react";

interface BaseTypographyProps
  extends Pick<TypographyProps, "component" | "children" | "variant"> {}

export namespace Ellipsis {
  export interface Props extends BaseTypographyProps {}
}

export const Ellipsis = forwardRef<HTMLElement, Ellipsis.Props>((p, ref) => {
  return (
    <T
      {...p}
      variant={"body1"}
      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      ref={ref}
    />
  );
});

export namespace Typography {
  export interface Props extends BaseTypographyProps {}
}

export const Typography = forwardRef<HTMLSpanElement, Typography.Props>(
  (p, ref) => {
    return <T {...p} ref={ref} />;
  },
);

export namespace DisabledMd {
  export interface Props extends BaseTypographyProps {}
}

export function DisabledMd(p: DisabledMd.Props) {
  return (
    <T
      variant={"body1"}
      sx={(t) => ({ fontStyle: "italic", color: t.palette.grey[600] })}
      {...p}
    />
  );
}
