import { useCallback, useEffect, useRef, useState } from "react";
import { Suggestions } from "..";
import { openCloseTimeoutDelay } from "../constants";

type Props<ID extends PropertyKey> = Pick<
  Suggestions.Props<ID>,
  "onPick" | "text"
> &
  Partial<Pick<Suggestions.Props<ID>, "data$">> & {
    onAfterClose?: () => void;
  };

export const useSuggestionsController = <ID extends PropertyKey = string>({
  onPick,
  data$,
  text,
  onAfterClose,
}: Props<ID>) => {
  const [isOpen, setIsOpen] = useState<undefined | boolean>(
    undefined /*== don't render*/,
  );

  const anchorEl = useRef<Element>();

  // fixes glitch when input is re-focused (close->open->close)
  const openCloseTimeout = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => () => clearTimeout(openCloseTimeout.current), []);

  const open = useCallback((p: { anchorEl: Element }) => {
    anchorEl.current = p.anchorEl;

    clearTimeout(openCloseTimeout.current);
    openCloseTimeout.current = setTimeout(
      () => setIsOpen(true),
      openCloseTimeoutDelay,
    );
  }, []);

  const afterClose = useRef<() => void>();
  const close = useCallback(() => {
    clearTimeout(openCloseTimeout.current);
    openCloseTimeout.current = setTimeout(() => {
      if (!anchorEl.current?.querySelector("input:focus")) {
        setIsOpen(false);

        afterClose.current?.();
        afterClose.current = undefined;

        onAfterClose?.();
      }
    }, openCloseTimeoutDelay);
  }, [onAfterClose]);

  const onPickAdapter = useCallback<typeof onPick>(
    (item) => {
      afterClose.current = () => onPick(item);
      close();
    },
    [close, onPick],
  );

  return {
    open,
    isOpen,
    props: data$
      ? ({
          anchorEl: anchorEl.current,
          open: isOpen || false,
          data$,
          text,
          onPick: onPickAdapter,
          onClose: close,
        } satisfies Pick<
          Suggestions.Props<ID>,
          "anchorEl" | "open" | "data$" | "text" | "onPick" | "onClose"
        >)
      : undefined,
  };
};
