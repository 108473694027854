import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import { DataTypeUiSchema } from "types/src/DataType/DataTypeUiSchema";

export namespace DataTypeSchemaBuilder {
  export interface Props {
    value$: BehaviorValue<{
      schema: DataTypeSchema;
      ui: DataTypeUiSchema;
    }>;
    onChange: (v: DataTypeSchema) => void;
    onUiChange: (v: DataTypeUiSchema) => void;
  }
}

export function DataTypeSchemaBuilder(p: DataTypeSchemaBuilder.Props) {
  //TODO: Implement
  return null;
}
