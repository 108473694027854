import { ReactElement, ReactNode } from "react";

import { useTranslation } from "i18n";
import { Button } from "@components/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export interface ExecuteModalProps {
  title: ReactNode;
  children?: ReactNode;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  isLoading?: boolean;
}

export function ExecuteModal(p: ExecuteModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={p.onCancel}>
      <DialogTitle>{p.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{p.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} onClick={p.onCancel}>
          {t("Cancel")}
        </Button>
        <Button variant={"contained"} onClick={p.onConfirm}>
          {t("Execute")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
