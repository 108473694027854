import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as PickingOrderItem from "../../PickingOrderItem/types/Actions";

export type Actions<P extends string, Id extends string> =
  | AddNew<P>
  | Remove<P, Id>
  | Submit<P>
  | PickingOrderItem.Actions<`${P}:item:${Id}`>;

export const isActions = <P extends string, Id extends string>(p: P) => {
  const _isAdd = isAddNew<P, Id>(p);
  const _isRemove = isRemove<P, Id>(p);
  const _isSubmit = isSubmit<P, Id>(p);
  const _isPickingOrderItemAction = isPickingOrderItemAction(p);

  return strictGuard((a: Actions<P, Id>): a is Actions<P, Id> => {
    if (
      _isAdd(a) ||
      _isRemove(a) ||
      _isSubmit(a) ||
      _isPickingOrderItemAction(a)
    )
      return true;

    silentUnreachableError(a);
    return false;
  });
};

// region AddNew
export interface AddNew<P extends string> {
  type: `${P}:AddNew`;
}

export const addNew =
  <P extends string>(p: P) =>
  (): AddNew<P> => ({
    type: `${p}:AddNew`,
  });

export const isAddNew =
  <P extends string, Id extends string>(p: P) =>
  (s: Actions<P, Id>): s is AddNew<P> =>
    s.type === `${p}:AddNew`;
// endregion

// region Remove
export interface Remove<P extends string, Id extends string> {
  type: `${P}:Remove`;
  payload: Id;
}

export const remove =
  <P extends string, Id extends string>(p: P) =>
  (payload: Remove<P, Id>["payload"]): Remove<P, Id> => ({
    type: `${p}:Remove`,
    payload,
  });

export const isRemove =
  <P extends string, Id extends string>(p: P) =>
  (s: Actions<P, Id>): s is Remove<P, Id> =>
    s.type === `${p}:Remove`;
// endregion

// region Submit
export interface Submit<P extends string> {
  type: `${P}:Submit`;
}

export const submit =
  <P extends string>(p: P) =>
  (): Submit<P> => ({
    type: `${p}:Submit`,
  });

export const isSubmit =
  <P extends string, Id extends string>(p: P) =>
  (s: Actions<P, Id>): s is Submit<P> =>
    s.type === `${p}:Submit`;
// endregion

export const isPickingOrderItemAction =
  <P extends string, Id extends string>(p: P) =>
  (a: Actions<P, Id>): a is PickingOrderItem.Actions<`${P}:item:${Id}`> => {
    return a.type.startsWith(`${p}:item:`);
  };

export const getItemIdFromPickingOrderAction =
  <P extends string, Id extends string>(p: P) =>
  (a: PickingOrderItem.Actions<`${P}:item:${Id}`>): Id =>
    a.type.split(`${p}:item:`)[1]?.split(":")?.[0] as Id;
