import { none, Option, some } from "fp-ts/Option";
import { Eq } from "fp-ts/Eq";

// region NoEmptyString
declare const _noEmptyString: unique symbol;

export type NoEmptyString = string & { [_noEmptyString]: "NoEmptyString" };
// endregion

export namespace NoEmptyString {
  export function isNoEmptyString(value: string): value is NoEmptyString {
    return value.length > 0;
  }

  export function fromString(value: string): Option<NoEmptyString> {
    if (!isNoEmptyString(value)) {
      return none;
    }

    return some(value);
  }

  export const equals: Eq<NoEmptyString>["equals"] = (a, b) => a === b;
}

/**
 * @deprecated, use NoEmptyString.isNoEmptyString
 */
export const isNoEmptyString = NoEmptyString.isNoEmptyString;
/**
 * @deprecated, use NoEmptyString.fromString
 */
export const fromString = NoEmptyString.fromString;
/**
 * @deprecated, use NoEmptyString
 */
export const NoEmptyStringEq: Eq<NoEmptyString> = NoEmptyString;
