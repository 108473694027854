import React, { useCallback, useRef, useState } from "react";

export const usePopoverController = () => {
  const [open, setOpen] = useState<undefined | boolean>(
    undefined /*== don't render*/,
  );
  const anchorEl = useRef<Element>();

  const openPopover = useCallback<React.MouseEventHandler<Element>>((event) => {
    anchorEl.current = event.currentTarget;
    setOpen(true);
  }, []);
  const closePopover = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    openPopover,
    popoverOpen: open,
    popoverProps: {
      open: open || false,
      onClose: closePopover,
      anchorEl: anchorEl.current,
    },
  };
};
