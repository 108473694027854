import { StockId } from "types/src/Stocks/Stock";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { ISODate } from "types/src/date/ISODate";

export type State = Loading | Ready | LoadError;

export const isState = strictGuard((s: State): s is State => {
  if (isLoading(s)) return true;
  if (isReady(s)) return true;
  if (isLoadError(s)) return true;

  silentUnreachableError(s);
  return false;
});

// region Loading
export interface LoadingPayload {
  id: StockId;
}

export interface Loading {
  type: "Stocks:Single:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Stocks:Single:Loading",
  payload,
});

export const isLoading = (s: State): s is Loading =>
  s.type === "Stocks:Single:Loading";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  inventoryItemId: InventoryItemId;
  repositoryId: RepositoryId;
  repositoryName: string;
  createdAt: ISODate;
  quantity: number;
  incoming: number;
  outgoing: number;
}

export interface Ready {
  type: "Stocks:Single:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Stocks:Single:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Stocks:Single:Ready";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Stocks:Single:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Stocks:Single:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Stocks:Single:LoadError";
// endregion
