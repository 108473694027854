import { Customer } from "types/src/Customers/Customer";
import { CustomerFragmentFragment } from "../generated/graphql";

export function customerFragmentToCustomer(
  customerFragment: CustomerFragmentFragment,
): Customer {
  return {
    id: customerFragment.id as Customer["id"],
    fields: (customerFragment.data ?? {}) as Customer["fields"],
    createdAt: customerFragment.createdAt ?? undefined,
    updatedAt: customerFragment.updatedAt ?? undefined,
    deletedAt: customerFragment.updatedAt ?? undefined,
    dataTypeId: customerFragment.dataTypeID as Customer["dataTypeId"],
  };
}
