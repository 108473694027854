import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { ColumnsConfigBase } from "@layouts/ListingTable/types/columns";
import * as Arr from "fp-ts/Array";
import { FiltersConfigBase } from "@layouts/ListingTable/types/filters";
import { BulkSelectProps } from "../../types/BulkSelectProps";
import { BulkSelect } from "./Header/BulkSelect";
import { Body } from "./Body";
import { Table } from ".";

export const isActionsColumnVisible = ({
  entryActions,
  onColumnsVisibilityChange,
}: {
  entryActions?: unknown;
  onColumnsVisibilityChange?: unknown;
}) => !!entryActions || !!onColumnsVisibilityChange;

export const createHeaderBulkSelectProp = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Actions extends string,
>({
  bulkSelect,
  data$,
}: Pick<
  Table.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>,
  "data$"
> & {
  bulkSelect: BulkSelectProps<DataEntry["id"], Actions> | undefined;
}): BulkSelect.Props<Actions> | undefined => {
  const diff = Arr.difference<DataEntry["id"]>({ equals: (a, b) => a === b });

  return bulkSelect
    ? {
        value$: BehaviorValue.combine([
          bulkSelect.selected$,
          bulkSelect.unselectable$,
          data$,
        ]).map(([selected, disabled, data]) => {
          return {
            total: diff(
              data.entries.map((i) => i.id),
              disabled,
            ).length,
            selected: diff(selected, disabled).length,
          };
        }),
        onSelect: () => {
          if (!bulkSelect) return;

          const entries = data$.getValue().entries;
          const allIds = entries.map((entry) => entry.id);
          const selectedIds = bulkSelect.selected$.getValue();
          const disabled = bulkSelect.unselectable$.getValue();
          const ids = diff(allIds, disabled);
          const total = ids.length;
          const selected = selectedIds.length;

          if (total === 0) return;

          // If no items selected, select all
          if (selected === 0) return bulkSelect.onSelect(ids);

          // if All items are selected, deselect all
          if (selected === total) bulkSelect.onUnselect(ids);

          // If items are partially selected, select all unselected
          const unselected = diff(ids, selectedIds);

          if (!unselected.length) return;
          return bulkSelect.onSelect(unselected);
        },
        actions: bulkSelect.actions,
        onAction: bulkSelect.onAction,
      }
    : undefined;
};

export const createBodyBulkSelectProp = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  Actions extends string,
>({
  bulkSelect,
}: {
  bulkSelect: BulkSelectProps<DataEntry["id"], Actions> | undefined;
}): Body.Props<DataEntry, ColumnsConfig>["bulkSelect"] => {
  return bulkSelect
    ? {
        selected$: bulkSelect.selected$,
        unselectable$: bulkSelect.unselectable$,
        onSelect: (id) => {
          if (!bulkSelect) return;
          if (bulkSelect.unselectable$.getValue().includes(id)) return;

          const isSelected = bulkSelect.selected$.getValue().includes(id);

          if (isSelected) bulkSelect.onUnselect([id]);
          else bulkSelect.onSelect([id]);
        },
      }
    : undefined;
};
