import React, { ReactNode } from "react";
import { Theme } from "@mui/material/styles";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Box, SxProps } from "@mui/material";
import { FilterAdapterPredefined } from "./FilterAdapter/Predefined";
import { Criteria, CriteriaChange } from "./types/criteria";
import { DataEntryBase } from "./types/data";
import { ColumnsConfigBase } from "./types/columns";
import { FiltersConfigBase, PredefinedFiltersMap } from "./types/filters";
import { DateRange } from "./filters/predefined/DateRange";
import { Text } from "./filters/predefined/Text";

export namespace HeaderFilters {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    filters: FiltersConfig;
    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: CriteriaChange<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
    customButtons?: ReactNode;
  };
}

export const HeaderFilters = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  filters,
  criteria$,
  onCriteriaChange,
  customButtons,
}: HeaderFilters.Props<DataEntry, ColumnsConfig, FiltersConfig>) => (
  <Box sx={sxWrapper}>
    <FilterAdapterPredefined<
      DataEntry,
      ColumnsConfig,
      FiltersConfig,
      PredefinedFiltersMap["search"]
    >
      criteria$={criteria$}
      onCriteriaChange={onCriteriaChange}
      filters={filters}
      filterId="search"
      Filter={Text}
    />
    <Box sx={sxRight}>
      {customButtons}
      <FilterAdapterPredefined<
        DataEntry,
        ColumnsConfig,
        FiltersConfig,
        PredefinedFiltersMap["dateRange"]
      >
        criteria$={criteria$}
        onCriteriaChange={onCriteriaChange}
        filters={filters}
        filterId="dateRange"
        Filter={DateRange}
      />
    </Box>
  </Box>
);

const sxWrapper: SxProps<Theme> = () => ({
  marginBottom: 6,
  display: "flex",
  justifyContent: "space-between",
  gap: 4,
});

const sxRight: SxProps<Theme> = () => ({
  display: "flex",
  gap: 4,
});
