import React from "react";
import { DataEntryBase } from "@layouts/ListingTable/types/data";

export enum CellType {
  id = "id",
  text = "text",
  number = "number",
  badge = "badge",
  timeDate = "timeDate",
  textPrimarySecondary = "textPrimarySecondary",
  movedFrom = "movedFrom",
  movedTo = "movedTo",
  stock = "stock",
}

type ConfigType<
  Type extends CellType,
  DataEntry extends DataEntryBase,
  RendererProps extends Record<string, unknown>,
> = {
  type: Type;
  renderProps: (entry: DataEntry) => RendererProps;
};

export type ColumnConfigBase<DataEntry extends DataEntryBase> = {
  label: React.ReactNode;
  canReorder?: boolean;
} & (
  | ConfigType<
      CellType.id,
      DataEntry,
      {
        id: React.Key;
        onClick?: () => void;
      }
    >
  | ConfigType<
      CellType.text,
      DataEntry,
      {
        text: React.ReactNode;
        onClick?: () => void;
      }
    >
  | ConfigType<
      CellType.number,
      DataEntry,
      {
        number: number;
        format?: /*todo*/ unknown;
      }
    >
  | ConfigType<
      CellType.badge,
      DataEntry,
      {
        color: "blue" | "green";
        text?: React.ReactNode;
      }
    >
  | ConfigType<
      CellType.timeDate,
      DataEntry,
      {
        date: Date | undefined;
      }
    >
  | ConfigType<
      CellType.textPrimarySecondary,
      DataEntry,
      {
        primary: string;
        secondary: string;
      }
    >
  | ConfigType<
      CellType.movedFrom,
      DataEntry,
      {
        text: string | undefined;
      }
    >
  | ConfigType<
      CellType.movedTo,
      DataEntry,
      {
        text: string | undefined;
      }
    >
  | ConfigType<
      CellType.stock,
      DataEntry,
      {
        current: number;
        max: number;
      }
    >
);

export type GetColumnSubType<
  DataEntry extends DataEntryBase,
  Column extends ColumnConfigBase<DataEntry>,
  Type extends CellType,
> = Column extends { type: Type } ? Column : never;

export type ColumnsConfigBase<DataEntry extends DataEntryBase> = Record<
  string,
  ColumnConfigBase<DataEntry>
>;

export type ColumnsVisibility<
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
> = Partial<Record<keyof ColumnsConfig, boolean>>;
