import { IconButton } from "@mui/material";
import * as Fp from "fp-ts/function";
import OutlinedInput from "@mui/material/OutlinedInput";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

export namespace DatePickerInput {
  export interface Props {
    label?: string;
    value: Date | undefined;
    onChange?: (value: Date | undefined) => void;
    min?: Date;
    max?: Date;
  }
}

export function DatePickerInput(p: DatePickerInput.Props) {
  return (
    <OutlinedInput
      size={"small"}
      label={p.label}
      type={"date"}
      value={p.value?.toISOString()?.slice(0, 10) || ""}
      inputProps={{
        min: p.min?.toISOString().slice(0, 10),
        max: p.max?.toISOString().slice(0, 10),
      }}
      onChange={Fp.flow(
        (v) => new Date(v.target.value),
        (v) => (isNaN(v.getTime()) ? undefined : v),
        (v) => p.onChange?.(v),
      )}
      endAdornment={
        <IconButton onClick={() => p.onChange?.(undefined)} disabled={!p.value}>
          <CancelRoundedIcon />
        </IconButton>
      }
    />
  );
}
