import { useBehaviorValue } from "react-rx/behaviorValue";
import React, { useCallback, useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as O from "fp-ts/Option";
import { Criteria, CriteriaChange } from "../types/criteria";
import { DataEntryBase } from "../types/data";
import { ColumnsConfigBase } from "../types/columns";
import {
  FilterConfig,
  FilterGroup,
  FiltersConfigBase,
  PredefinedFiltersMap,
  FilterPropsPredefined,
} from "../types/filters";

export namespace FilterAdapterPredefined {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Config extends FilterConfig,
  > = {
    filters: FiltersConfig;
    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: CriteriaChange<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
    filterId: keyof PredefinedFiltersMap;
    Filter: React.FC<FilterPropsPredefined<Config>>;
  };
}

export const FilterAdapterPredefined = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Config extends FilterConfig,
>(
  props: FilterAdapterPredefined.Props<
    DataEntry,
    ColumnsConfig,
    FiltersConfig,
    Config
  >,
) => {
  const { filters, filterId } = props;

  // @ts-expect-error fixme
  const config: Config | undefined = filters[filterGroup][filterId];

  if (!config) return null;

  return (
    <FilterRender<DataEntry, ColumnsConfig, FiltersConfig, Config>
      {...{ ...props, config }}
    />
  );
};

export const FilterRender = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Config extends FilterConfig,
>({
  config,
  criteria$,
  onCriteriaChange,
  filterId,
  Filter,
}: FilterAdapterPredefined.Props<
  DataEntry,
  ColumnsConfig,
  FiltersConfig,
  Config
> & {
  config: Config;
}) => {
  type Value = Config["value"];

  const criteriaValue = useBehaviorValue(
    useMemo(
      () => criteria$.map(({ filters }) => filters?.[filterGroup]?.[filterId]),
      [criteria$, filterId],
    ),
  );

  const value = useMemo<Value>(
    () => ({ ...config.value, ...criteriaValue }),
    [config.value, criteriaValue],
  );

  const onChange = useCallback(
    (value: Value) => {
      onCriteriaChange({
        filters: { [filterGroup]: { [filterId]: O.some(value) } },
      });
    },
    [filterId, onCriteriaChange],
  );

  return <Filter {...{ config, value, onChange }} />;
};

const filterGroup = FilterGroup.predefined;
