import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { InventoryItemFragmentFragment } from "../generated/graphql";

export function inventoryItemFragmentToInventoryItem(
  fragment: InventoryItemFragmentFragment,
): InventoryItem {
  return {
    id: fragment.id as InventoryItem["id"],
    fields: (fragment.inventoryJSONData ?? {}) as InventoryItem["fields"],
    createdAt: fragment.createdAt,
    updatedAt: fragment.updatedAt ?? undefined,
    dataTypeId: fragment.dataTypeID as InventoryItem["dataTypeId"],
    sku: fragment.sku as InventoryItem["sku"],
  };
}
