/* eslint-disable @typescript-eslint/no-explicit-any */

import { DataTypeId } from "types/src/DataType/DataType";
import { DsError } from "ds";
import * as Rx from "rxjs";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { strictGuard } from "utils/strictGuard";
import { Loading } from "../Loading";
import { ListingState } from "../Listing";
import { Epic } from "../../types/RootEpic";

export namespace ListingWithDataTypes {
  export interface DataType {
    id: DataTypeId;
    name: string;
  }

  export interface EpicDeps<
    P extends string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    F extends Record<string, any>,
    T extends { id: string },
    O,
    A extends {},
  > extends ListingState.EpicDeps<P, F, T, O, A> {
    fetchDataTypes: () => Rx.Observable<E.Either<DsError, DataType[]>>;
  }

  export const createState = <
    P extends string,
    F extends Record<string, unknown>,
    T extends { id: string },
    O,
    A extends {},
  >(
    p: P,
    config: {
      defaultFilters: F;
    },
  ) => {
    type Extend<T extends {}> = T & {
      dataTypes: Loading.GetState<typeof dataTypesState>;
    };

    const dataTypesState = Loading.createState<
      `${P}:data-types`,
      Array<DataType>,
      undefined
    >(`${p}:data-types`);
    const state = ListingState.createState<P, F, T, O, Extend<A>>(p, config);

    type St = ListingState.GetState<typeof state>;
    type Ac =
      | ListingState.GetActions<typeof state>
      | Loading.GetActions<typeof dataTypesState>;
    type Exits = ListingState.GetExits<typeof state>;

    const epic: Epic<Ac, St, EpicDeps<P, F, T, O, Extend<A>>> = (
      state$,
      deps,
    ) => {
      const listing$ = state.epic(state$, deps);

      const dataTypes$ = dataTypesState.epic(
        state$.pipe(Rx.map((v) => v.payload.dataTypes)),
        {
          get: deps.fetchDataTypes,
        },
      );

      return Rx.merge(listing$, dataTypes$);
    };

    const reducer = (s: St, a: Ac): E.Either<Exits, St> => {
      if (dataTypesState.isAction(a)) {
        return Fp.pipe(
          dataTypesState.reducer(s.payload.dataTypes, a),
          E.map(
            (dataTypes) =>
              ({ ...s, payload: { ...s.payload, dataTypes } }) as typeof s,
          ),
        );
      }

      return state.reducer(s, a);
    };

    return {
      ...state,
      isAction: strictGuard(
        (a: Ac): a is Ac => state.isAction(a) || dataTypesState.isAction(a),
      ),
      reducer,
      epic,
      init: (a: A): St =>
        state.init({
          ...a,
          dataTypes: dataTypesState.init(undefined),
        }),
      dataTypes: dataTypesState,
    };
  };

  export type GetState<T extends { reducer: (s: any, a: any) => any }> =
    ListingState.GetState<T>;

  export type GetActions<T extends { reducer: (s: any, a: any) => any }> =
    ListingState.GetActions<T>;

  export type GetExits<
    T extends { reducer: (s: any, a: any) => E.Either<any, any> },
  > = ListingState.GetExits<T>;
}
