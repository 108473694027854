import React from "react";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Icon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "i18n";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { Tooltip } from "./Tooltip";

export namespace RemoveButton {
  export type Props = {
    onClick: () => void;
  };
}
export const RemoveButton: React.FC<RemoveButton.Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("Remove filter")}>
      <Icon sx={sxs.icon} onClick={onClick} />
    </Tooltip>
  );
};

const sxs = {
  icon: (theme) => ({
    ...sxsCommon.icon(theme),

    cursor: "pointer",
  }),
} satisfies Record<string, SxProps<Theme>>;
