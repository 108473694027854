import { ReactElement, ReactNode } from "react";
import { styled } from "@mui/material/styles";

export interface InlineProps {
  children?: ReactNode;
  gap?: "s" | "m" | "l";
}

export function Inline(p: InlineProps): ReactElement {
  return <Container $gap={p.gap}>{p.children}</Container>;
}

const Container = styled("div")<{ $gap?: "s" | "m" | "l" }>`
  display: flex;
  gap: ${(p) =>
    p?.$gap
      ? {
          s: 8,
          m: 16,
          l: 24,
        }[p.$gap]
      : 0}px;
`;
