import { RootState, useSelector } from "state-manager";
import { Autocomplete as ComboboxComponent } from "ui/components/Autocomplete";
import { TranslatedStr } from "types/src/TranslatedStr";
import { flow } from "fp-ts/function";

export interface ComboboxProps<T extends string> {
  value$: (s: RootState) => T | undefined;
  onChange: (value: T) => void;
  options: { value: T; label: TranslatedStr }[];
  validation?: "success" | "warning" | "error";
  label?: string;
}

export function Combobox<T extends string>({
  label,
  value$,
  onChange,
  options,
}: ComboboxProps<T>) {
  const activeIndex = useSelector(
    flow(value$, (v) => options.findIndex((i) => i.value === v)),
  );

  return (
    <ComboboxComponent
      label={label}
      activeIndex={activeIndex}
      onChange={(v) => v && onChange(v.value)}
      error={activeIndex === -1 ? "error" : undefined}
      options={options}
      getLabel={(v) => v.label}
    />
  );
}
