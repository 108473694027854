import { Typed } from "utils/Typed";
import { DataTypeSchema as DataTypeSchemaType } from "types/src/DataType/DataTypeSchema";
import { DataTypeUiSchema } from "types/src/DataType/DataTypeUiSchema";
import * as E from "fp-ts/Either";

export namespace DataTypeSchema {
  export function createState<P extends string>(p: P) {
    const states = Typed.builder
      .add(
        "idle",
        (p: { schema: DataTypeSchemaType; uiSchema: DataTypeUiSchema }) => p,
      )
      .finish()(p);
    const actions = Typed.builder
      .add("resetSchema", (p: DataTypeSchemaType) => p)
      .add("resetUi", (p: DataTypeUiSchema) => p)
      .finish()(p);

    type State = Typed.GetTypes<typeof states>;
    type Actions = Typed.GetTypes<typeof actions>;

    return {
      states,
      actions,
      isState: Typed.getGuard(states),
      isAction: Typed.getGuard(actions),
      init: states.idle.create,
      reducer: (s: State, a: Actions): E.Either<never, State> => {
        if (actions.resetSchema.is(a)) {
          return E.right(
            states.idle.create({
              schema: a.payload,
              uiSchema: s.payload.uiSchema,
            }),
          );
        }
        if (actions.resetUi.is(a)) {
          return E.right(
            states.idle.create({
              schema: s.payload.schema,
              uiSchema: a.payload,
            }),
          );
        }

        return E.right(s);
      },
    };
  }
}
