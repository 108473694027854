import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "i18n";
import { styled } from "@mui/material/styles";
import { delay, exhaustMap, from, merge, mergeMap, of, Subject } from "rxjs";
import { Tooltip } from "@components/Tooltip";

export interface CopyTooltipProps {
  text: string;
  children: ReactElement;
}
export function CopyTooltip(p: CopyTooltipProps): ReactElement {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState<boolean>(false);

  const handleCopy = useMemo(() => new Subject<string>(), []);

  useEffect(() => {
    const sub = handleCopy
      .pipe(
        exhaustMap((text) => {
          return from(navigator.clipboard.writeText(text)).pipe(
            mergeMap(() => merge(of(true), of(false).pipe(delay(1000)))),
          );
        }),
      )
      .subscribe(setHidden);
    return () => sub.unsubscribe();
  }, [handleCopy]);

  return (
    <Tooltip
      content={<Copy onClick={() => handleCopy.next(p.text)}>{t("Copy")}</Copy>}
      placement={"bottom-start"}
    >
      {p.children}
    </Tooltip>
  );
}

const Copy = styled("span")`
  cursor: pointer;
`;
