import { silentUnreachableError } from "utils/exceptions";
import { OrgId, Organization, User, UserAccessToken } from "types";
import { Either } from "fp-ts/Either";
import { strictGuard } from "utils/strictGuard";
import * as DataManager from "../states/DataManager";
import * as BuilderPreview from "../states/BuilderPreview";
import * as BPMNPreview from "../states/BPMNPreview";
import * as ZitadelPreview from "../states/ZitadelPreview";
import * as SandboxPreview from "../states/SandboxPreview";
import * as Stocks from "../states/Stocks";

// region State
export interface StatePayload {
  user: Either<
    {
      avatar: string | undefined;
      username: string;
      email: string;
    },
    {
      avatar: string | undefined;
      username: string;
      email: string;
      accessToken: UserAccessToken;
    }
  >;
  organizations: {
    ids: OrgId[];
    isActive: boolean;
    entities: Record<OrgId, Organization>;
  };
  orgId: OrgId;
  openAI: User["openAI"];
  subState:
    | SigningOut
    | DataManager.State
    | BuilderPreview.State
    | BPMNPreview.State
    | ZitadelPreview.State
    | SandboxPreview.State
    | Stocks.State;
}

export interface State {
  type: "Ready:State";
  payload: StatePayload;
}

export const state = (payload: State["payload"]): State => ({
  type: "Ready:State",
  payload,
});

export const isState = strictGuard((state: State): state is State => {
  const type = state.type as State["type"];
  switch (type) {
    case "Ready:State":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region SignOut
export interface SigningOutPayload {}

export interface SigningOut {
  type: "Ready:SigningOut";
  payload: SigningOutPayload;
}

export const signingOut = (payload: SigningOut["payload"]): SigningOut => ({
  type: "Ready:SigningOut",
  payload,
});

export const isSigningOut = (
  s: State["payload"]["subState"],
): s is SigningOut => s.type === "Ready:SigningOut";

// endregion

export const init = ({
  user,
  organizations,
  orgId,
  openAI,
}: {
  user: StatePayload["user"];
  organizations: StatePayload["organizations"];
  orgId: StatePayload["orgId"];
  openAI: StatePayload["openAI"];
}): State =>
  state({
    user,
    organizations,
    orgId,
    openAI,
    subState: DataManager.init(),
  });
