/* eslint-disable @typescript-eslint/no-explicit-any */

import { strictGuard } from "utils/strictGuard";
import { Typed } from "utils/Typed";
import * as E from "fp-ts/Either";
import * as Filters from "../Filters";
import * as ActionsModule from "./types/Actions";
import * as StatesModule from "./types/State";
import * as ExitsModule from "./types/Exits";
import { createReducer } from "./reducer";
import { createEpic, Deps } from "./epic";

export namespace ListingState {
  export type State<
    P extends string,
    F extends Record<string, unknown>,
    T extends { id: string },
    O,
    A extends {},
  > = Typed.GetTypes<
    ReturnType<typeof StatesModule.createState<P, F, T, O, A>>
  >;

  export type Actions<
    P extends string,
    F extends Record<string, unknown>,
    T extends { id: string },
    O,
  > = ActionsModule.Actions<P, F, T, O>;

  export interface EpicDeps<
    P extends string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    F extends Record<string, any>,
    T extends { id: string },
    O,
    A extends {},
  > extends Deps<P, F, T, O, A> {}

  export function createState<
    P extends string,
    F extends Record<string, unknown>,
    T extends { id: string },
    OrderBy,
    A extends {},
  >(
    p: P,
    config: {
      defaultFilters: F;
    },
  ) {
    const states = StatesModule.createState<P, F, T, OrderBy, A>(p);
    const actions = ActionsModule.createActions<P, T, OrderBy>(p);
    const exits = ExitsModule.createExits(p);
    const filters = Filters.createState(`${p}:filters`, config.defaultFilters);
    const reducer = createReducer<P, F, T, OrderBy, A>({
      states,
      actions,
      filters,
      exits,
    });
    const epic = createEpic<P, F, T, OrderBy, A>({
      states,
      actions,
      filters,
    });

    return {
      isExit: Typed.getGuard(exits),
      isState: Typed.getGuard(states),
      isAction: strictGuard(
        (
          a: ActionsModule.Actions<P, F, T, OrderBy>,
        ): a is ActionsModule.Actions<P, F, T, OrderBy> => a.type.startsWith(p),
      ),
      states,
      actions,
      exits,
      reducer,
      epic,
      init: (a: A) =>
        states.loading.create({
          ...a,
          filters: filters.init(),
          advancedFiltersState: "closed",
          perPage: 20,
          order: undefined,
        }),
      subStates: { filters },
    };
  }

  export type GetState<T extends { reducer: (s: any, a: any) => any }> =
    T extends { reducer: (s: infer S, a: any) => any } ? S : never;

  export type GetActions<T extends { reducer: (s: any, a: any) => any }> =
    T extends { reducer: (s: any, a: infer A) => any } ? A : never;

  export type GetExits<
    T extends { reducer: (s: any, a: any) => E.Either<any, any> },
  > = T extends { reducer: (s: any, a: any) => E.Either<infer T, any> }
    ? T
    : never;
}
