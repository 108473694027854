import { Typography } from "ui/components/Typography";
import { ReactNode } from "react";

export namespace Header {
  export interface Props {
    children: ReactNode;
  }
}

export function Header(p: Header.Props) {
  return <Typography variant={"h6"}>{p.children}</Typography>;
}
