import { silentUnreachableError } from "utils/exceptions";
import * as Obj from "utils/object";
import * as E from "fp-ts/Either";
import { createStates, createActions, State, Actions } from "./index";

export const createReducer =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <P extends string, Fields extends { [k in string]: any }>({
    actions,
    states,
    defaultValue,
  }: {
    states: ReturnType<typeof createStates<P, Fields>>;
    actions: ReturnType<typeof createActions<P, Fields>>;
    defaultValue: Fields;
  }) => {
    return (
      s: State<P, Fields>,
      a: Actions<P, Fields>,
    ): E.Either<never, State<P, Fields>> => {
      if (actions.setValue.is(a)) {
        const validated = Obj.filter((_, k) => k in defaultValue, a.payload);
        const fields = { ...s.payload.fields, ...validated };

        if (!Obj.isDeepEqual(fields, s.payload.fields)) {
          return E.right(
            Obj.isDeepEqual(fields as Fields, defaultValue)
              ? states.idle.create(fields)
              : states.edited.create(fields),
          );
        }

        return E.right(s);
      }

      if (actions.submit.is(a)) {
        if (states.edited.is(s)) {
          return E.right(
            Obj.isDeepEqual(s.payload.fields, defaultValue)
              ? states.idle.create(s.payload.fields)
              : states.submitted.create(s.payload.fields),
          );
        }

        return E.right(s);
      }
      if (actions.reset.is(a)) {
        if (!states.idle.is(s)) {
          return E.right(states.idle.create(defaultValue));
        }

        return E.right(s);
      }

      silentUnreachableError(a);
      return E.right(s);
    };
  };
