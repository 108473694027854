import { Typed } from "utils/Typed";

import { debounceTime, distinctUntilKeyChanged, filter, map } from "rxjs";
import { Epic } from "../../types/RootEpic";
import { createReducer } from "./reducer";

export const createStates = <
  P extends string,
  Fields extends { [k in string]: unknown },
>(
  p: P,
) => {
  return Typed.builder
    .add("idle", (fields: Fields) => ({ fields }))
    .add("edited", (fields: Fields) => ({ fields }))
    .add("submitted", (fields: Fields) => ({ fields }))
    .finish()(p);
};

export const createActions = <
  P extends string,
  Fields extends { [k in string]: unknown },
>(
  p: P,
) => {
  return Typed.builder
    .add("setValue", (p: Partial<Fields>) => p)
    .add("submit")
    .add("reset")
    .finish()(p);
};

export function createState<
  P extends string,
  Fields extends { [k in string]: unknown },
>(p: P, defaultValue: Fields) {
  const states = createStates<P, Fields>(p);
  const actions = createActions<P, Fields>(p);
  const epic: Epic<Actions<P, Fields>, State<P, Fields>> = (state$) => {
    return state$.pipe(
      distinctUntilKeyChanged("type"),
      filter(states.edited.is),
      debounceTime(500),
      map(actions.submit.create),
    );
  };

  return {
    states,
    actions,
    isState: Typed.getGuard(states),
    isAction: Typed.getGuard(actions),
    reducer: createReducer({ states, actions, defaultValue }),
    epic,
    init: () => states.idle.create(defaultValue),
  };
}

export type State<
  P extends string,
  Fields extends { [k in string]: unknown },
> = Typed.GetTypes<ReturnType<typeof createStates<P, Fields>>>;

export type Actions<
  P extends string,
  Fields extends { [k in string]: unknown },
> = Typed.GetTypes<ReturnType<typeof createActions<P, Fields>>>;
