import React, { useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import IconDesc from "@mui/icons-material/ArrowDownwardRounded";
import IconAsc from "@mui/icons-material/ArrowUpwardRounded";
import { getColumnsOrder } from "@layouts/ListingTable/utils/columns";
import * as Rx from "rxjs";
import { styleSecondaryTextColor } from "../../../utils/styles";
import { ColumnsConfigBase, ColumnsVisibility } from "../../../types/columns";
import { DataEntryBase, EntryAction } from "../../../types/data";
import { FiltersConfigBase } from "../../../types/filters";
import {
  Criteria,
  CriteriaChange,
  OrderDirection,
} from "../../../types/criteria";
import { isActionsColumnVisible } from "../utils";
import { ColumnsVisibilityPicker } from "./ColumnsVisibilityPicker";
import { BulkSelect } from "./BulkSelect";

export namespace Header {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Actions extends string,
  > = {
    columns: ColumnsConfig;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: CriteriaChange<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
    bulkSelect: BulkSelect.Props<Actions> | undefined;
    entryActions?: (entry: DataEntry) => EntryAction<DataEntry>[];
  };
}

export const Header = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Actions extends string,
>({
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  criteria$,
  onCriteriaChange,
  bulkSelect,
  entryActions,
}: Header.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>) => {
  type ColumnsClickHandlers = Record<
    keyof ColumnsConfig,
    React.MouseEventHandler<HTMLAnchorElement>
  >;

  const columnsVisibility = useBehaviorValue(columnsVisibility$);
  const columnsOrder = useMemo(
    () => getColumnsOrder<DataEntry, ColumnsConfig>(columns, columnsVisibility),
    [columns, columnsVisibility],
  );

  const orderBy = useBehaviorValue(
    useMemo(() => criteria$.map(({ orderBy }) => orderBy), [criteria$]),
  );
  const columnsOrderClickHandlers = useMemo(
    () =>
      columnsOrder.reduce(
        (carry, id) => ({
          ...carry,
          [id]: (event: MouseEvent) => {
            event.preventDefault();

            onCriteriaChange({
              orderBy: {
                by: id,
                direction: getNextDirection(
                  orderBy?.by === id ? orderBy?.direction : undefined,
                ),
              },
            });
          },
        }),
        {} as ColumnsClickHandlers,
      ),
    [columnsOrder, onCriteriaChange, orderBy?.by, orderBy?.direction],
  );
  const hasSelected$ = useMemo(
    () =>
      bulkSelect?.value$.map((v) => !!v.selected) ??
      new BehaviorValue(false, Rx.EMPTY),
    [bulkSelect?.value$],
  );
  const hasSelected = useBehaviorValue(hasSelected$);

  const actionsVisible = isActionsColumnVisible({
    entryActions,
    onColumnsVisibilityChange,
  });

  return (
    <TableHead sx={sxHead}>
      <TableRow>
        {bulkSelect && (
          <BulkSelect
            value$={bulkSelect.value$}
            onSelect={bulkSelect.onSelect}
            actions={bulkSelect.actions}
            onAction={bulkSelect.onAction}
          />
        )}
        {columnsOrder.map((id, i) => {
          const label = columns[id]?.label,
            canReorder = columns[id]?.canReorder,
            direction = orderBy?.by === id ? orderBy.direction : undefined;

          return (
            <TableCell
              key={id.toString()}
              sx={sxCell}
              sortDirection={direction}
            >
              <Box
                sx={{
                  visibility: i === 0 && hasSelected ? "hidden" : undefined,
                }}
              >
                {canReorder && columnsOrderClickHandlers[id] ? (
                  <a href="#" onClick={columnsOrderClickHandlers[id]}>
                    {label}
                    {direction && directionIcons[direction]}
                  </a>
                ) : (
                  label
                )}
              </Box>
            </TableCell>
          );
        })}
        {actionsVisible && (
          <TableCell key="_actions" sx={sxCell} width={1}>
            {onColumnsVisibilityChange && (
              <ColumnsVisibilityPicker<DataEntry, ColumnsConfig>
                {...{
                  columns,
                  columnsVisibility,
                  onColumnsVisibilityChange,
                }}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

const sxHead: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.surface.primary,
});

const sxCell: SxProps<Theme> = (theme) => ({
  ...theme.typography.caption,
  padding: theme.spacing(3, 6),
  height: theme.spacing(5),
  color: styleSecondaryTextColor(theme),
  a: {
    color: styleSecondaryTextColor(theme),
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
});

const directionIconSx: SxProps<Theme> = (theme) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
});
const directionIcons: Record<OrderDirection, React.ReactNode> = {
  asc: <IconAsc sx={directionIconSx} />,
  desc: <IconDesc sx={directionIconSx} />,
};

const getNextDirection = (
  direction: OrderDirection | undefined,
): OrderDirection | undefined => {
  const sequence: Record<OrderDirection | "", OrderDirection | undefined> = {
    "": "asc",
    asc: "desc",
    desc: undefined,
  };
  return sequence[direction || ""];
};
