import { useTranslation } from "i18n";
import { RootState, useSelector } from "state-manager";
import { DataTypeCreate as Create } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Create";
import { Header as Layout } from "ui/layouts/Single/Header";
import { flow } from "fp-ts/function";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DataTypeEntity } from "types/src/DataType/DataType";

export interface HeaderProps {
  selector: (s: RootState) => Create.State;
  dispatch: (a: Create.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();
  const entity = useSelector(flow(p.selector, (s) => s.payload.entity));

  return <Layout>{getTitle()}</Layout>;

  function getTitle(): TranslatedStr {
    switch (entity) {
      case DataTypeEntity.Attachment:
        return t("Add new attachment type");
      case DataTypeEntity.CollectionMovement:
        return t("Add new collection type");
      case DataTypeEntity.Customer:
        return t("Add new customer type");
      case DataTypeEntity.Inbound:
        return t("Add new inbound type");
      case DataTypeEntity.InboundItem:
        return t("Add new inbound item type");
      case DataTypeEntity.Item:
        return t("Add new item type");
      case DataTypeEntity.ItemSet:
        return t("Add new item set type");
      case DataTypeEntity.Movement:
        return t("Add new movement type");
      case DataTypeEntity.Order:
        return t("Add new order type");
      case DataTypeEntity.OrderItem:
        return t("Add new order item type");
      case DataTypeEntity.Other:
        return t("Add new type");
      case DataTypeEntity.Repository:
        return t("Add new repository type");
      case DataTypeEntity.Supplier:
        return t("Add new supplier type");
    }
  }
}
