import { useTranslation } from "i18n";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Edit";
import { Selector } from "state-manager";
import { Header as Layout } from "ui/layouts/Single/Header";

export interface HeaderProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Layout>{t("Edit supplier")}</Layout>;
}
