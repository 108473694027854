/* eslint-disable @typescript-eslint/no-explicit-any */

import { PageInfo } from "types";
import { Typed } from "utils/Typed";
import * as Filters from "../../Filters";

type BasePayload<
  P extends string,
  F extends Record<string, unknown>,
  OrderBy,
> = {
  filters: Filters.State<`${P}:filters`, F>;
  advancedFiltersState: "open" | "closed";
  perPage: number;
  order:
    | {
        by: OrderBy;
        direction: "asc" | "desc";
      }
    | undefined;
};

type ReadyPayload<
  P extends string,
  F extends Record<string, unknown>,
  T extends { id: string },
  OrderBy,
> = BasePayload<P, F, OrderBy> & {
  items: T[];
  pageInfo: PageInfo;
  total: number;
  selected: Array<T["id"]>;
  removing: { [k in T["id"]]?: "removing" | "confirm" | undefined };
};

type FetchingPayload<
  P extends string,
  F extends Record<string, unknown>,
  T extends { id: string },
  OrderBy,
> = ReadyPayload<P, F, T, OrderBy> & {
  page: "current" | "start" | "prev" | "next" | "end";
};

export const createState = <
  P extends string,
  F extends Record<string, unknown>,
  T extends { id: string },
  O,
  A extends {},
>(
  p: P,
) => {
  return Typed.builder
    .add("loading", (p: A & BasePayload<P, F, O>) => p)
    .add("loadError", (p: A & BasePayload<P, F, O>) => p)
    .add("ready", (p: A & ReadyPayload<P, F, T, O>) => p)
    .add("fetching", (p: A & FetchingPayload<P, F, T, O>) => p)
    .finish()(p);
};

export type State<
  P extends string,
  F extends Record<string, unknown>,
  T extends { id: string },
  O,
  A extends {},
> = Typed.GetTypes<ReturnType<typeof createState<P, F, T, O, A>>>;
