import { styleButtonBorderColor } from "@layouts/ListingTable/utils/styles";
import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

export const sxsCommon = {
  filterAsButton: (theme: Theme): SystemStyleObject<Theme> => ({
    ...theme.typography.body2,

    display: "flex",
    gap: 2,
    alignItems: "center",
    justifyContent: "space-between",

    borderRadius: 2,
    border: `1px solid ${styleButtonBorderColor(theme)}`,
    height: theme.spacing(10),
    boxSizing: "border-box",

    "&:hover, &.Mui-disabled": {
      backgroundColor: theme.palette.surface.primary,
      borderColor: theme.palette.text.primary,
    },
  }),
  input: (theme: Theme): SystemStyleObject<Theme> => ({
    ...theme.typography.body2,

    lineHeight: theme.spacing(5),

    width: "100%", // fixes focus width glitch
    height: theme.spacing(10),

    boxShadow: 0,
    borderRadius: 2,
    border: 0,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
      border: `1px solid ${styleButtonBorderColor(theme)}`,
    },

    "& .MuiInputBase-input": {
      padding: 0,
      height: theme.spacing(5),
    },
  }),
  inputWithRemove: (theme: Theme): SystemStyleObject<Theme> => ({
    padding: theme.spacing(2.5, 9, 2.5, 3),

    "&.Mui-focused": {
      paddingRight: 3,
    },
  }),
  inputRemoveButton: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "absolute",
    top: 2,
    right: 2,
    bottom: 2,
    paddingRight: 3,
    paddingLeft: 2,
    borderRadius: 2,
    background: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  }),
  icon: (theme: Theme): SystemStyleObject<Theme> => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.action.active,
  }),
};
