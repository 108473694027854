import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Edit";
import { flow, pipe } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { FormWrapper } from "ui/layouts/FormWrapper";
import * as O from "fp-ts/Option";
import * as FormValue from "types/src/FormValue";
import { HandlerInput } from "@Containers/Form/predefined/HandlerInput";
import { Toggle } from "@Containers/Form/Toggle";
import { useTranslation } from "i18n";
import { SearchCombobox, SelectorProps } from "@Containers/Form/SearchCombobox";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Repository } from "types/src/Repositories/Repository";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";

export interface ContentProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:RepositoryMovements:Edit:Loading":
        case "Ready:DataManager:RepositoryMovements:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:RepositoryMovements:Edit:Ready":
        case "Ready:DataManager:RepositoryMovements:Edit:Saving": {
          const currentSelector = flow(selector, (st) => st as typeof s);

          return {
            type: "ready",
            schemaSelector: flow(
              currentSelector,
              (s) => s.payload.schema.payload,
            ),
            itemSelector: flow(
              currentSelector,
              (s) => s.payload.item,
              (v): SelectorProps<Repository> => {
                return {
                  options: v.value.items.map((i) => ({
                    value: i,
                    label: i.name as string as TranslatedStr,
                  })),
                  value: pipe(
                    v,
                    O.fromPredicate(FormValue.isValid),
                    O.map((v) => v.value.selected),
                  ),
                  isLoading: FormValue.isVerifying(v),
                  validation: FormValue.isInvalid(v) ? "error" : undefined,
                };
              },
            ),
            fromSelector: flow(
              currentSelector,
              (s) => s.payload.from,
              (v): SelectorProps<Repository> => {
                return {
                  options: v.value.items.map((i) => ({
                    value: i,
                    label: i.name as string as TranslatedStr,
                  })),
                  value: pipe(
                    v,
                    O.fromPredicate(FormValue.isValid),
                    O.map((v) => v.value.selected),
                  ),
                  isLoading: FormValue.isVerifying(v),
                  validation: FormValue.isInvalid(v) ? "error" : undefined,
                };
              },
            ),
            toSelector: flow(
              currentSelector,
              (s) => s.payload.to,
              (v): SelectorProps<Repository> => {
                return {
                  options: v.value.items.map((i) => ({
                    value: i,
                    label: i.name as string as TranslatedStr,
                  })),
                  value: pipe(
                    v,
                    O.fromPredicate(FormValue.isValid),
                    O.map((v) => v.value.selected),
                  ),
                  isLoading: FormValue.isVerifying(v),
                  validation: FormValue.isInvalid(v) ? "error" : undefined,
                };
              },
            ),
            handlerSelector: flow(currentSelector, (s) => s.payload.handler),
            executedSelector: flow(
              currentSelector,
              (s) => s.payload.isExecuted,
            ),
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <FormWrapper>
          <SearchCombobox
            label={t("Repository")}
            getId={(v) => v.id}
            onChange={flow(O.map((v) => dispatch(Edit.setRepository(v.id))))}
            onSearch={flow(Edit.searchRepository, dispatch)}
            selector={r.itemSelector}
          />
          <SearchCombobox
            label={t("Form repository")}
            getId={(v) => v.id}
            onChange={flow(O.map((v) => dispatch(Edit.setFrom(v.id))))}
            onSearch={flow(Edit.searchFrom, dispatch)}
            selector={r.fromSelector}
          />
          <SearchCombobox
            label={t("To repository")}
            getId={(v) => v.id}
            onChange={flow(O.map((v) => dispatch(Edit.setTo(v.id))))}
            onSearch={flow(Edit.searchTo, dispatch)}
            selector={r.toSelector}
          />
          <HandlerInput
            value$={r.handlerSelector}
            onChange={flow(Edit.setHandler, dispatch)}
          />
          <Toggle disabled value$={r.executedSelector}>
            {t("Is executed")}
          </Toggle>
          <SchemaFieldsJsonEditor
            value$={r.schemaSelector}
            onChange={flow(
              Edit.schemaFieldsState.actions.onChange.create,
              dispatch,
            )}
          />
        </FormWrapper>
      );
  }
}
