import i18next from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";
import { SupportedLang } from "../types";
import { supportedLangs } from "../constants";
import FileLoaderModule from "./FileLoaderModule";

export type I18nInitProps = {
  lang?: SupportedLang;
  fallbackLang?: SupportedLang;
};

export default function i18nInit({
  lang = SupportedLang.en,
  fallbackLang = SupportedLang.en,
}: I18nInitProps): Promise<unknown> {
  return i18next
    .use(new FileLoaderModule())
    .use(initReactI18next)
    .use(ICU)
    .init({
      supportedLngs: supportedLangs,
      fallbackLng: fallbackLang,
      lng: lang,
      preload: [fallbackLang, lang],
      partialBundledLanguages: true, // required for module to work
      interpolation: {
        /**
         * React already safes from XSS
         * https://www.i18next.com/translation-function/interpolation#unescape
         */
        escapeValue: false,
      },
      //debug: true,
    });
}
