import { Typed } from "utils/Typed";
import {
  DataTypeEntity,
  DataTypeId,
  DataType,
} from "types/src/DataType/DataType";
import { Client, DsError, isNotFoundError, unknownError } from "ds";
import { strictGuard } from "utils/strictGuard";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import {
  catchError,
  distinctUntilKeyChanged,
  from,
  map,
  NEVER,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { getDataType, removeDataType, updateDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import { Epic } from "../../../../../../../../types/RootEpic";
import { DataTypeSchema } from "../../../../../../../../generic-states/Schema";

const prefix = "Ready:DataManager:DataTypes:Create";

const schema = DataTypeSchema.createState(`${prefix}:Schema`);

interface LoadingPayload {
  id: DataTypeId;
}

interface ErrorPayload extends LoadingPayload {
  error: DsError;
}

interface Payload extends LoadingPayload {
  name: string;
  description: string | undefined;
  default: boolean;
  entity: DataTypeEntity;
  schema: Typed.GetTypes<typeof schema.states>;
}
const states = Typed.builder
  .add("loading", (p: LoadingPayload) => p)
  .add("loadError", (p: ErrorPayload) => p)
  .add("ready", (p: Payload) => p)
  .add("saving", (p: Payload) => p)
  .add("removeConfirmation", (p: Payload) => p)
  .add("removing", (p: Payload) => p)
  .finish()(prefix);

const actions = Typed.builder
  .add("loadSuccess", (p: DataType) => p)
  .add("loadError", (p: DsError) => p)
  .add("setName", (p: string) => p)
  .add("setDescription", (p: string) => p)
  .add("setDefault", (p: boolean) => p)
  .add("saveSuccess", (p: DataType) => p)
  .add("saveFail", (p: DsError) => p)
  .add("submit")
  .add("remove")
  .add("removeConfirm")
  .add("removeDecline")
  .add("removeError", (p: DsError) => p)
  .add("removeSuccess")
  .finish()(`${prefix}:Actions`);

const exits = Typed.builder
  .add("saved", (p: DataTypeId) => p)
  .add("removed", (p: DataTypeId) => p)
  .finish()(`${prefix}:Exits`);

export namespace DataTypeEdit {
  export type CreateActions = Typed.GetTypes<typeof actions>;
  export type SchemaActions = Typed.GetTypes<typeof schema.actions>;
  export type Actions = CreateActions | SchemaActions;
  export type State = Typed.GetTypes<typeof states>;
  export type Exits = Typed.GetTypes<typeof exits>;

  const epic: Epic<Actions, State, { pyckAdminClient$: Observable<Client> }> = (
    state$,
    { pyckAdminClient$ },
  ) => {
    return state$.pipe(
      distinctUntilKeyChanged("type"),
      withLatestFrom(pyckAdminClient$),
      switchMap(([s, client]) => {
        if (states.loading.is(s)) {
          return from(getDataType(client, s.payload.id)).pipe(
            map(
              flow(
                E.map(actions.loadSuccess.create),
                E.getOrElseW((e) => {
                  if (isNotFoundError(e)) return actions.loadError.create(e);
                  return actions.loadError.create(unknownError());
                }),
              ),
            ),
            catchError(() => of(actions.loadError.create(unknownError()))),
          );
        }

        if (states.saving.is(s)) {
          return from(
            updateDataType(client, s.payload.id, {
              name: s.payload.name,
              description: s.payload.description,
              jsonSchema: s.payload.schema.payload.schema,
              frontendSchema: s.payload.schema.payload.uiSchema,
              entity: s.payload.entity,
              default: s.payload.default,
            }),
          ).pipe(
            map(
              flow(
                E.map(actions.saveSuccess.create),
                E.getOrElseW(actions.saveFail.create),
              ),
            ),
            catchError(() => of(actions.saveFail.create(unknownError()))),
          );
        }

        if (states.removing.is(s)) {
          return from(removeDataType(client, s.payload.id)).pipe(
            map(
              flow(
                E.map(actions.removeSuccess.create),
                E.getOrElseW(actions.removeError.create),
              ),
            ),
            catchError(() => of(actions.removeError.create(unknownError()))),
          );
        }

        if (states.removeConfirmation.is(s)) return NEVER;
        if (states.loadError.is(s)) return NEVER;
        if (states.ready.is(s)) return NEVER;

        silentUnreachableError(s);
        return NEVER;
      }),
    );
  };

  export const instance = {
    states,
    actions,
    exits,
    isState: Typed.getGuard(states),
    isAction: strictGuard(
      (a: Actions): a is Actions =>
        Typed.getGuard(actions)(a) || schema.isAction(a),
    ),
    reducer: (s: State, a: Actions): E.Either<Exits, State> => {
      if (schema.isAction(a)) {
        return states.ready.is(s)
          ? E.right(
              states.ready.create({
                ...s.payload,
                schema: Fp.pipe(
                  schema.reducer(s.payload.schema, a),
                  E.getOrElse((e) => {
                    silentUnreachableError(e);
                    return s.payload.schema;
                  }),
                ),
              }),
            )
          : E.right(s);
      }

      if (actions.loadSuccess.is(a)) {
        return states.loading.is(s)
          ? E.right(
              states.ready.create({
                id: a.payload.id,
                entity: a.payload.entity,
                name: a.payload.name,
                description: a.payload.description,
                default: a.payload.default,
                schema: schema.init({
                  schema: a.payload.schema,
                  uiSchema: a.payload.frontendSchema ?? {},
                }),
              }),
            )
          : E.right(s);
      }

      if (actions.loadError.is(a)) {
        return states.loading.is(s)
          ? E.right(
              states.loadError.create({
                id: s.payload.id,
                error: a.payload,
              }),
            )
          : E.right(s);
      }

      if (actions.setName.is(a)) {
        return states.ready.is(s)
          ? E.right(states.ready.create({ ...s.payload, name: a.payload }))
          : E.right(s);
      }

      if (actions.setDescription.is(a)) {
        return states.ready.is(s)
          ? E.right(
              states.ready.create({ ...s.payload, description: a.payload }),
            )
          : E.right(s);
      }

      if (actions.setDefault.is(a)) {
        return states.ready.is(s)
          ? E.right(states.ready.create({ ...s.payload, default: a.payload }))
          : E.right(s);
      }

      if (actions.submit.is(a)) {
        return states.ready.is(s)
          ? E.right(states.saving.create(s.payload))
          : E.right(s);
      }

      if (actions.saveSuccess.is(a)) {
        return states.saving.is(s)
          ? E.left(exits.saved.create(a.payload.id))
          : E.right(s);
      }

      if (actions.saveFail.is(a)) {
        return states.saving.is(s)
          ? E.right(states.ready.create(s.payload))
          : E.right(s);
      }

      if (actions.remove.is(a)) {
        return states.ready.is(s)
          ? E.right(states.removeConfirmation.create(s.payload))
          : E.right(s);
      }

      if (actions.removeConfirm.is(a)) {
        return states.removeConfirmation.is(s)
          ? E.right(states.removing.create(s.payload))
          : E.right(s);
      }
      if (actions.removeDecline.is(a)) {
        return states.removeConfirmation.is(s)
          ? E.right(states.ready.create(s.payload))
          : E.right(s);
      }
      if (actions.removeError.is(a)) {
        return states.removing.is(s)
          ? E.right(states.ready.create(s.payload))
          : E.right(s);
      }
      if (actions.removeSuccess.is(a)) {
        return states.removing.is(s)
          ? E.left(exits.removed.create(s.payload.id))
          : E.right(s);
      }

      silentUnreachableError(a);
      return E.right(s);
    },
    epic,
    init: (id: DataTypeId) => states.loading.create({ id }),
    subStates: {
      schema,
    },
  };
}
