import * as Redux from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import * as ReactRedux from "react-redux/es";
import { EqualityFn } from "react-redux/es/types";
import { Primitive } from "types/src/Primitive";
import { reducer } from "./reducer";
import { State } from "./types/State";
import { Actions, isAction } from "./types/Actions";
import { rootEpic } from "./epic";
import * as AuthState from "./states/Auth";
import * as NotificationsState from "./states/Notifications";
import { DataSource } from "./types/DataSource";
import { Selector } from "./types/Selectors";

export type { Selector } from "./types/Selectors";

const createReducer =
  (initialState: State) =>
  (s: State | undefined, a: Redux.Action | Actions): State => {
    if (!s) return initialState;

    if (isAction(a)) return reducer(s, a);

    return s;
  };

export function createStore(
  ds: DataSource,
  initialState: State = {
    content: AuthState.init({
      goTo: { type: "customers" },
    }),
    notifications: NotificationsState.init(),
  },
): Redux.Store<State, Actions> {
  const epicMiddleware = createEpicMiddleware<
    Actions,
    Actions,
    State,
    DataSource
  >({ dependencies: ds });

  // const middlewares = Redux.applyMiddleware(epicMiddleware);

  const store = Redux.configureStore({
    reducer: createReducer(initialState),
    // @ts-expect-error, fix-later
    middleware: (get) => get().concat(epicMiddleware),
  });

  epicMiddleware.run((_, s$, deps) => rootEpic(s$, deps));

  return store as Redux.Store<State, Actions>;
}

export const StateProvider = ReactRedux.Provider<Actions, State>;
export const useStore = ReactRedux.createStoreHook<State, Actions>();
export const useDispatch = ReactRedux.createDispatchHook<State, Actions>();

interface UseSelector {
  <T extends object>(fn: Selector<T>, eq: EqualityFn<T>): T;
  <T extends Primitive>(fn: Selector<T>, eq?: EqualityFn<T>): T;
  <T>(fn: Selector<T>, eq: EqualityFn<T>): T;
  <T>(fn: Selector<T>, eq: EqualityFn<T>): T;
}
export const useSelector: UseSelector = ReactRedux.useSelector;

export type RootState = State;
