import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  Observer,
  Subscription,
} from "rxjs";
import { OperatorFunction } from "rxjs/internal/types";

// @ts-expect-error, I need to review this later, but it solves the variance constraint
export class BehaviorValue<out T> extends BehaviorSubject<T> {
  private subscription: Subscription | null = null;

  public get value() {
    return this.getValue();
  }

  public getValue() {
    if (!this.subscription && this.o$ instanceof BehaviorSubject) {
      super.next(this.o$.getValue());
    }

    return super.getValue();
  }

  constructor(
    v: T,
    private o$: Observable<T>,
  ) {
    super(v);
  }

  static fromBehaviorSubject<T>(v: BehaviorSubject<T>): BehaviorValue<T> {
    return new BehaviorValue<T>(v.getValue(), v);
  }

  map<B>(f: (v: T) => B): BehaviorValue<B> {
    return new BehaviorValue(f(this.getValue()), this.o$.pipe(map(f)));
  }

  static combine<T extends unknown[]>(
    sources: [...ObservableInputTuple<T>],
  ): BehaviorValue<T> {
    return new BehaviorValue<T>(
      sources.map((s) => s.getValue()) as T,
      combineLatest(sources),
    );
  }

  subscribe(
    observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | null,
  ): Subscription;
  subscribe(
    observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | null,
  ): Subscription {
    if (!this.subscription) {
      this.subscription = this.o$.subscribe((v) => super.next(v));
    }

    const subscription = super.subscribe(
      observerOrNext as Partial<Observer<T>> | ((value: T) => void),
    );

    subscription.add(() => this._unsubscribeIfNoSubscriptions());

    return subscription;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  next(_: never) {}

  private _unsubscribeIfNoSubscriptions() {
    if (this.subscription && !this.observed) {
      this.subscription.unsubscribe();

      this.subscription = null;
    }
  }

  pipe(): BehaviorValue<T>;
  pipe(...operations: OperatorFunction<T, T>[]): BehaviorValue<T>;
  pipe(...operations: OperatorFunction<T, T>[]): BehaviorValue<T> {
    // @ts-expect-error, it seems it is caused by the limited list of parameters of the pipe method
    return new BehaviorValue(this.getValue(), this.o$.pipe(...operations));
  }
}

export type ObservableInputTuple<T> = {
  [K in keyof T]: BehaviorValue<T[K]>;
};
