import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { styleCustomFilterInputWidth } from "@layouts/ListingTable/utils/styles";
import { useState } from "react";
import { Box } from "@mui/material";
import { ChevronDown } from "@layouts/ListingTable/filters/custom/common/ChevronDown";
import { RemoveButton } from "@layouts/ListingTable/filters/custom/common/RemoveButton";
import { Sxs } from "../../../../../types/styles";
import { FilterPropsCustom, FilterTypeSelect } from "../../../types/filters";

export const Select = ({
  config: { options },
  value,
  onChange,
  onRemove,
}: FilterPropsCustom<FilterTypeSelect>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormControl sx={sxs.wrapper}>
      <MuiSelect
        value={value?.id || ""}
        onChange={({ target: { value: id } }) => onChange({ id })}
        sx={sxs.select}
        MenuProps={MenuProps}
        displayEmpty
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        {undefined === value?.id && (
          <MenuItem value="" disabled>
            {options.label}
          </MenuItem>
        )}
        {options.choices.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
      <Box sx={sxs.removeButton}>
        {isOpen ? (
          <ChevronDown />
        ) : (
          <RemoveButton onClick={onRemove}></RemoveButton>
        )}
      </Box>
    </FormControl>
  );
};

const sxs = {
  wrapper: (theme) => ({
    width: styleCustomFilterInputWidth(theme),
    position: "relative",
  }),
  select: (theme) => ({
    padding: 0,
    border: 0,
    borderRadius: 2,
    height: theme.spacing(10),
    boxShadow: 0,

    ".MuiInputBase-input": {
      boxShadow: 0,
      borderRadius: 2,
      padding: theme.spacing(2, 3),
    },
    ".MuiInputBase-input:hover": {
      backgroundColor: theme.palette.surface.primary,
    },
    ".MuiSelect-icon": {
      visibility: "hidden",
    },
  }),
  removeButton: (theme) => ({
    ...sxsCommon.inputRemoveButton(theme),
    background: "unset",
  }),
  dropdownWrapper: (theme) => ({
    marginTop: 2,
    boxShadow: theme.shadows[24],
  }),
  dropdown: (theme) => ({
    py: 1,

    ".MuiMenuItem-root": {
      margin: theme.spacing(0.25, 1.5),
      borderRadius: theme.spacing(1.5),
      padding: 2,
    },
    ".MuiMenuItem-root.Mui-selected, .MuiMenuItem-root:hover, .MuiMenuItem-root.Mui-selected:focus":
      {
        backgroundColor: theme.palette.surface.primary,
      },
  }),
} satisfies Sxs;

const MenuProps = {
  PaperProps: { sx: sxs.dropdownWrapper },
  MenuListProps: { sx: sxs.dropdown },
};
