import { TranslatedStr } from "types/src/TranslatedStr";
import { TOptionsBase as I18nextOptions } from "i18next";

export enum SupportedLang {
  en = "en",
  de = "de",
}

export type TranslationKey =
  /**
   * Initial solution was to make it type safe:
   * `keyof typeof import("../translations/en.json")`
   * But was decided that it will slow down TypeScript
   */
  string;

export type TFunc = (
  text: TranslationKey,
  vars?: Record<string, string | number> &
    Partial<
      Record<
        // restrict special keys that change translation behavior
        keyof I18nextOptions,
        never
      >
    >,

  /* fixme: options: { context?: string }
  {
    "Obvious text": "Offensichtlicher Text",
    "Bank": {
      // contexts
      "finance": "Bank",
      "geography": "Ufer" // River related
    }
  }
   */
) => TranslatedStr;

export type Translator = {
  t: TFunc;
  language: SupportedLang;
};

export type Translations = Record<TranslationKey, string>;
