import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          id: s.payload.id,
          dataTypeId: s.payload.dataTypeId,
          schema: schemaFieldsState.reducer(s.payload.schema, a),
          sku: s.payload.sku,
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(State.loadError(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            id: a.payload.item.id,
            dataTypeId: a.payload.item.dataTypeId,
            sku: FormValue.valid(a.payload.item.sku),
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.fields,
              values: a.payload.item.fields,
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:SetSku": {
      if (State.isReady(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            sku: isNoEmptyString(a.payload)
              ? FormValue.valid(a.payload)
              : FormValue.invalid("required", a.payload),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:Submit": {
      if (State.isReady(s)) {
        if (FormValue.isValid(s.payload.sku)) {
          return E.right(
            State.saving({
              ...s.payload,
              sku: s.payload.sku,
            }),
          );
        } else {
          return E.right(
            State.ready({
              ...s.payload,
              sku: FormValue.invalid("required", s.payload.sku.value),
            }),
          );
        }
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.saved.create(a.payload));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}
