import React, { useMemo } from "react";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { ColumnsConfigBase } from "@layouts/ListingTable/types/columns";
import {
  CustomFilterConfig,
  FilterGroup,
  FiltersConfigBase,
  FilterType,
} from "@layouts/ListingTable/types/filters";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Criteria, CriteriaChange } from "@layouts/ListingTable/types/criteria";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { silentUnreachableError } from "utils/exceptions";
import { Text as TypeText } from "@layouts/ListingTable/filters/custom/Text";
import { Select as TypeSelect } from "src/layouts/ListingTable/filters/custom/Select";
import { FilterAdapterCustom } from "@layouts/ListingTable/FilterAdapter/Custom";
import * as Rx from "rxjs";
import { isDeepEqual } from "utils/object";
import { MultiChoice as TypeMultiChoice } from "@layouts/ListingTable/filters/custom/MultiChoice";
import { NumberRange as TypeNumberRange } from "@layouts/ListingTable/filters/custom/NumberRange";
import { AddMore } from "@layouts/ListingTable/List/Header/CustomFilters/AddMore";

export namespace CustomFilters {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    filters: FiltersConfig;
    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: CriteriaChange<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
  };
}

export const CustomFilters = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  filters,
  criteria$,
  onCriteriaChange,
}: CustomFilters.Props<DataEntry, ColumnsConfig, FiltersConfig>) => {
  const ids = useBehaviorValue<Array<keyof FiltersConfig[FilterGroup.custom]>>(
    useMemo(
      () =>
        criteria$
          .map(({ filters }) =>
            filters?.[filterGroup] ? Object.keys(filters[filterGroup]) : [],
          )
          // prevent rerender if id list is the same
          .pipe(Rx.distinctUntilChanged(isDeepEqual)),
      [criteria$],
    ),
  );

  if (!ids || !ids.length) return null;

  const notAllFiltersAreInCriteria =
    ids.length < Object.keys(filters[FilterGroup.custom]).length;

  return (
    <Box sx={sxs.wrapper}>
      {ids.map((id) => {
        const filter: CustomFilterConfig = filters[FilterGroup.custom][id];

        switch (filter.type) {
          case FilterType.text: {
            return (
              <FilterAdapterCustom<
                DataEntry,
                ColumnsConfig,
                FiltersConfig,
                typeof filter
              >
                key={id.toString()}
                criteria$={criteria$}
                onCriteriaChange={onCriteriaChange}
                filters={filters}
                filterId={id}
                Filter={TypeText}
              />
            );
          }
          case FilterType.select: {
            return (
              <FilterAdapterCustom<
                DataEntry,
                ColumnsConfig,
                FiltersConfig,
                typeof filter
              >
                key={id.toString()}
                criteria$={criteria$}
                onCriteriaChange={onCriteriaChange}
                filters={filters}
                filterId={id}
                Filter={TypeSelect}
              />
            );
          }
          case FilterType.multiChoice: {
            return (
              <FilterAdapterCustom<
                DataEntry,
                ColumnsConfig,
                FiltersConfig,
                typeof filter
              >
                key={id.toString()}
                criteria$={criteria$}
                onCriteriaChange={onCriteriaChange}
                filters={filters}
                filterId={id}
                Filter={TypeMultiChoice}
              />
            );
          }
          case FilterType.numberRange: {
            return (
              <FilterAdapterCustom<
                DataEntry,
                ColumnsConfig,
                FiltersConfig,
                typeof filter
              >
                key={id.toString()}
                criteria$={criteria$}
                onCriteriaChange={onCriteriaChange}
                filters={filters}
                filterId={id}
                Filter={TypeNumberRange}
              />
            );
          }
        }

        silentUnreachableError(filter);
        return null;
      })}
      {notAllFiltersAreInCriteria && (
        <AddMore<DataEntry, ColumnsConfig, FiltersConfig>
          {...{ filters, criteria$, onCriteriaChange }}
        />
      )}
    </Box>
  );
};

const filterGroup = FilterGroup.custom;

const sxs = {
  wrapper: () => ({
    display: "flex",
    alignItems: "start",
    gap: 3,
    flexWrap: "wrap",
  }),
} satisfies Record<string, SxProps<Theme>>;
