import { useCallback } from "react";
import { endOfDay, startOfDay } from "date-fns";
import { DateRangePicker } from "..";

export const useOnStartChange = ({
  value,
  onChange,
  min,
  max,
}: Pick<DateRangePicker.Props, "value" | "onChange" | "min" | "max">) =>
  useCallback(
    (date: Date | null) => {
      if (!date) {
        return onChange({
          start: undefined,
          end: value?.end,
        });
      }
      if (min && date < min) {
        return;
      }
      if (max && date > max) {
        return;
      }
      onChange({
        start: startOfDay(date),
        end: value?.end
          ? endOfDay(date > value.end ? date : value.end)
          : undefined,
      });
    },
    [value?.end, onChange, min, max],
  );

export const useOnEndChange = ({
  value,
  onChange,
  min,
  max,
}: Pick<DateRangePicker.Props, "value" | "onChange" | "min" | "max">) =>
  useCallback(
    (date: Date | null) => {
      if (!date) {
        return onChange({
          start: value?.start,
          end: undefined,
        });
      }
      if (min && date < min) {
        return;
      }
      if (max && date > max) {
        return;
      }
      onChange({
        start: value?.start
          ? startOfDay(date < value.start ? date : value.start)
          : undefined,
        end: endOfDay(date),
      });
    },
    [value?.start, onChange, min, max],
  );
