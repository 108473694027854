import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Sxs } from "../../../../../../types/styles";
import { styleSecondaryTextColor } from "../../../../utils/styles";
import { ProgressBar } from "./ProgressBar";
import { accentColor } from "./constants";

export namespace Stock {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.stock>["renderProps"]
  >;
}

export const Stock = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  current,
  max,
}: Stock.Props<DataEntry, ColumnConfig>) => (
  <Box sx={sxs.wrapper}>
    <ProgressBar {...{ current, max }} />
    <Typography variant="subtitle2">
      <Box component="span" sx={sxs.textAccent}>
        {current}
      </Box>
      <Box component="span" sx={sxs.textSecondary}>
        /{max}
      </Box>
    </Typography>
  </Box>
);

const sxs = {
  wrapper: (theme) => ({
    display: "inline-flex",
    alignItems: "center",
    gap: 3,
  }),
  textAccent: (theme) => ({
    color: accentColor,
  }),
  textSecondary: (theme) => ({
    color: styleSecondaryTextColor(theme),
  }),
} satisfies Sxs;
