import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { flow } from "fp-ts/function";

export const _gte: (
  v: O.Option<[ISODate | undefined, ISODate | undefined]>,
) => ISODate | undefined = flow(
  O.chain((v) => O.fromNullable(v[0])),
  O.toUndefined,
);

export const _lte: (
  v: O.Option<[ISODate | undefined, ISODate | undefined]>,
) => ISODate | undefined = flow(
  O.chain((v) => O.fromNullable(v[1])),
  O.toUndefined,
);
