import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { NoEmptyString } from "types/src/NoEmptyString";
import { ItemMovementFragmentFragment } from "../generated/graphql";
import { inventoryItemFragmentToInventoryItem } from "./InventoryItems";
import { repositoryFragmentToRepository } from "./Repositories";

export function itemMovementFragmentToItemMovement(
  fragment: ItemMovementFragmentFragment,
): ItemMovement {
  return {
    id: fragment.id as ItemMovement["id"],
    fields: (fragment.data ?? {}) as ItemMovement["fields"],
    createdAt: fragment.createdAt,
    collectionId: fragment.collectionID as ItemMovement["collectionId"],
    orderId: fragment.orderID as ItemMovement["orderId"],
    updatedAt: fragment.updatedAt ?? undefined,
    dataTypeId: fragment.dataTypeID as ItemMovement["dataTypeId"],
    executed: fragment.executed ?? false,
    executedAt: fragment.executedAt ?? undefined,
    handler: fragment.handler as NoEmptyString,
    quantity: fragment.quantity,
    item: inventoryItemFragmentToInventoryItem(fragment.item),
    from: repositoryFragmentToRepository(fragment.from),
    to: repositoryFragmentToRepository(fragment.to),
  };
}
