import MuiButton, { IconButtonProps } from "@mui/material/IconButton";
import { forwardRef } from "react";

export namespace IconButton {
  export interface Props
    extends Pick<IconButtonProps, "children" | "size" | "onClick"> {}
}

export const IconButton = forwardRef<HTMLButtonElement, IconButton.Props>(
  (p, ref) => <MuiButton {...p} ref={ref} />,
);
