import { Selector, useSelector } from "state-manager";
import { Input as InputComponent } from "ui/components/Input";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface InputProps {
  value$: Selector<string | undefined>;
  onChange: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  validation?: "success" | "error";
  label?: TranslatedStr;
}

export function Input({
  value$,
  onChange,
  placeholder,
  validation,
  readOnly,
  label,
}: InputProps) {
  const value = useSelector(value$);

  return (
    <InputComponent
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={validation === "error"}
      readOnly={readOnly}
      label={label}
    />
  );
}
