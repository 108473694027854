import { Eq } from "fp-ts/Eq";

export type Tuple<A, B> = [A, B];

export namespace Tuple {
  export function create<A, B>(...a: [A, B]): Tuple<A, B>;
  export function create<A, B>(...a: [A]): (b: B) => Tuple<A, B>;
  export function create<A, B>(
    ...a: [A, B] | [A]
  ): Tuple<A, B> | ((b: B) => Tuple<A, B>) {
    return a.length === 1
      ? (b: B): Tuple<A, B> => create(a[0], b)
      : [a[0], a[1]];
  }

  export const getEq = <A, B>(a: Eq<A>, b: Eq<B>): Eq<Tuple<A, B>> => ({
    equals: (x, y) => a.equals(x[0], y[0]) && b.equals(x[1], y[1]),
  });
}

export type SymmetricTuple<A> = [A, A];

export namespace SymmetricTuple {
  export function create<A>(...a: [A, A]): Tuple<A, A>;
  export function create<A>(...a: [A]): (b: A) => Tuple<A, A>;
  export function create<A>(
    ...a: [A, A] | [A]
  ): Tuple<A, A> | ((b: A) => Tuple<A, A>) {
    return a.length === 1
      ? (b: A): Tuple<A, A> => create(a[0], b)
      : [a[0], a[1]];
  }

  export const getEq: <T>(qe: Eq<T>) => Eq<SymmetricTuple<T>> = (eq) =>
    Tuple.getEq(eq, eq);
}

/**
 * @deprecated, use SymmetricTuple.create
 */
export const symmetricTuple = SymmetricTuple.create;
