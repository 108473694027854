import { Selector, useSelector } from "state-manager";
import { SymmetricTuple } from "types/src/Tuple";
import * as Fp from "fp-ts/function";
import { Number } from "@Containers/Form/Number";
import { TranslatedStr } from "types/src/TranslatedStr";

export namespace NumberFrom {
  export interface Props {
    label: TranslatedStr;
    selector$: Selector<SymmetricTuple<number | undefined>>;
    onChange: (v: SymmetricTuple<number | undefined>) => void;
  }
}

export function NumberFrom(p: NumberFrom.Props) {
  const to = useSelector(Fp.flow(p.selector$, (v) => v[1]));

  return (
    <Number
      label={p.label}
      value$={Fp.flow(p.selector$, (v) => v[0])}
      onChange={(v) => p.onChange([v, to])}
      min={0}
      max={to}
    />
  );
}
export namespace NumberTo {
  export interface Props {
    label: TranslatedStr;
    selector$: Selector<SymmetricTuple<number | undefined>>;
    onChange: (v: SymmetricTuple<number | undefined>) => void;
  }
}

export function NumberTo(p: NumberTo.Props) {
  const from = useSelector(Fp.flow(p.selector$, (v) => v[0]));

  return (
    <Number
      label={p.label}
      value$={Fp.flow(p.selector$, (v) => v[1])}
      onChange={(v) => p.onChange([from, v])}
      min={from}
    />
  );
}
