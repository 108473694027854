import React from "react";
import TableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import Paper, { PaperProps } from "@mui/material/Paper";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { BulkSelectProps } from "@layouts/ListingTable/types/BulkSelectProps";
import { ColumnsConfigBase, ColumnsVisibility } from "../../types/columns";
import { DataEntryBase, EntryAction, ListData } from "../../types/data";
import { FiltersConfigBase } from "../../types/filters";
import { Criteria, CriteriaChange } from "../../types/criteria";
import { createBodyBulkSelectProp, createHeaderBulkSelectProp } from "./utils";
import { Body } from "./Body";
import { Header } from "./Header";

export namespace Table {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Actions extends string,
  > = {
    columns: ColumnsConfig;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: CriteriaChange<keyof ColumnsConfig, FiltersConfig>,
    ) => void;

    data$: BehaviorValue<ListData<DataEntry>>;
    bulkSelect: BulkSelectProps<DataEntry["id"], Actions> | undefined;
    entryActions?: (entry: DataEntry) => EntryAction<DataEntry>[];
  };
}

export const Table = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Actions extends string,
>({
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  criteria$,
  onCriteriaChange,
  data$,
  entryActions,
  bulkSelect,
}: Table.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>) => (
  <TableContainer component={Container}>
    <MuiTable size="small">
      <Header<DataEntry, ColumnsConfig, FiltersConfig, Actions>
        columns={columns}
        columnsVisibility$={columnsVisibility$}
        onColumnsVisibilityChange={onColumnsVisibilityChange}
        criteria$={criteria$}
        onCriteriaChange={onCriteriaChange}
        entryActions={entryActions}
        bulkSelect={createHeaderBulkSelectProp({ bulkSelect, data$ })}
      />
      <Body<DataEntry, ColumnsConfig>
        columns={columns}
        columnsVisibility$={columnsVisibility$}
        onColumnsVisibilityChange={onColumnsVisibilityChange}
        data$={data$}
        entryActions={entryActions}
        bulkSelect={createBodyBulkSelectProp({ bulkSelect })}
      ></Body>
    </MuiTable>
  </TableContainer>
);

const Container: React.FC<PaperProps> = ({ children, ...p }) => (
  <Paper
    {...p}
    sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      border: "none",
    }}
  >
    {children}
  </Paper>
);
