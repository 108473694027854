import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { Box } from "@mui/material";
import { Sxs } from "../../../../../types/styles";

export namespace Badge {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.badge>["renderProps"]
  >;
}

export const Badge = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  text,
  color,
}: Badge.Props<DataEntry, ColumnConfig>) => (
  <Box sx={[sxs.wrapper, colorsSx[color]]}>{text}</Box>
);

const sxs = {
  wrapper: (theme) => ({
    ...theme.typography.caption,
    display: "inline-block",
    borderRadius: theme.spacing(4),
    padding: theme.spacing(0.5, 2),
  }),
} satisfies Sxs;

type Color = Badge.Props<
  DataEntryBase,
  ColumnConfigBase<DataEntryBase>
>["color"];

const colorsSx: Record<Color, Sxs[string]> = {
  green: (theme) => ({
    backgroundColor: "#EDF7ED", // fixme: compute from theme colors?
    color: theme.palette.success.light,
  }),
  blue: (theme) => ({
    backgroundColor: "#E5F6FD",
    color: theme.palette.info.light,
  }),
};
