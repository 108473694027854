import { RootState } from "state-manager";
import { useTranslation } from "i18n";
import { Dispatch } from "react";
import * as Fp from "fp-ts/function";
import { Picker } from "@Containers/Form/Picker";
import * as Filters from "../../../../../packages/state-manager/src/generic-states/Filters";

export namespace Orphans {
  export type Filter = { status: "active" | "orphan" | "all" };

  export interface Props<P extends string> {
    selector$: (s: RootState) => Filters.State<P, Filter>;
    dispatch: Dispatch<Filters.Actions<P, Filter>>;
    actions: ReturnType<typeof Filters.createState<P, Filter>>["actions"];
  }
}

export function Orphans<P extends string>(p: Orphans.Props<P>) {
  const { t } = useTranslation();
  const options = [
    { value: "active", label: t("Active") } as const,
    { value: "orphan", label: t("Orphan") } as const,
  ];

  return (
    <Picker<Orphans.Filter["status"]>
      label={t("Status")}
      value$={Fp.flow(p.selector$, (v) => v.payload.fields.status)}
      onChange={Fp.flow(
        (v) => p.actions.setValue.create({ status: v ?? "all" }),
        p.dispatch,
      )}
      options={options}
    />
  );
}
