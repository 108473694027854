import Stack from "@mui/material/Stack";
import { ReactNode } from "react";

export const FiltersWrapper = (p: { children?: ReactNode }) => {
  return (
    <Stack direction={"row"} gap={2}>
      {p.children}
    </Stack>
  );
};
