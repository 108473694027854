import { RootState, useSelector } from "state-manager";
import { Toggle as ToggleComponent } from "ui/components/Toggle";
import { ReactNode } from "react";

export interface ToggleProps {
  disabled?: boolean;
  value$: (s: RootState) => boolean;
  onChange?: (value: boolean) => void;
  children?: ReactNode;
}

export function Toggle({ value$, onChange, disabled, children }: ToggleProps) {
  const value = useSelector(value$);

  return (
    <ToggleComponent disabled={disabled} value={value} onChange={onChange}>
      {children}
    </ToggleComponent>
  );
}
