import { useDispatch, useSelector } from "state-manager";
import * as Auth from "state-manager/states/Auth";

import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as SandboxPreview from "state-manager/states/Ready/states/SandboxPreview";

import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";

import { StocksListing } from "state-manager/states/Ready/states/Stocks/states/Listing";
import * as StocksSingle from "state-manager/states/Ready/states/Stocks/states/Single";

import { silentUnreachableError, unreachableError } from "utils/exceptions";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut } from "state-manager/states/Ready";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { flow } from "fp-ts/function";
import * as Router from "@/router";

export function RouterDispatcher() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const statePath = useSelector(
    flow(
      (s) => s.content,
      (s): Router.Route | undefined => {
        if (Auth.isState(s)) {
          if (Auth.isIdle(s)) return undefined;
          if (Auth.isAuthConfirmation(s)) return "/callback";
          if (Auth.isLogin(s)) return "/login";
          if (Auth.isAuthError(s)) return "/login";
          if (Auth.isAuthentication(s)) return "/login";
        }

        if (Ready.isState(s)) {
          const subState = s.payload.subState;

          if (isSigningOut(subState)) return "/login";

          if (DataManager.isState(subState)) {
            const dmSubState = subState.payload.subState;

            if (Customers.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Customers.instance.subStates.listing.isState(listing)) {
                return Router.routes["/customers/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Customers.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/customers"].create();
              }

              return undefined;
            }

            if (InventoryItems.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (InventoryItems.instance.subStates.listing.isState(listing)) {
                return Router.routes["/inventory-items/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                InventoryItems.instance.subStates.listingAll.isState(listing)
              ) {
                return Router.routes["/inventory-items"].create();
              }

              return undefined;
            }

            if (ItemMovements.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (ItemMovements.instance.subStates.listing.isState(listing)) {
                return Router.routes["/item-movements/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                ItemMovements.instance.subStates.listingAll.isState(listing)
              ) {
                return Router.routes["/item-movements"].create();
              }

              return undefined;
            }

            if (DataManager.pickingOrderState.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (
                DataManager.pickingOrderState.subStates.listing.isState(listing)
              ) {
                return Router.routes["/picking-orders/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                DataManager.pickingOrderState.subStates.listingAll.isState(
                  listing,
                )
              ) {
                return Router.routes["/picking-orders"].create();
              }

              return Router.routes["/picking-orders"].create();
            }

            if (Suppliers.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Suppliers.instance.subStates.listing.isState(listing)) {
                return Router.routes["/suppliers/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Suppliers.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/suppliers"].create();
              }

              return undefined;
            }

            if (Repositories.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Repositories.instance.subStates.listing.isState(listing)) {
                return Router.routes["/repositories/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Repositories.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/repositories"].create();
              }

              return undefined;
            }

            if (RepositoryMovements.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (
                RepositoryMovements.instance.subStates.listing.isState(listing)
              ) {
                return Router.routes[
                  "/repository-movements/:dataTypeId"
                ].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                RepositoryMovements.instance.subStates.listingAll.isState(
                  listing,
                )
              ) {
                return Router.routes["/repository-movements"].create();
              }

              return undefined;
            }

            silentUnreachableError(dmSubState);
            return undefined;
          }

          if (StocksListing.instance.isState(subState))
            return Router.routes["/stocks"].create();
          if (StocksSingle.isState(subState))
            return Router.routes["/stocks/:id"].create({
              id: subState.payload.id,
            });

          if (BuilderPreview.isState(subState)) return "/warehouse-builder";
          if (BPMNPreview.isState(subState)) return "/bpmn-builder";
          if (ZitadelPreview.isState(subState)) return "/zitadel-app";
          if (SandboxPreview.isState(subState)) return "/graphql-sandbox";

          unreachableError(subState);
          return undefined;
        }

        unreachableError(s);
        return undefined;
      },
    ),
  );

  useEffect(
    () => {
      if (statePath && statePath !== location.pathname) {
        navigate(statePath);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statePath && statePath !== location.pathname],
  );

  useEffect(
    () => {
      if (location.pathname !== statePath) {
        const action = Router.getRedirectAction(location.pathname);

        if (action === undefined) return;

        switch (action.type) {
          case "data-types:create":
            dispatch(Ready.goToDataTypesCreate(action.payload));
            break;
          case "data-types:edit":
            dispatch(Ready.goToDataTypesEdit(action.payload));
            break;
          case "customers":
            dispatch(Ready.goToCustomers());
            break;
          case "customers:create":
            dispatch(Ready.goToCustomersCreate(action.dataTypeId));
            break;
          case "customers:edit":
            dispatch(Ready.goToCustomersEdit(action.id));
            break;
          case "customers:data-type":
            dispatch(Ready.goToCustomersByDataType(action.id));
            break;
          case "suppliers":
            dispatch(Ready.goToSuppliers());
            break;
          case "suppliers:create":
            dispatch(Ready.goToSuppliersCreate(action.dataTypeId));
            break;
          case "suppliers:edit":
            dispatch(Ready.goToSuppliersEdit(action.id));
            break;
          case "suppliers:data-type":
            dispatch(Ready.goToSuppliersByDataType(action.id));
            break;
          case "warehouse-builder":
            dispatch(Ready.goToWarehouseBuilder());
            break;
          case "bpmn-builder":
            dispatch(Ready.goToBPMNBuilder());
            break;
          case "zitadel-app":
            dispatch(Ready.goToZitadelApp());
            break;
          case "graphql-sandbox":
            dispatch(Ready.goToGraphQLSandbox());
            break;
          case "repositories":
            dispatch(Ready.goToRepositories());
            break;
          case "repositories:create":
            dispatch(Ready.goToRepositoriesCreate(action.dataTypeId));
            break;
          case "repositories:edit":
            dispatch(Ready.goToRepositoriesEdit(action.id));
            break;
          case "repositories:data-type":
            dispatch(Ready.goToRepositoriesByDataType(action.id));
            break;
          case "inventoryItems":
            dispatch(Ready.goToInventoryItems());
            break;
          case "inventoryItems:create":
            dispatch(Ready.goToInventoryItemsCreate(action.dataTypeId));
            break;
          case "inventoryItems:edit":
            dispatch(Ready.goToInventoryItemsEdit(action.id));
            break;
          case "inventoryItems:data-type":
            dispatch(Ready.goToInventoryItemsByDataType(action.id));
            break;
          case "itemMovements":
            dispatch(Ready.goToItemMovements());
            break;
          case "itemMovements:create":
            dispatch(Ready.goToItemMovementsCreate(action.id));
            break;
          case "itemMovements:edit":
            dispatch(Ready.goToItemMovementsEdit(action.id));
            break;
          case "itemMovements:data-type":
            dispatch(Ready.goToItemMovementsByDataType(action.id));
            break;

          case "repositoryMovements":
            dispatch(Ready.goToRepositoryMovements());
            break;
          case "repositoryMovements:create":
            dispatch(Ready.goToRepositoryMovementsCreate(action.id));
            break;
          case "repositoryMovements:edit":
            dispatch(Ready.goToRepositoryMovementsEdit(action.id));
            break;
          case "repositoryMovements:data-type":
            dispatch(Ready.goToRepositoryMovementsByDataType(action.id));
            break;

          case "pickingOrders":
            dispatch(Ready.goToPickingOrders());
            break;
          case "pickingOrders:create":
            dispatch(Ready.goToPickingOrdersCreate(action.id));
            break;
          case "pickingOrders:edit":
            dispatch(Ready.goToPickingOrdersEdit(action.id));
            break;
          case "pickingOrders:data-type":
            dispatch(Ready.goToPickingOrdersByDataType(action.id));
            break;

          case "stocks":
            dispatch(Ready.goToStocks());
            break;
          case "stocks:single":
            dispatch(Ready.goToStock(action.id));
            break;
          default:
            silentUnreachableError(action);
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  return null;
}
