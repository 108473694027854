import React from "react";
import { Typography } from "ui/components/Typography";
import { ArrowLeft as BackIcon } from "icons";
import { styled, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

export namespace Title {
  export type Props = {
    title: React.ReactNode;
    parentPage?: {
      title: React.ReactNode;
      open: () => void;
    };
    sx?: SxProps<Theme>; // this component is internal
  };
}

export const Title: React.FC<Title.Props> = ({ title, parentPage, sx }) => {
  const onBackClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    parentPage?.open();
  };

  return (
    <Wrapper sx={sx}>
      {parentPage && (
        <TitleText /*to match title height*/>
          <BackLink href="#" onClick={onBackClick}>
            <BackIcon />
          </BackLink>
        </TitleText>
      )}
      <div>
        <TitleText>{title}</TitleText>
        {parentPage && (
          <ParentPageTitle variant="subtitle1">
            {parentPage.title}
          </ParentPageTitle>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  margin-bottom: ${(p) => p.theme.spacing(8)};

  display: flex;
  align-items: flex-start;
  gap: ${(p) => p.theme.spacing(3)};
`;

const BackLink = styled("a")`
  text-decoration: none;
  display: inline-block;
`;

const TitleText: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography variant="h4">{children}</Typography>
);

const ParentPageTitle = styled(Typography)`
  opacity: 0.35;
`;
