import React, { useMemo } from "react";
import { Box, Button, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { usePopoverController } from "@layouts/ListingTable/Popover/hooks/usePopoverController";
import { CheckboxesList } from "@layouts/ListingTable/CheckboxesList";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { Popover } from "@layouts/ListingTable/Popover";
import { ChevronDown } from "@layouts/ListingTable/filters/custom/common/ChevronDown";
import {
  FilterPropsCustom,
  FilterTypeMultiChoice,
} from "../../../types/filters";
import { RemoveButton } from "../common/RemoveButton";

export const MultiChoice = ({
  config: { options },
  value,
  onChange,
  onRemove,
}: FilterPropsCustom<FilterTypeMultiChoice>) => {
  const { openPopover, popoverOpen, popoverProps } = usePopoverController();
  const labelSelectedChoices = useMemo(
    () =>
      (options.choices || [])
        .filter(({ id }) => value?.choices?.[id])
        .map(({ label }) => label)
        .join(", "),
    [options.choices, value?.choices],
  );

  return (
    <>
      <Button
        size="small"
        disabled={popoverOpen}
        sx={sxs.button}
        onClick={openPopover}
      >
        <Box
          component="span"
          sx={labelSelectedChoices.length ? undefined : sxs.labelNoValue}
        >
          {labelSelectedChoices || options.label}
        </Box>
        {popoverOpen ? <ChevronDown /> : <RemoveButton onClick={onRemove} />}
      </Button>
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          <CheckboxesList
            items={options.choices || []}
            value={value?.choices || {}}
            onChange={(choices) => onChange({ choices })}
          />
        </Popover>
      )}
    </>
  );
};

const sxs = {
  button: (theme) => ({
    ...sxsCommon.filterAsButton(theme),
  }),
  labelNoValue: (theme) => ({
    opacity: 0.3,
  }),
} satisfies Record<string, SxProps<Theme>>;

const popoverStaticProps: Partial<Popover.Props> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
