import { strictGuard } from "utils/strictGuard";
import * as Auth from "../states/Auth";
import * as Ready from "../states/Ready";
import * as Notifications from "../states/Notifications";

export const isAction = strictGuard(
  (a: Actions): a is Actions =>
    Auth.isAction(a) || Ready.isActions(a) || Notifications.isActions(a),
);

export type Actions = Auth.Actions | Ready.Actions | Notifications.Actions;
