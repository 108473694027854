import {
  ComponentProps,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from "react";
import { Box, Button, Divider as MuiDivider } from "@mui/material";
import { useTranslation } from "i18n";
import MuiAvatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

export function MainSidebar(p: MainSidebar.Props): ReactElement {
  return (
    <Box
      sx={(t) => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        p: t.spacing(8, 4, 6, 4),
        backgroundColor: t.palette.surface.primary,
        gap: 6,
      })}
    >
      {p.children}
    </Box>
  );
}

export namespace MainSidebar {
  export interface Props {
    children: ReactNode;
  }

  export function Group(p: { children?: ReactNode }): ReactElement {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {p.children}
      </Box>
    );
  }
  export function Divider(): ReactElement {
    return <MuiDivider orientation={"horizontal"} />;
  }

  export interface ItemProps {
    icon: ReactElement;
    title: string;
    isCurrent: boolean;
    onClick: () => void;
  }

  export function Item(p: ItemProps): ReactElement {
    return (
      <Button
        onClick={p.onClick}
        variant={p.isCurrent ? "contained" : "text"}
        color={p.isCurrent ? "primary" : undefined}
        sx={{
          p: 3,
          minWidth: "unset",
          "& > svg": { fontSize: "24px" },
          borderRadius: "6px",
        }}
      >
        {p.icon}
      </Button>
    );
  }

  export const Separator: JSXElementConstructor<ComponentProps<"div">> = styled(
    "div",
  )`
    flex: 1;
    padding-top: ${(p) => p.theme.spacing(3)};
  `;

  export interface AvatarProps {
    name: string;
    email: string;
    avatar?: string;

    onLogout: () => void;
  }

  export function Avatar(p: AvatarProps) {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    return (
      <>
        <MuiAvatar
          src={p.avatar}
          alt={p.name}
          sx={{ width: 48, height: 48 }}
          onClick={() => setOpen(!open)}
          ref={ref}
        >
          {p.name[0]}
        </MuiAvatar>
        <Menu open={open} onClose={() => setOpen(false)} anchorEl={ref.current}>
          <MenuItem onClick={p.onLogout}>{t("Log out")}</MenuItem>
        </Menu>
      </>
    );
  }
}
