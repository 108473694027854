import {
  PickingOrder,
  PickingOrderId,
} from "types/src/PickingOrder/PickingOrder";
import { PickingOrderFragmentFragment } from "../generated/graphql";
import { pickingOrderItemFragmentToPickingOrderItem } from "./PickingOrderItem";

export function pickingOrderFragmentToPickingOrder(
  p: PickingOrderFragmentFragment,
): PickingOrder {
  return {
    id: p.id as PickingOrderId,
    fields: (p.data ?? {}) as PickingOrder["fields"],
    createdAt: p.createdAt,
    updatedAt: p.updatedAt ?? undefined,
    dataTypeId: p.dataTypeID as PickingOrder["dataTypeId"],
    customerId: p.customerID as PickingOrder["customerId"],
    items: p.orderitems?.map(pickingOrderItemFragmentToPickingOrderItem) ?? [],
  };
}
