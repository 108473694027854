import React, { ReactNode } from "react";
import { Grid2 } from "@mui/material";
import { Title } from "./Title";

export namespace ContentLayout {
  export type Props = {
    title: ReactNode;
    parentPage?: {
      title: string;
      open: () => void;
    };
    children: ReactNode;
  };
}

export const ContentLayout: React.FC<ContentLayout.Props> = ({
  children,
  ...p
}) => (
  <Grid2 size="grow" sx={{ p: 8, display: "flex", flexDirection: "column" }}>
    <Title title={p.title} parentPage={p.parentPage} />
    <Grid2 sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {children}
    </Grid2>
  </Grid2>
);
