import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import Typography from "@mui/material/Typography";

export namespace Text {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.text>["renderProps"]
  >;
}

export const Text = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  text,
  onClick,
}: Text.Props<DataEntry, ColumnConfig>) => {
  return (
    <Typography
      variant="body2"
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : "default" }}
    >
      {text}
    </Typography>
  );
};
