import { BackendModule, InitOptions, ReadCallback, Services } from "i18next";
import { langIsSupported } from "../utils";
import translationFileLoaders from "../translationFileLoaders";

export default class FileLoaderModule implements BackendModule<{}> {
  type: BackendModule["type"];

  constructor() {
    this.type = "backend";
  }

  init(
    services: Services,
    backendOptions: {},
    i18nextOptions: InitOptions,
  ): void {}

  read(language: string, namespace: string, callback: ReadCallback): void {
    if (!langIsSupported(language)) {
      return callback(`Unsupported language (${language})`, false);
    }

    translationFileLoaders[language]()
      .then((translations) => callback(null, translations))
      .catch((error) => callback(error, false));
  }
}
