import * as O from "fp-ts/Option";
import { Eq } from "fp-ts/Eq";
import { SymmetricTuple } from "../Tuple";
import { ISODate } from "./ISODate";

type Unit = ISODate | undefined;

export type DateRange = SymmetricTuple<Unit>;

export namespace DateRange {
  export const from = <T extends DateRange>(v: T): T[0] => v[0];
  export const to = <T extends DateRange>(v: T): T[1] => v[1];

  const eq: Eq<Unit> = {
    equals: (a, b) =>
      O.getEq(ISODate).equals(O.fromNullable(a), O.fromNullable(b)),
  };

  export const equal: Eq<DateRange>["equals"] = SymmetricTuple.getEq(eq).equals;

  export function mapFrom(...a: [(v: Unit) => Unit, v: DateRange]): DateRange;
  export function mapFrom(
    ...a: [(v: Unit) => Unit]
  ): (v: DateRange) => DateRange;
  export function mapFrom(
    ...a: [(v: Unit) => Unit, v: DateRange] | [(v: Unit) => Unit]
  ): DateRange | ((v: DateRange) => DateRange) {
    if (a.length === 1) return (v: DateRange) => mapFrom(a[0], v);

    const other = to(a[1]);
    const r = a[0](from(a[1]));

    return r !== undefined &&
      other !== undefined &&
      ISODate.compare(r, other) === 1
      ? [other, other]
      : [r, other];
  }

  export function mapTo(...a: [(v: Unit) => Unit, v: DateRange]): DateRange;
  export function mapTo(...a: [(v: Unit) => Unit]): (v: DateRange) => DateRange;
  export function mapTo(
    ...a: [(v: Unit) => Unit, v: DateRange] | [(v: Unit) => Unit]
  ): DateRange | ((v: DateRange) => DateRange) {
    if (a.length === 1) return (v: DateRange) => mapTo(a[0], v);

    const other = from(a[1]);
    const r = a[0](to(a[1]));

    return r !== undefined &&
      other !== undefined &&
      ISODate.compare(r, other) === -1
      ? [other, other]
      : [other, r];
  }
}
