import { RootState, useSelector } from "state-manager";
import { useTranslation } from "i18n";
import * as Fp from "fp-ts/function";
import { DateRange } from "types/src/date/DateRange";
import { Dispatch, useMemo } from "react";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { subDays } from "date-fns/fp";
import { FilterDatePicker } from "@Containers/Listing/FilterDatePicker";
import { Tuple } from "types/src/Tuple";
import * as Filters from "../../../../../packages/state-manager/src/generic-states/Filters";

export namespace CreatedFrom {
  export type Filter = { createdAt: DateRange };

  export interface Props<P extends string> {
    selector$: (s: RootState) => Filters.State<P, Filter>;
    dispatch: Dispatch<Filters.Actions<P, Filter>>;
    actions: ReturnType<typeof Filters.createState<P, Filter>>["actions"];
  }
}

export function CreatedFrom<P extends string>(p: CreatedFrom.Props<P>) {
  const { t } = useTranslation();
  const value$ = Fp.flow(p.selector$, (v) => v.payload.fields.createdAt);
  const to = useSelector(Fp.flow(value$, DateRange.to));

  const maxValue = useMemo(
    () =>
      Fp.pipe(
        to,
        O.fromNullable,
        O.map(ISODate.toDate),
        O.map(subDays(1)),
        O.toUndefined,
      ),
    [to],
  );

  return (
    <FilterDatePicker
      label={t("Created from")}
      max={maxValue}
      value$={Fp.flow(
        value$,
        DateRange.from,
        O.fromNullable,
        O.map(ISODate.toDate),
        O.toUndefined,
      )}
      onChange={Fp.flow(
        O.fromNullable,
        O.map(ISODate.fromDate),
        O.toUndefined,
        (v) => Tuple.create(v, to),
        (v) => p.actions.setValue.create({ createdAt: v }),
        p.dispatch,
      )}
    />
  );
}
