import * as Fp from "fp-ts/function";
import { match } from "fp-utilities";
import { TranslatedStr } from "types/src/TranslatedStr";
import { SearchCombobox } from "@Containers/Form/SearchCombobox";
import { Selector } from "state-manager";
import * as O from "fp-ts/Option";
import { useMemo } from "react";
import { Loading } from "../../../../../packages/state-manager/src/generic-states/Loading";

export namespace MultiSelect {
  export type Data<T> = Array<{ value: T; label: TranslatedStr }>;

  export interface SelectorValue<P extends string, T extends string | number> {
    value: O.Option<T>;
    data: Loading.State<P, Data<T>, unknown>;
  }

  export interface Props<P extends string, T extends string | number> {
    label: TranslatedStr;
    selector$: Selector<SelectorValue<P, T>>;
    onChange: (value: O.Option<T>) => void;
    onSearch: (s: string) => void;
    prefix: P;
  }
}

export function MultiSelect<P extends string, T extends string | number>(
  p: MultiSelect.Props<P, T>,
) {
  const loading = useMemo(
    () => Loading.createState<P, MultiSelect.Data<T>, unknown>(p.prefix),
    [p.prefix],
  );

  return (
    <SearchCombobox<T>
      label={p.label}
      selector={Fp.flow(p.selector$, (s) => ({
        value: s.value,
        isLoading: loading.states.loading.is(s.data),
        options: match(
          [loading.states.loading.is, (): MultiSelect.Data<T> => []],
          [loading.states.loadError.is, (): MultiSelect.Data<T> => []],
          [loading.states.ready.is, (v) => v.payload.data],
        )(s.data),
      }))}
      onChange={p.onChange}
      onSearch={p.onSearch}
      getId={String}
    />
  );
}
