import { silentUnreachableError, unreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { StocksListing as Listing } from "./states/Listing";
import * as Single from "./states/Single";
import { State } from "./types/State";
import { Actions } from "./types/Actions";

export function reducer(s: State, a: Actions): State {
  if (Listing.instance.isAction(a)) {
    if (Listing.instance.isState(s)) {
      return Fp.pipe(
        Listing.instance.reducer(s, a),
        E.getOrElse((e) => {
          if (Listing.instance.exits.create.is(e)) return s;

          silentUnreachableError(e);
          return s;
        }),
      );
    }
    return s;
  }
  if (Single.isActions(a)) {
    if (Single.isState(s)) {
      return Single.reducer(s, a);
    }
    return s;
  }

  unreachableError(a);
  return a;
}
