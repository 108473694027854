import { silentUnreachableError, unreachableError } from "utils/exceptions";
import { User } from "types";
import { right } from "fp-ts/Either";
import { DataTypeEntity } from "types/src/DataType/DataType";
import * as Ready from "../Ready";
import { state } from "../Ready";

import * as DataManager from "../Ready/states/DataManager";
import * as BuilderPreview from "../Ready/states/BuilderPreview";
import * as BPMNPreview from "../Ready/states/BPMNPreview";
import * as ZitadelPreview from "../Ready/states/ZitadelPreview";
import * as SandboxPreview from "../Ready/states/SandboxPreview";

import { Customers } from "../Ready/states/DataManager/states/Customers";
import * as CustomersListing from "../Ready/states/DataManager/states/Customers/states/Listing";
import * as CustomersCreate from "../Ready/states/DataManager/states/Customers/states/Create";
import * as CustomersEdit from "../Ready/states/DataManager/states/Customers/states/Edit";

import { Suppliers } from "../Ready/states/DataManager/states/Suppliers";
import { SuppliersListingAll } from "../Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { SuppliersListing } from "../Ready/states/DataManager/states/Suppliers/states/Listing";
import * as SuppliersCreate from "../Ready/states/DataManager/states/Suppliers/states/Create";
import * as SuppliersEdit from "../Ready/states/DataManager/states/Suppliers/states/Edit";

import { Repositories } from "../Ready/states/DataManager/states/Repositories";
import { RepositoriesListingAll } from "../Ready/states/DataManager/states/Repositories/states/ListingAll";
import { RepositoriesListing } from "../Ready/states/DataManager/states/Repositories/states/Listing";
import * as RepositoriesCreate from "../Ready/states/DataManager/states/Repositories/states/Create";
import * as RepositoriesEdit from "../Ready/states/DataManager/states/Repositories/states/Edit";

import { InventoryItems } from "../Ready/states/DataManager/states/InventoryItems";
import { InventoryItemsListingAll } from "../Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import { InventoryItemsListing } from "../Ready/states/DataManager/states/InventoryItems/states/Listing";
import * as InventoryItemsCreate from "../Ready/states/DataManager/states/InventoryItems/states/Create";
import * as InventoryItemsEdit from "../Ready/states/DataManager/states/InventoryItems/states/Edit";

import { ItemMovements } from "../Ready/states/DataManager/states/ItemMovements";
import { ItemMovementsListingAll } from "../Ready/states/DataManager/states/ItemMovements/states/ListingAll";
import { ItemMovementsListing } from "../Ready/states/DataManager/states/ItemMovements/states/Listing";
import * as ItemMovementsCreate from "../Ready/states/DataManager/states/ItemMovements/states/Create";
import * as ItemMovementsEdit from "../Ready/states/DataManager/states/ItemMovements/states/Edit";

import { RepositoryMovements } from "../Ready/states/DataManager/states/RepositoryMovements";
import { RepositoryMovementsListingAll } from "../Ready/states/DataManager/states/RepositoryMovements/states/ListingAll";
import { RepositoryMovementsListing } from "../Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import * as RepositoryMovementsCreate from "../Ready/states/DataManager/states/RepositoryMovements/states/Create";
import * as RepositoryMovementsEdit from "../Ready/states/DataManager/states/RepositoryMovements/states/Edit";

import { StocksListing } from "../Ready/states/Stocks/states/Listing";
import * as StocksSingle from "../Ready/states/Stocks/states/Single";

import { CustomersListingAll } from "../Ready/states/DataManager/states/Customers/states/ListingAll";
import { PickingOrders } from "../Ready/states/DataManager/states/PickingOrder";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Ready.State {
  switch (a.type) {
    case "Auth:UserPreloadFail":
      return s.type === "Auth:Idle" ? State.login(s.payload) : s;
    case "Auth:UserPreloadSuccess":
      if (s.type === "Auth:Idle") {
        return goToReducer(s, s.payload.goTo, a.payload);
      }
      return s;
    case "Auth:SignIn":
      return s.type === "Auth:Login" || s.type === "Auth:AuthError"
        ? State.authentication(s.payload)
        : s;
    case "Auth:SignInFail":
      return s.type === "Auth:Authentication" ? State.authError(s.payload) : s;
    case "Auth:SignInSuccess": {
      if (s.type === "Auth:Authentication") {
        return goToReducer(s, s.payload.goTo, a.payload);
      }
      return s;
    }
    default:
      unreachableError(a);
      return s;
  }
}

function goToReducer(
  s: State.State,
  a: State.IdlePayload["goTo"],
  _user: User,
): State.State | Ready.State {
  const { orgs, orgId, openAI, ...user } = _user;
  switch (a.type) {
    case "data-types:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: {
            customer: Customers.instance.states.idle.create({
              listing: Customers.instance.subStates.listingAll.init(),
              single: Customers.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Customer,
              ),
            }),
            item: InventoryItems.instance.states.idle.create({
              listing: InventoryItems.instance.subStates.listingAll.init(),
              single: InventoryItems.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Item,
              ),
            }),
            order: PickingOrders.instance.states.idle.create({
              listing: PickingOrders.instance.subStates.listingAll.init(),
              single: PickingOrders.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Order,
              ),
            }),
            "item-movement": ItemMovements.instance.states.idle.create({
              listing: ItemMovements.instance.subStates.listingAll.init(),
              single: ItemMovements.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Movement,
              ),
            }),
            "repository-movement": PickingOrders.instance.states.idle.create({
              listing: PickingOrders.instance.subStates.listingAll.init(),
              single: PickingOrders.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Order,
              ),
            }),
            supplier: Suppliers.instance.states.idle.create({
              listing: Suppliers.instance.subStates.listingAll.init(),
              single: Suppliers.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Supplier,
              ),
            }),
            repository: Repositories.instance.states.idle.create({
              listing: Repositories.instance.subStates.listingAll.init(),
              single: Repositories.instance.subStates.dataTypesCreate.init(
                DataTypeEntity.Repository,
              ),
            }),
          }[a.payload],
        }),
      });
    case "data-types:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: {
            customer: Customers.instance.states.idle.create({
              listing: Customers.instance.subStates.listingAll.init(),
              single: Customers.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
            item: InventoryItems.instance.states.idle.create({
              listing: InventoryItems.instance.subStates.listingAll.init(),
              single: InventoryItems.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
            order: PickingOrders.instance.states.idle.create({
              listing: PickingOrders.instance.subStates.listingAll.init(),
              single: PickingOrders.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
            "item-movement": ItemMovements.instance.states.idle.create({
              listing: ItemMovements.instance.subStates.listingAll.init(),
              single: ItemMovements.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
            "repository-movement": PickingOrders.instance.states.idle.create({
              listing: PickingOrders.instance.subStates.listingAll.init(),
              single: PickingOrders.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
            supplier: Suppliers.instance.states.idle.create({
              listing: Suppliers.instance.subStates.listingAll.init(),
              single: Suppliers.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
            repository: Repositories.instance.states.idle.create({
              listing: Repositories.instance.subStates.listingAll.init(),
              single: Repositories.instance.subStates.dataTypesEdit.init(
                a.payload.id,
              ),
            }),
          }[a.payload.entity],
        }),
      });
    case "customers":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Customers.instance.states.idle.create({
            listing: CustomersListingAll.instance.init(),
            single: undefined,
          }),
        }),
      });
    case "customers:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Customers.instance.states.idle.create({
            listing: CustomersListing.CustomersListing.instance.init({
              id: a.id,
            }),
            single: undefined,
          }),
        }),
      });
    case "customers:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Customers.instance.states.idle.create({
            listing: CustomersListingAll.instance.init(),
            single: CustomersCreate.init({ dataTypeId: a.dataTypeId }),
          }),
        }),
      });
    case "customers:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Customers.instance.states.idle.create({
            listing: CustomersListingAll.instance.init(),
            single: CustomersEdit.init({ customerId: a.id }),
          }),
        }),
      });
    case "suppliers":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Suppliers.instance.init(),
        }),
      });
    case "suppliers:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Suppliers.instance.states.idle.create({
            listing: SuppliersListing.instance.init({ id: a.id }),
            single: undefined,
          }),
        }),
      });
    case "suppliers:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Suppliers.instance.states.idle.create({
            listing: SuppliersListingAll.instance.init(),
            single: SuppliersCreate.init({ dataTypeId: a.dataTypeId }),
          }),
        }),
      });
    case "suppliers:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Suppliers.instance.states.idle.create({
            listing: SuppliersListingAll.instance.init(),
            single: SuppliersEdit.init({ id: a.id }),
          }),
        }),
      });
    case "repositories":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Repositories.instance.init(),
        }),
      });
    case "repositories:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Repositories.instance.states.idle.create({
            listing: RepositoriesListing.instance.init({ id: a.id }),
            single: undefined,
          }),
        }),
      });
    case "repositories:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Repositories.instance.states.idle.create({
            listing: RepositoriesListingAll.instance.init(),
            single: RepositoriesCreate.init({ dataTypeId: a.dataTypeId }),
          }),
        }),
      });
    case "repositories:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: Repositories.instance.states.idle.create({
            listing: RepositoriesListingAll.instance.init(),
            single: RepositoriesEdit.init({ id: a.id }),
          }),
        }),
      });
    case "inventoryItems":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItems.instance.init(),
        }),
      });
    case "inventoryItems:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItems.instance.states.idle.create({
            listing: InventoryItemsListing.instance.init({ id: a.id }),
            single: undefined,
          }),
        }),
      });
    case "inventoryItems:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItems.instance.states.idle.create({
            listing: InventoryItemsListingAll.instance.init(),
            single: InventoryItemsCreate.init({ dataTypeId: a.dataTypeId }),
          }),
        }),
      });
    case "inventoryItems:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItems.instance.states.idle.create({
            listing: InventoryItemsListingAll.instance.init(),
            single: InventoryItemsEdit.init({ id: a.id }),
          }),
        }),
      });

    case "itemMovements":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovements.instance.init(),
        }),
      });
    case "itemMovements:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovements.instance.states.idle.create({
            listing: ItemMovementsListing.instance.init(a.id),
            single: undefined,
          }),
        }),
      });
    case "itemMovements:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovements.instance.states.idle.create({
            listing: ItemMovementsListingAll.instance.init(),
            single: ItemMovementsCreate.init({ dataTypeId: a.id }),
          }),
        }),
      });
    case "itemMovements:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovements.instance.states.idle.create({
            listing: ItemMovementsListingAll.instance.init(),
            single: ItemMovementsEdit.init({ id: a.id }),
          }),
        }),
      });

    case "pickingOrders":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataManager.pickingOrderState.init(),
        }),
      });
    case "pickingOrders:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataManager.pickingOrderState.states.idle.create({
            listing: DataManager.pickingOrderState.subStates.listing.init({
              id: a.id,
            }),
            single: undefined,
          }),
        }),
      });
    case "pickingOrders:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataManager.pickingOrderState.states.idle.create({
            listing: DataManager.pickingOrderState.subStates.listingAll.init(),
            single: DataManager.pickingOrderState.subStates.create.init({
              dataTypeId: a.id,
            }),
          }),
        }),
      });
    case "pickingOrders:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataManager.pickingOrderState.states.idle.create({
            listing: DataManager.pickingOrderState.subStates.listingAll.init(),
            single: DataManager.pickingOrderState.subStates.edit.init({
              id: a.id,
            }),
          }),
        }),
      });

    case "repositoryMovements":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovements.instance.init(),
        }),
      });
    case "repositoryMovements:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovements.instance.states.idle.create({
            listing: RepositoryMovementsListing.instance.init(a.id),
            single: undefined,
          }),
        }),
      });
    case "repositoryMovements:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovements.instance.states.idle.create({
            listing: RepositoryMovementsListingAll.instance.init(),
            single: RepositoryMovementsCreate.init({ dataTypeId: a.id }),
          }),
        }),
      });
    case "repositoryMovements:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovements.instance.states.idle.create({
            listing: RepositoryMovementsListingAll.instance.init(),
            single: RepositoryMovementsEdit.init({ id: a.id }),
          }),
        }),
      });

    case "warehouse-builder":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: BuilderPreview.loading({}),
      });
    case "bpmn-builder":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: BPMNPreview.idle({}),
      });
    case "zitadel-app":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: ZitadelPreview.idle({}),
      });
    case "graphql-sandbox":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: SandboxPreview.idle({}),
      });
    case "stocks":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: StocksListing.instance.init(),
      });
    case "stocks:single":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: StocksSingle.loading({ id: a.id }),
      });
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
