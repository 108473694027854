import { FilterType, PredefinedFiltersMap } from "../types/filters";

export const predefinedFilters: PredefinedFiltersMap = {
  search: {
    type: FilterType.text,
    options: { label: "Search" },
  },
  dateRange: {
    type: FilterType.dateRange,
    options: { label: "Date range" },
  },
};
