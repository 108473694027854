import { styled } from "@mui/material/styles";
import { ComponentProps, JSXElementConstructor } from "react";

export const FormWrapper: JSXElementConstructor<ComponentProps<"div">> = styled(
  "div",
)`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing(3)};
`;
