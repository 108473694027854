import { Eq } from "fp-ts/Eq";
import { Ord } from "fp-ts/Ord";

// region ISODate
declare const _iSODate: unique symbol;

export type ISODate = string & { [_iSODate]: "ISODate" };
// endregion

export namespace ISODate {
  export const toDate = (date: ISODate): Date => new Date(date);
  export const fromDate = (date: Date): ISODate =>
    date.toISOString() as ISODate;

  export const equals: Eq<ISODate>["equals"] = (a, b) =>
    toDate(a).getTime() === toDate(b).getTime();

  export const compare: Ord<ISODate>["compare"] = (a, b) => {
    const _a = toDate(a).getTime();
    const _b = toDate(b).getTime();

    return _a < _b ? -1 : a === b ? 0 : 1;
  };
}

/**
 * @deprecated, use ISODate.toDate
 */
export const toDate = ISODate.toDate;

/**
 * @deprecated, use ISODate.fromDate
 */
export const fromDate = ISODate.fromDate;
