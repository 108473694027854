import { silentUnreachableError } from "utils/exceptions";
import { DataEntryBase } from "../../../types/data";
import { CellType as Type, ColumnsConfigBase } from "../../../types/columns";
import { ID } from "./ID";
import { Text } from "./Text";
import { TextPrimarySecondary } from "./TextPrimarySecondary";
import { Number } from "./Number";
import { Badge } from "./Badge";
import { TimeDate } from "./TimeDate";
import { MovedFrom } from "./MovedFrom";
import { MovedTo } from "./MovedTo";
import { Stock } from "./Stock";

export namespace CellType {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = {
    column: ColumnsConfig[string];
    entry: DataEntry;
  };
}

export const CellType = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
>({
  column,
  entry,
}: CellType.Props<DataEntry, ColumnsConfig>) => {
  switch (column.type) {
    case Type.id:
      return <ID {...column.renderProps(entry)} />;
    case Type.text:
      return <Text {...column.renderProps(entry)} />;
    case Type.textPrimarySecondary:
      return <TextPrimarySecondary {...column.renderProps(entry)} />;
    case Type.number:
      return <Number {...column.renderProps(entry)} />;
    case Type.badge:
      return <Badge {...column.renderProps(entry)} />;
    case Type.timeDate:
      return <TimeDate {...column.renderProps(entry)} />;
    case Type.movedFrom:
      return <MovedFrom {...column.renderProps(entry)} />;
    case Type.movedTo:
      return <MovedTo {...column.renderProps(entry)} />;
    case Type.stock:
      return <Stock {...column.renderProps(entry)} />;
  }

  silentUnreachableError(column);

  return null;
};
