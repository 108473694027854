import React from "react";
import { useTranslation } from "i18n";
import { Layout } from "@layouts/ListingTable/Suggestions/states/common/Layout";
import Icon from "@mui/icons-material/CloseRounded";
import { Sxs } from "../../../../types/styles";

export namespace StateError {
  export type Props = {
    message?: string;
  };
}

export const StateError = ({ message }: StateError.Props) => {
  const { t } = useTranslation();

  return (
    <Layout
      icon={icon}
      title={t("Error")}
      description={message || t("Something went wrong, try again later.")}
    />
  );
};

const sxs = {
  icon: (theme) => ({
    color: theme.palette.action.active,
  }),
} satisfies Sxs;

const icon = <Icon sx={sxs.icon} />;
