import { SupportedLang, Translations } from "./types";

const translationFileLoaders: Record<
  SupportedLang,
  () => Promise<Translations>
> = {
  en: () => import("../translations/en.json").then((json) => json.default),
  de: () => import("../translations/de.json").then((json) => json.default),
};

export default translationFileLoaders;
