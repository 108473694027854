import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { ColumnsConfigBase } from "@layouts/ListingTable/types/columns";

export const getColumnsOrder = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
>(
  columns: ColumnsConfig,
  filterVisible?: Partial<Record<keyof ColumnsConfig | string, boolean>>,
): Array<keyof ColumnsConfig> => {
  const order = Object.keys(columns);
  return filterVisible
    ? order.filter((id) => filterVisible[id] !== false)
    : order;
};
