import { DataEntryBase } from "@layouts/ListingTable/types/data";
import {
  ColumnsConfigBase,
  ColumnsVisibility,
} from "@layouts/ListingTable/types/columns";
import React, { useMemo } from "react";
import { Columns as Icon } from "icons";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { usePopoverController } from "@layouts/ListingTable/Popover/hooks/usePopoverController";
import { getColumnsOrder } from "@layouts/ListingTable/utils/columns";
import { Popover } from "@layouts/ListingTable/Popover";
import { CheckboxesList } from "../../../CheckboxesList";

export namespace ColumnsVisibilityPicker {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = {
    columns: ColumnsConfig;

    columnsVisibility: ColumnsVisibility<DataEntry, ColumnsConfig>;
    onColumnsVisibilityChange: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;
  };
}

export const ColumnsVisibilityPicker = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
>({
  columns,
  columnsVisibility,
  onColumnsVisibilityChange,
}: ColumnsVisibilityPicker.Props<DataEntry, ColumnsConfig>) => {
  const { openPopover, popoverOpen, popoverProps } = usePopoverController();
  const checkboxesItems = useMemo(
    () =>
      getColumnsOrder<DataEntry, ColumnsConfig>(columns).map<
        CheckboxesList.Item<keyof ColumnsConfig>
      >((id) => ({
        id,
        label: columns[id]?.label,
      })),
    [columns],
  );

  return (
    <>
      <Icon sx={sxIcon} onClick={openPopover} />
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          <CheckboxesList
            items={checkboxesItems}
            value={columnsVisibility}
            onChange={onColumnsVisibilityChange}
          />
        </Popover>
      )}
    </>
  );
};

const sxIcon: SxProps<Theme> = (theme) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  cursor: "pointer",
  verticalAlign: "top",
});

const popoverStaticProps: Partial<Popover.Props> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};
