import { RootState, useSelector } from "state-manager";
import { Search } from "ui/components/Input";

export interface SearchInputProps {
  value$: (s: RootState) => string;
  onChange: (value: string) => void;
}

export function SearchInput({ value$, onChange }: SearchInputProps) {
  const value = useSelector(value$);

  return <Search value={value} onChange={onChange} />;
}
