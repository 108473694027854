import Popover_, { PopoverProps } from "@mui/material/Popover";
import { useMemo } from "react";
import { useTheme } from "@mui/material";
import { stylesPopper } from "@layouts/ListingTable/utils/styles";
import { Sxs } from "../../../types/styles";

export namespace Popover {
  export type Props = Omit<PopoverProps, "sx">;
}

export const Popover = (props: Popover.Props) => {
  const theme = useTheme();
  const transformOrigin = useMemo<PopoverProps["transformOrigin"]>(
    () =>
      props.anchorOrigin?.vertical === "bottom"
        ? { vertical: parseInt(theme.spacing(-2.5), 10), horizontal: "left" }
        : undefined,
    [props.anchorOrigin?.vertical, theme],
  );

  return <Popover_ transformOrigin={transformOrigin} {...props} sx={sx} />;
};

const sx: Sxs[string] = (theme) => ({
  "& .MuiPaper-root": {
    ...stylesPopper(theme),
  },
});
