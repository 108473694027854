import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { Customers } from "../states/Customers";
import { Suppliers } from "../states/Suppliers";
import { Repositories } from "../states/Repositories";
import { InventoryItems } from "../states/InventoryItems";
import { ItemMovements } from "../states/ItemMovements";
import { RepositoryMovements } from "../states/RepositoryMovements";
import { PickingOrders } from "../states/PickingOrder";

// region State
export type State = Loading | LoadError | Ready;

export const isState = strictGuard((state: State): state is State => {
  const s = state as State;
  switch (s.type) {
    case "Ready:DataManager:Loading":
    case "Ready:DataManager:LoadError":
    case "Ready:DataManager:Ready":
      return true;
    default:
      silentUnreachableError(s);
      return false;
  }
});

export const init = (): State =>
  loading({ subState: Customers.instance.init() });
// endregion

// region Loading
export interface LoadingPayload {
  subState:
    | Customers.State
    | Suppliers.State
    | Repositories.State
    | InventoryItems.State
    | ItemMovements.State
    | RepositoryMovements.State
    | PickingOrders.State;
}

export interface Loading {
  type: "Ready:DataManager:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Loading",
  payload,
});

export const isLoading = (s: State): s is Loading =>
  s.type === "Ready:DataManager:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:LoadError",
  payload,
});

export const isLoadError = (s: State): s is LoadError =>
  s.type === "Ready:DataManager:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  dataTypes: DataType[];
}

export interface Ready {
  type: "Ready:DataManager:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:DataManager:Ready";
// endregion
