import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Client } from "ds";
import * as Rx from "rxjs";
import {
  deleteItemMovements,
  executeItemMovements,
  getItemMovements,
  GetItemMovementsVars,
} from "ds/ItemMovements";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { getDataTypes } from "ds/DataTypes";
import { Typed } from "utils/Typed";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { silentUnreachableError } from "utils/exceptions";
import * as Arr from "fp-ts/Array";
import * as Obj from "utils/object";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import { strictGuard } from "utils/strictGuard";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataTypes } from "../../../../../../../../generic-states/ListingWithDataTypes";
import { ListingState } from "../../../../../../../../generic-states/Listing";

const prefix = "Ready:DataManager:ItemMovements:Listing" as const;

const createExecuteActions = Typed.builder
  .add("executeItem", (id: ItemMovementId) => id)
  .add("executeBulk")
  .add("executeConfirm")
  .add("executeDecline")
  .add("executeSuccess", (ids: ItemMovementId[]) => ids)
  .add("executeFail", (ids: ItemMovementId[]) => ids)
  .finish();

const createListingState = () => {
  const executeActions = createExecuteActions(prefix);
  const state = ListingState.createState<
    typeof prefix,
    ItemMovementsListing.Filters,
    ItemMovementsListing.Item,
    "createdAt" | "updatedAt",
    {
      id: DataTypeId;
      executing: Record<
        ItemMovementId,
        undefined | "confirmation" | "executing"
      >;
    }
  >(prefix, {
    defaultFilters: {
      createdAt: [undefined, undefined],
      executed: "all",
      id: O.none,
      search: O.none,
      status: "all",
      updatedAt: [undefined, undefined],
    },
  });

  type St = ListingWithDataTypes.GetState<typeof state>;
  type Ac =
    | ListingWithDataTypes.GetActions<typeof state>
    | Typed.GetTypes<typeof executeActions>;
  type Ex = ListingWithDataTypes.GetExits<typeof state>;

  const reducer = (s: St, a: Ac): E.Either<Ex, St> => {
    if (executeActions.executeItem.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((s) => !s.payload.removing[a.payload]),
        O.filter((s) => !s.payload.executing[a.payload]),
        O.chain((s) => {
          const item = s.payload.items.find((v) => v.id === a.payload);

          if (!item) return O.none;

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              executing: {
                ...s.payload.executing,
                [a.payload]: "confirmation",
              },
            },
          } as typeof s);
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeBulk.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((s) => !!s.payload.selected.length),
        O.chain((s) => {
          const ids = Arr.intersection<ItemMovementId>({
            equals: (a, b) => a === b,
          })(
            s.payload.selected,
            s.payload.items.filter((i) => !i.executed).map((i) => i.id),
          ).filter((id) => !s.payload.executing[id]);
          if (!ids.length) return O.none;

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              executing: {
                ...s.payload.executing,
                ...ids.reduce(
                  (acc: Record<ItemMovementId, "confirmation">, id) => {
                    acc[id] = "confirmation";
                    return acc;
                  },
                  {},
                ),
              },
            },
          });
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeConfirm.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some((v) => v === "confirmation", v.payload.executing),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                executing: Obj.map(
                  (v) => (v === "confirmation" ? "executing" : v),
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeDecline.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some((v) => v === "confirmation", v.payload.executing),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                executing: Obj.filter(
                  (v) => v !== "confirmation",
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeSuccess.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some(
            (v, k) => v === "executing" && a.payload.includes(k),
            v.payload.executing,
          ),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                items: s.payload.items.map((v) =>
                  a.payload.includes(v.id) ? { ...v, executed: true } : v,
                ),
                executing: Obj.filter(
                  (_, k) => a.payload.includes(k),
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeFail.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some(
            (v, k) => v === "executing" && a.payload.includes(k),
            v.payload.executing,
          ),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                executing: Obj.filter(
                  (_, k) => a.payload.includes(k),
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (state.isAction(a)) return state.reducer(s, a);

    silentUnreachableError(a);
    return E.right(s);
  };

  const epic: Epic<Ac, St, { pyckAdminClient$: Rx.Observable<Client> }> = (
    state$,
    { pyckAdminClient$ },
  ) => {
    const diff = Arr.difference(Str.Eq as Eq<ItemMovementId>);
    const listing$ = state.epic(state$, {
      fetchItems: (s) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getItemMovements(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: [DataTypeEntity.Movement],
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;

                    return E.right({
                      items: v.items.right,
                      dataTypes: Fp.pipe(
                        v.dataTypes,
                        E.map((v) => v.items),
                        E.getOrElse(() => [] as DataType[]),
                      ),
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      executed: i.executed,
                      dataType: O.fromNullable(
                        r.dataTypes.find((v) => v.id === i.dataTypeId),
                      ),
                      item: i.item.id,
                      quantity: i.quantity,
                      orderId: i.orderId,
                      collectionId: i.collectionId,
                      handler: i.handler,
                      from: i.from,
                      to: i.to,
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteItemMovements(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
    });

    const execute$ = pyckAdminClient$.pipe(
      Rx.switchMap((client) => {
        return state$.pipe(
          Rx.filter(state.states.ready.is),
          Rx.map((s) =>
            Obj.entries(s.payload.executing)
              .filter(([, v]) => v === "executing")
              .map(([k]) => k),
          ),
          Rx.scan(
            ([pendingItems], removingItems) => {
              return [removingItems, diff(pendingItems, removingItems)] as [
                ItemMovementId[],
                ItemMovementId[],
              ];
            },
            [[], []] as [ItemMovementId[], ItemMovementId[]],
          ),
          Rx.map(([, toRemove]) => toRemove),
          Rx.filter((i) => i.length > 0),
          Rx.mergeMap((ids) => {
            return Rx.from(executeItemMovements(client, ids)).pipe(
              Rx.map(E.map(() => ids)),
              Rx.map(E.mapLeft(() => ids)),
              Rx.map(E.map(executeActions.executeSuccess.create)),
              Rx.map(E.mapLeft(state.actions.removeFail.create)),
              Rx.map(E.getOrElseW((v) => v)),
            );
          }),
        );
      }),
    );

    return Rx.merge(listing$, execute$);
  };

  return {
    ...state,
    actions: { ...state.actions, ...executeActions },
    isAction: strictGuard(
      (a: Ac): a is Ac =>
        state.isAction(a) || Typed.getGuard(executeActions)(a),
    ),
    reducer,
    epic,
    init: (id: DataTypeId): St =>
      state.init({
        id: id,
        executing: {},
      }),
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetItemMovementsVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetItemMovementsVars["where"] = {
      createdAt: fields.createdAt,
      updatedAt: fields.updatedAt,
      dataTypes: [s.payload.id],
      id: O.toUndefined(fields.id),
      search: O.toUndefined(fields.search),
      executed: {
        all: undefined,
        executed: true,
        "un-executed": false,
      }[fields.executed],
      orphans: {
        all: undefined,
        active: false,
        orphan: true,
      }[fields.status],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
};

export namespace ItemMovementsListing {
  export type Filter = {
    createdAt: Option<DateRange>;
    updatedAt: Option<DateRange>;
    id: Option<NoEmptyString>;
    search: Option<NoEmptyString>;
    dataTypes: DataTypeId[];
    status: "all" | "active" | "orphan";
  };

  export const instance = createListingState();

  export type State = ListingWithDataTypes.GetState<typeof instance>;
  export type Actions = ListingWithDataTypes.GetActions<typeof instance>;
  export type Exits = ListingWithDataTypes.GetExits<typeof instance>;

  export interface Item {
    id: ItemMovementId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: Option<{ id: DataTypeId; name: string }>;
    executed: boolean;
    item: InventoryItemId;
    from: {
      id: RepositoryId;
      name: string;
    };
    to: {
      id: RepositoryId;
      name: string;
    };
    quantity: number;
    handler: string;
    collectionId: string;
    orderId: string;
  }

  export type Filters = {
    executed: "all" | "executed" | "un-executed";
    createdAt: DateRange;
    updatedAt: DateRange;
    id: Option<NoEmptyString>;
    search: Option<NoEmptyString>;
    status: "all" | "active" | "orphan";
  };
}
