import React from "react";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Sxs } from "../../../../../../types/styles";
import { accentColor } from "./constants";

export namespace ProgressBar {
  export type Props = {
    current: number;
    max: number;
  };
}

export const ProgressBar = ({ current, max }: ProgressBar.Props) => {
  const percentage = max
    ? Math.max(0, Math.min(100, (current / max) * 100))
    : 0;

  return (
    <Box sx={sxs.wrapper}>
      <Box sx={[sxs.filled, { width: `${percentage}%` }]} />
    </Box>
  );
};

const sxs = {
  wrapper: (theme) => ({
    width: theme.spacing(13.5),
    height: theme.spacing(2),
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    borderRadius: 2,
  }),
  filled: (theme) => ({
    height: "100%",
    backgroundColor: accentColor,
    borderRadius: 2,
  }),
} satisfies Sxs;
