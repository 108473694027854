import MuiGlobalStyles from "@mui/material/GlobalStyles";

export function GlobalStyles() {
  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        html: {
          backgroundColor: theme.palette.background.paper,
        },
      })}
    />
  );
}
