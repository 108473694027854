import React from "react";
import Button from "@mui/material/Button";
import { SvgIconOwnProps } from "@mui/material/SvgIcon/SvgIcon";
import { Sxs } from "../../types/styles";
import { styleButtonBorderColor, stylesIcon } from "./utils/styles";

export namespace FilterButton {
  export type Props = React.PropsWithChildren<{
    Icon: React.FC<SvgIconOwnProps>;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    active?: boolean;
  }>;
}

export const FilterButton: React.FC<FilterButton.Props> = ({
  Icon,
  onClick,
  active,
  children,
}) => (
  <Button
    size="small"
    type="button"
    variant={active ? "contained" : "outlined"}
    startIcon={<Icon sx={[sxs.icon, active ? { color: "inherit" } : {}]} />}
    onClick={onClick}
    sx={[
      sxs.button,
      (theme) => (active ? {} : { borderColor: styleButtonBorderColor(theme) }),
    ]}
  >
    {children}
  </Button>
);

const sxs = {
  icon: (theme) => ({
    ...stylesIcon(theme),
  }),
  button: (theme) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5, 4),
  }),
} satisfies Sxs;
