import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Sxs } from "../../../../../types/styles";

export namespace Layout {
  export type Props = {
    icon: React.ReactNode;
    title: string;
    description: string;
  };
}

export const Layout: React.FC<Layout.Props> = ({
  icon,
  title,
  description,
}) => (
  <Box sx={sxs.wrapper}>
    <Box>{icon}</Box>
    <Box>
      <Typography variant="subtitle2" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {description}
      </Typography>
    </Box>
  </Box>
);

const sxs = {
  wrapper: (theme) => ({
    py: 1,
    px: 4,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,

    minHeight: theme.spacing(64),
    textAlign: "center",
  }),
} satisfies Sxs;
