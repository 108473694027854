import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DateRange } from "types/src/date/DateRange";
import * as Rx from "rxjs";
import { Client } from "ds";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { from, switchMap } from "rxjs";
import { SymmetricTuple } from "types/src/Tuple";
import { StockId } from "types/src/Stocks/Stock";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { ISODate } from "types/src/date/ISODate";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { getStocks, GetStocksVars } from "ds/Stocks";
import { pipe } from "fp-ts/function";
import { getInventoryItems } from "ds/InventoryItems";
import { getRepositories } from "ds/Repositories";
import { strictGuard } from "utils/strictGuard";
import * as Fp from "fp-ts/function";
import { Epic } from "../../../../../../types/RootEpic";
import { ListingState } from "../../../../../../generic-states/Listing";
import { Loading } from "../../../../../../generic-states/Loading";

const prefix = "Stocks:Listing" as const;
type Prefix = typeof prefix;

function createState() {
  const stocks = Loading.createState<
    `${typeof prefix}:Stocks`,
    Array<{ id: StockId; name: string }>,
    O.Option<NoEmptyString>
  >(`${prefix}:Stocks`);
  const items = Loading.createState<
    `${typeof prefix}:Items`,
    Array<{ id: InventoryItemId; name: string }>,
    O.Option<NoEmptyString>
  >(`${prefix}:Items`);
  const repositories = Loading.createState<
    `${typeof prefix}:Repositories`,
    Array<{ id: RepositoryId; name: string }>,
    O.Option<NoEmptyString>
  >(`${prefix}:Repositories`);

  const state = ListingState.createState<
    Prefix,
    StocksListing.Filter,
    StocksListing.Item,
    "createdAt",
    {
      stocks: Loading.GetState<typeof stocks>;
      inventoryItems: Loading.GetState<typeof items>;
      repositories: Loading.GetState<typeof repositories>;
    }
  >(prefix, {
    defaultFilters: {
      createdAt: [undefined, undefined],
      id: O.none,
      status: "all",
      item: O.none,
      latest: true,
      quantity: [undefined, undefined],
      repository: O.none,
      search: O.none,
    },
  });

  type St = ListingState.GetState<typeof state>;
  type Ac =
    | ListingState.GetActions<typeof state>
    | Loading.GetActions<typeof stocks>
    | Loading.GetActions<typeof repositories>
    | Loading.GetActions<typeof items>;

  const epic: Epic<Ac, St, { pyckAdminClient$: Rx.Observable<Client> }> = (
    state$,
    { pyckAdminClient$ },
  ) => {
    const listing$ = state.epic(state$, {
      fetchItems: (s) => {
        return pyckAdminClient$.pipe(
          switchMap((c) => {
            return Rx.from(from(getStocks(c, getFetchVars(s)))).pipe(
              Rx.map(
                E.map((v) => ({
                  pageInfo: v.pageInfo,
                  items: v.items,
                  total: v.totalCount,
                })),
              ),
            );
          }),
        );
      },
      removeItems: (ids) => Rx.of(E.left(ids)),
    });
    const stocks$ = stocks.epic(state$.pipe(Rx.map((s) => s.payload.stocks)), {
      get: (q) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((c) => {
            return getStocks(c, {
              where: { id: { eq: O.toUndefined(q) as string as StockId } },
            }).then(
              E.map((v) => v.items.map((v) => ({ id: v.id, name: v.id }))),
            );
          }),
        );
      },
    });
    const items$ = items.epic(
      state$.pipe(Rx.map((s) => s.payload.inventoryItems)),
      {
        get: (q) => {
          return pyckAdminClient$.pipe(
            Rx.switchMap((c) => {
              return getInventoryItems(c, {
                where: { search: O.toUndefined(q) },
              }).then(
                E.map((v) => v.items.map((v) => ({ id: v.id, name: v.sku }))),
              );
            }),
          );
        },
      },
    );
    const repositories$ = repositories.epic(
      state$.pipe(Rx.map((s) => s.payload.repositories)),
      {
        get: (q) => {
          return pyckAdminClient$.pipe(
            Rx.switchMap((c) => {
              return getRepositories(c, {
                where: { name: O.toUndefined(q) },
              }).then(
                E.map((v) => v.items.map((i) => ({ id: i.id, name: i.name }))),
              );
            }),
          );
        },
      },
    );

    return Rx.merge(listing$, stocks$, items$, repositories$);
  };

  const reducer = (
    s: St,
    a: Ac,
  ): E.Either<ListingState.GetExits<typeof state>, St> => {
    if (stocks.isAction(a)) {
      return Fp.pipe(
        stocks.reducer(s.payload.stocks, a),
        E.map(
          (stocks) => ({ ...s, payload: { ...s.payload, stocks } }) as typeof s,
        ),
      );
    }
    if (items.isAction(a)) {
      return Fp.pipe(
        items.reducer(s.payload.inventoryItems, a),
        E.map(
          (inventoryItems) =>
            ({ ...s, payload: { ...s.payload, inventoryItems } }) as typeof s,
        ),
      );
    }
    if (repositories.isAction(a)) {
      return Fp.pipe(
        repositories.reducer(s.payload.repositories, a),
        E.map(
          (repositories) =>
            ({ ...s, payload: { ...s.payload, repositories } }) as typeof s,
        ),
      );
    }

    return state.reducer(s, a);
  };

  return {
    ...state,
    isAction: strictGuard(
      (a: Ac): a is Ac =>
        state.isAction(a) ||
        stocks.isAction(a) ||
        repositories.isAction(a) ||
        items.isAction(a),
    ),
    epic,
    reducer,
    init: () =>
      state.init({
        stocks: stocks.init(O.none),
        inventoryItems: items.init(O.none),
        repositories: repositories.init(O.none),
      }),
    subStates: {
      ...state.subStates,
      stocks,
      items,
      repositories,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetStocksVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetStocksVars["where"] = {
      or: [
        { hasItemWith: [{ search: O.toUndefined(fields.search) }] },
        { hasRepositoryWith: [{ name: O.toUndefined(fields.search) }] },
      ],
      id: { eq: O.toUndefined(fields.id) as string as StockId },
      hasItem: {
        all: undefined,
        active: true,
        orphan: false,
      }[fields.status],
      quantity: {
        gte: fields.quantity?.[0],
        lte: fields.quantity?.[1],
      },
      hasItemWith: [
        {
          id: O.toUndefined(fields.item),
        },
      ],
      hasRepositoryWith: [
        {
          id: O.toUndefined(fields.repository),
        },
      ],
      createdAt: {
        gte: fields.createdAt?.[0],
        lte: fields.createdAt?.[1],
      },
      time: fields.latest
        ? pipe(
            O.fromNullable(fields.createdAt),
            O.chain((v) => O.fromNullable(v[1])),
            O.getOrElse(() => ISODate.fromDate(new Date())),
          )
        : undefined,
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.startCursor,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.endCursor,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          where,
        };
    }
  }
}

export namespace StocksListing {
  export type Filter = {
    search: O.Option<NoEmptyString>;
    id: O.Option<StockId>;
    status: "all" | "active" | "orphan";
    quantity: SymmetricTuple<number | undefined>;
    item: O.Option<InventoryItemId>;
    repository: O.Option<RepositoryId>;
    createdAt: DateRange;
    latest: boolean;
  };

  export interface Item {
    id: StockId;
    repository: {
      id: RepositoryId;
      name: string;
    };
    item: {
      id: string;
    };
    createdAt: ISODate;
    movementId: ItemMovementId;
    quantity: number;
    incomingStock: number;
    outgoingStock: number;
  }

  export const instance = createState();

  export type State = ListingState.GetState<typeof instance>;
  export type Actions = ListingState.GetActions<typeof instance>;
  export type Exits = ListingState.GetExits<typeof instance>;
}
