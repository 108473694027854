import TextField from "@mui/material/TextField";

export namespace Textarea {
  export interface Props {
    value: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    label?: string;
    readOnly?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    rows?: number;
  }
}

export function Textarea(p: Textarea.Props) {
  return (
    <TextField
      multiline
      value={p.value}
      onChange={(e) => p.onChange?.(e.target.value)}
      disabled={p.disabled}
      label={p.label}
      error={p.error}
      rows={p.rows}
      helperText={p.helperText}
      slotProps={{
        input: {
          readOnly: p.readOnly,
        },
      }}
      size={"small"}
    />
  );
}
