import { useTranslation } from "i18n";
import { Selector } from "state-manager";
import { StocksListing as Listing } from "state-manager/states/Ready/states/Stocks/states/Listing";

export interface HeaderProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Header(p: HeaderProps): string {
  const { t } = useTranslation();
  return t("Stocks");
}
