import React from "react";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ChevronDown as Icon } from "icons";

export namespace ChevronDown {
  export type Props = {};
}
export const ChevronDown: React.FC<ChevronDown.Props> = () => {
  return <Icon sx={sxs.icon} />;
};

const sxs = {
  icon: (theme) => ({
    ...sxsCommon.icon(theme),
  }),
} satisfies Record<string, SxProps<Theme>>;
