import { useTranslation } from "i18n";
import { styled } from "@mui/material/styles";
import { Button } from "@components/Button";
import { Tune, Cancel } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";

export interface FiltersButtonProps extends Omit<Button.Props, "children"> {
  isSelected: boolean;
  onClear?: () => void;
}

export function FiltersButton({
  onClear,
  isSelected,
  ...props
}: FiltersButtonProps) {
  const { t } = useTranslation();

  return (
    <Badge
      badgeContent={
        <IconButton onClick={onClear}>
          <Cancel
            opacity={0.7}
            sx={(p) => ({ fill: p.palette.text.secondary })}
          />
        </IconButton>
      }
      invisible={!isSelected}
    >
      <SButton {...props} startIcon={<Tune />}>
        {t("Filter")}
      </SButton>
    </Badge>
  );
}

const SButton = styled(Button)`
  position: relative;
  overflow: visible;
`;
