import React, { ReactNode } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { BulkSelectProps } from "@layouts/ListingTable/types/BulkSelectProps";
import { Box } from "@mui/material";
import { Suggestions } from "@layouts/ListingTable/Suggestions";
import { Sxs } from "../../types/styles";
import {
  CellType as CellType_,
  ColumnsConfigBase as ColumnsConfigBase_,
  ColumnsVisibility as ColumnsVisibility_,
} from "./types/columns";
import {
  DataEntryBase,
  EntryAction as EntryAction_,
  ListData as ListData_,
  PaginationDestination,
} from "./types/data";
import {
  FiltersConfigBase as FiltersConfigBase_,
  FilterType as FilterType_,
} from "./types/filters";
import {
  Criteria as Criteria_,
  CriteriaChange as CriteriaChange_,
} from "./types/criteria";
import { List } from "./List";
import { predefinedFilters as predefinedFilters_ } from "./constants/filters";
import { HeaderFilters } from "./HeaderFilters";
import { mergeCriteriaChanges as mergeCriteriaChanges_ } from "./utils/criteria";

export function ListingTable<
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase_<DataEntry>,
  FiltersConfig extends FiltersConfigBase_,
  Actions extends string,
>({
  title,
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  loading$,
  data$,
  entryActions,
  filters,
  criteria$,
  onCriteriaChange,
  onPageChange,
  headerButtons,
  bulkSelect,
}: ListingTable.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>) {
  return (
    <Box sx={sxs.wrapper}>
      <HeaderFilters<DataEntry, ColumnsConfig, FiltersConfig>
        {...{
          filters,
          criteria$,
          onCriteriaChange,
          customButtons: headerButtons,
        }}
      />
      <List<DataEntry, ColumnsConfig, FiltersConfig, Actions>
        {...{
          title,
          columns,
          columnsVisibility$,
          onColumnsVisibilityChange,
          filters,
          criteria$,
          onCriteriaChange,
          loading$,
          data$,
          entryActions,
          onPageChange,
          bulkSelect,
        }}
      />
    </Box>
  );
}

export namespace ListingTable {
  export const CellType = CellType_;

  export type ColumnsConfigBase<DataEntry extends DataEntryBase> =
    ColumnsConfigBase_<DataEntry>;

  export type ColumnsVisibility<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = ColumnsVisibility_<DataEntry, ColumnsConfig>;

  export const FilterType = FilterType_;

  export type FiltersConfigBase<CustomKeys extends string = string> =
    FiltersConfigBase_<CustomKeys>;

  export type Criteria<
    Columns,
    FiltersConfig extends FiltersConfigBase,
  > = Criteria_<Columns, FiltersConfig>;

  export type CriteriaChange<
    Columns,
    FiltersConfig extends FiltersConfigBase,
  > = CriteriaChange_<Columns, FiltersConfig>;

  export const predefinedFilters = predefinedFilters_;

  export type ListData<DataEntry extends DataEntryBase> = ListData_<DataEntry>;

  export type EntryAction<DataEntry extends DataEntryBase> =
    EntryAction_<DataEntry>;

  export const mergeCriteriaChanges = mergeCriteriaChanges_;

  export type SuggestionsData<ID extends PropertyKey = string> =
    Suggestions.Data<ID>;

  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Actions extends string,
  > = {
    columns: ColumnsConfig;
    filters: FiltersConfig;

    title: string;
    headerButtons?: ReactNode;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange? /*renders Visible Columns picker*/ : (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: CriteriaChange<keyof ColumnsConfig, FiltersConfig>,
    ) => void;

    loading$: BehaviorValue<boolean>;
    data$: BehaviorValue<ListData<DataEntry>>;
    entryActions?: (entry: DataEntry) => EntryAction<DataEntry>[];

    onPageChange: (page: PaginationDestination) => void;

    bulkSelect: BulkSelectProps<DataEntry["id"], Actions> | undefined;
  };
}

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,

    "&:not(:first-of-type)": {
      marginTop: 8,
    },
  }),
} satisfies Sxs;
