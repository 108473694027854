import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import Popper, { PopperProps } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import React from "react";
import { stylesPopper } from "@layouts/ListingTable/utils/styles";
import { ClickAwayListener } from "@mui/material";
import { Sxs } from "../../../types/styles";
import { List } from "./List";
import { State as State_ } from "./types/state";
import { StateLoading } from "./states/StateLoading";
import { StateError } from "./states/StateError";
import { NoItems } from "./states/NoItems";

export namespace Suggestions {
  export type State = State_;

  export type Item<ID extends PropertyKey = string> = List.Item<ID>;

  export type Data<ID extends PropertyKey = string> = {
    state?: State;
    items: Array<Item<ID>>;
  };

  export type Props<ID extends PropertyKey = string> = {
    text: string;
    data$: BehaviorValue<Data<ID>>;
    onPick: List.Props<ID>["onPick"];
    anchorEl: Element | undefined;
    open?: boolean;
    onClose: () => void;
  };
}

export const Suggestions = <ID extends PropertyKey = string>({
  text,
  data$,
  onPick,
  anchorEl,
  open = true,
  onClose,
}: Suggestions.Props<ID>) => {
  const { state, items } = useBehaviorValue(data$);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        modifiers={popperModifiers}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={sxs.paper}>
              {state?.type === "loading" && <StateLoading />}
              {state?.type === "error" && (
                <StateError message={state.message} />
              )}
              {!state &&
                (items.length ? (
                  <List {...{ text, items, onPick }} />
                ) : (
                  <NoItems />
                ))}
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

const sxs = {
  paper: (theme) => ({
    ...stylesPopper(theme),
    marginTop: 2,
  }),
} satisfies Sxs;

// makes `popper.width = anchorEl.width`
const popperModifiers: PopperProps["modifiers"] = [
  {
    name: "preventOverflow",
    options: {
      boundary: "window",
    },
  },
  {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      if (state.styles.popper) {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      }
    },
  },
];
