import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import { DataTypeEdit as Edit } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Edit";
import { Header as Layout } from "ui/layouts/Single/Header";

export interface HeaderProps {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}

export function Header({ selector }: HeaderProps): ReactElement | null {
  const name = useSelector(
    flow(selector, (s) => {
      if (
        Edit.instance.states.loading.is(s) ||
        Edit.instance.states.loadError.is(s)
      )
        return null;

      return s.payload.name;
    }),
  );

  return name !== null ? <Layout>{name}</Layout> : null;
}
