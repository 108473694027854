import { createRoot } from "react-dom/client";
import React from "react";
import { App } from "./App";

export function pyckAdminStart(
  node: HTMLElement,
  env: {
    clientId: string;
    authority: string;
    redirectUri: string;
    clientUri: string;
    zitadelConsole: string;
  },
) {
  const root = createRoot(node);

  root.render(
    <App
      clientId={env.clientId}
      authority={env.authority}
      clientUri={env.clientUri}
      redirectUri={env.redirectUri}
      zitadelConsole={env.zitadelConsole}
    />,
  );
}

window.pyckAdminStart = pyckAdminStart;
