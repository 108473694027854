import { forwardRef } from "react";
import { useTranslation } from "i18n";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { styled } from "@mui/material/styles";
import { Typography } from "@components/Typography";
import { Cell } from "@components/Table";

export interface DateCellProps extends Omit<Cell.Props, "children"> {
  value: O.Option<Date>;
  className?: string;
}

export const DateCell = forwardRef<HTMLTableCellElement, DateCellProps>(
  ({ value, ...props }, ref) => {
    const { language } = useTranslation();
    return (
      <Cell ref={ref} {...props}>
        {pipe(
          value,
          O.map((v) => new Intl.DateTimeFormat(language).format(v)),
          O.map((v) => <Wrapper>{v}</Wrapper>),
          O.toUndefined,
        )}
      </Cell>
    );
  },
);

const Wrapper = styled(Typography)`
  font-weight: bold;
  padding: 3px 5px;
  background: ${(p) => p.theme.palette.grey[200]};
  border-radius: 4px;
`;
